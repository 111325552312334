import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {RxCheckCircled, RxChevronRight, RxClock} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {v4} from 'uuid';
import {dateToTimestamp, timeSince} from '../../../api/dates';
import {
  FileViewerSimple,
  ProfileImage,
} from '../../../components/inputs/uploader';
import {SpinningIndicator} from '../../../components/loading/loading-indicator';
import {isProUser} from '../../../components/system-status/system-status';
import {useAuth} from '../../../hooks/use-auth';
import useCrypto from '../../../hooks/use-crypto';
import useLoader from '../../../hooks/use-loader';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import ContentDisplay from '../components/content-display';
import DocumentGenerator from '../components/quick-doc';
import {OfficialPostProfile} from '../components/thread-comment';
import WorkLogger from '../components/work-logger';
import '../ThreadStyles.css';
import {DirectMessages} from './direct-messages';
import './ThreadHome.css';

const HomeMentionItem = ({item, thread, onClick, isUnread = true}) => {
  const navigate = useNavigate();
  const {prettyName} = useStringFormatter();
  const {loadProfiles} = useLoader();
  const {encryptString} = useCrypto();
  const {
    state: {profiles},
  } = useAuth();

  const {owner_id, content, created, media, version} = item || {};

  // Add check for assistant/official post
  const user =
    owner_id === 'assistant'
      ? OfficialPostProfile()
      : profiles?.[owner_id] ?? {};

  const profile_image = user?.profile_image;
  const {title: threadTitle, logo = '', type} = thread || {};
  let title = type !== 'direct' ? logo + ' ' + threadTitle : 'Direct Message';

  useEffect(() => {
    // Only load profile if it's not an assistant post and profile isn't loaded
    if (owner_id && owner_id !== 'assistant' && !profiles?.[owner_id]) {
      loadProfiles([owner_id]);
    }
  }, [owner_id, profiles, loadProfiles]);

  const navigateToProfile = e => {
    e.stopPropagation();
    // Only navigate if not an assistant post
    if (owner_id !== 'assistant') {
      const parsed = encryptString(owner_id);
      navigate(`/feed/profiles/detail/${parsed}`);
    }
  };

  return (
    <div className={`home-mention-item ${isUnread ? 'unread' : ''}`}>
      <div className="home-mention-header">
        <div
          className="home-mention-avatar"
          onClick={navigateToProfile}
          style={owner_id === 'assistant' ? {cursor: 'default'} : {}}>
          <ProfileImage
            data={profile_image}
            style={{
              height: '32px',
              width: '32px',
              ...(owner_id === 'assistant' &&
                {
                  // Uncomment if you want special styling for assistant
                  // padding: '4px',
                  // backgroundColor: 'var(--background)',
                  // border: '1px solid var(--primary-button)',
                }),
            }}
          />
        </div>
        <div className="home-mention-info">
          <div className="home-mention-title">
            <span className="home-mention-name">{prettyName(user)}</span>
            <span className="home-mention-separator">in</span>
            <span className="home-mention-thread">{title}</span>
          </div>
          <div className="home-mention-metadata">
            <span className="home-mention-time">
              <RxClock />
              {timeSince(created)}
            </span>
          </div>
        </div>
      </div>
      <div className="home-mention-content">
        <ContentDisplay item={item} />
      </div>
      {media?.length > 0 && (
        <div className="home-mention-media">
          <FileViewerSimple files={media} />
        </div>
      )}
      <div className="home-mention-footer">
        <div className="home-mention-action" onClick={onClick}>
          <span>{type === 'direct' ? 'View message' : 'View thread'}</span>
          <RxChevronRight />
        </div>
      </div>
    </div>
  );
};

// Local storage key for tracking last seen timestamp
const LAST_SEEN_KEY = 'thread_last_seen';

// Helper functions for last seen tracking
const getLastSeen = userId => {
  try {
    const stored = localStorage.getItem(`${LAST_SEEN_KEY}_${userId}`);
    return stored ? JSON.parse(stored) : {};
  } catch (err) {
    console.error('Error reading last seen from localStorage:', err);
    return {};
  }
};

const setLastSeen = (userId, updates) => {
  try {
    const current = getLastSeen(userId);
    localStorage.setItem(
      `${LAST_SEEN_KEY}_${userId}`,
      JSON.stringify({...current, ...updates}),
    );
  } catch (err) {
    console.error('Error setting last seen in localStorage:', err);
  }
};

// Component for mark all as read button
const MarkAllReadButton = ({onMarkAllRead, unreadCount}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await onMarkAllRead();
    setLoading(false);
  };

  if (!unreadCount) return null;

  return (
    <button
      className="mark-all-read-button"
      onClick={handleClick}
      disabled={loading}>
      {loading ? (
        <span className="loading-spinner" />
      ) : (
        <>
          <RxCheckCircled />
          Mark all as read
        </>
      )}
    </button>
  );
};

const ThreadsHome = ({setCurrent, setIndex, setContent}) => {
  const {
    state: {id: currentUserId, profile},
  } = useAuth();

  const {
    state: {
      thread_search,
      threads,
      thread_token,
      thread_ids,
      thread_subs,
      thread_content,
      thread_filter,
      threads_search_loaded,
    },
    searchThreads,
    updateThreadSubscription,
    createThreadSubscription,
  } = useThreads();

  const [unreadPosts, setUnread] = useState([]);

  const [loadingUpdates, setLoadingUpdates] = useState(true);

  const [showDM, setShowDMs] = useState(false);
  const [directID, setDirectID] = useState(null);

  const [markingRead, setMarkingRead] = useState(false);
  const [activityStats, setActivityStats] = useState({
    content: [],
    totalUnread: 0,
    todayCount: 0,
    activeThreads: 0,
    totalThreads: 0,
    threadBreakdown: [],
    activityTimeline: [],
  });

  const [activeTab, setActiveTab] = useState('home');

  const getUnreadContent = useCallback(() => {
    const lastSeen = getLastSeen(currentUserId);
    const sessionStart = dateToTimestamp(new Date());

    return Object.values(thread_content)
      .filter(content => {
        if (
          content?.status !== 'active' ||
          content?.owner_id === currentUserId
        ) {
          return false;
        }

        const sub = thread_subs[content.thread_id];
        const threadLastSeen = lastSeen[content.thread_id];

        return (
          content.created > (sub?.last_read || 0) ||
          (threadLastSeen ? content.created > threadLastSeen : false) ||
          (sessionStart ? content.created > sessionStart : false)
        );
      })
      .sort((a, b) => b.created - a.created);
  }, [thread_content, thread_subs, currentUserId]);

  const handleMarkAllRead = useCallback(async () => {
    setMarkingRead(true);
    const now = dateToTimestamp();
    const updates = {};

    try {
      thread_ids.forEach(threadId => {
        updates[threadId] = now;
      });
      setLastSeen(currentUserId, updates);

      await Promise.all(
        thread_ids.map(t_id => {
          const sub = thread_subs?.[t_id];
          if (sub) {
            const {id} = sub;
            return updateThreadSubscription({
              id,
              thread_id: t_id,
              last_read: now,
            });
          } else {
            return createThreadSubscription({
              id: v4(),
              user_id: currentUserId,
              thread_id: t_id,
              status: null,
              last_read: dateToTimestamp(),
            });
          }
        }),
      );

      await fetchData();
    } catch (err) {
      console.error('Error marking all as read:', err);
    } finally {
      setMarkingRead(false);
    }
  }, [thread_ids]);

  const fetchData = async () => {
    setLoadingUpdates(true);

    try {
      const oneDayAgo = new Date();
      oneDayAgo.setHours(oneDayAgo.getHours() - 24);
      const oneDayTimestamp = dateToTimestamp(oneDayAgo);

      const recentActivity = Object.values(thread_content)
        .filter(
          content =>
            content?.status === 'active' && content.created >= oneDayTimestamp,
        )
        .sort((a, b) => b.created - a.created);

      const unreadContent = getUnreadContent();
      setUnread(unreadContent);

      // if (unreadContent.length > 0) {
      //   setActiveTab('updates');
      // }

      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      const sevenDaysTimestamp = dateToTimestamp(sevenDaysAgo);

      // Calculate thread activity stats
      const threadActivity = Object.values(thread_content)
        .filter(content => {
          const {status, created, thread_id} = content || {};
          const thread = threads?.[thread_id] ?? {};
          return (
            thread.type !== 'direct' &&
            status === 'active' &&
            created >= sevenDaysTimestamp
          );
        })
        .reduce((acc, content) => {
          const thread = threads[content.thread_id];
          if (!acc[content.thread_id]) {
            acc[content.thread_id] = {
              id: content.thread_id,
              title: thread?.title || 'Unknown',
              logo: thread?.logo,
              count: 0,
            };
          }
          acc[content.thread_id].count++;
          return acc;
        }, {});

      const breakdownData = Object.values(threadActivity)
        .sort((a, b) => b.count - a.count)
        .slice(0, 5)
        .map(thread => ({
          ...thread,
          percentage:
            (thread.count /
              Object.values(threadActivity).reduce(
                (sum, t) => sum + t.count,
                0,
              )) *
            100,
        }));

      setActivityStats({
        content: recentActivity,
        totalUnread: unreadContent.length,
        todayCount: recentActivity.length,
        activeThreads: userThreads.filter(
          id => threads[id]?.updated > (thread_subs[id]?.last_read || 0),
        ).length,
        totalThreads: userThreads.length,
        threadBreakdown: breakdownData,
      });
    } catch (err) {
      console.error('Error fetching thread data:', err);
    } finally {
      setLoadingUpdates(false);
    }
  };

  const userThreads = useMemo(
    () =>
      thread_ids.filter(
        id => threads[id]?.status === 'active' && thread_subs[id],
      ),
    [thread_ids, threads, thread_subs],
  );

  useEffect(() => {
    fetchData();
  }, [thread_content]);

  return (
    <div className="home-container">
      <div className="home-main-content">
        <div className="home-tabs">
          <button
            className={`home-tab ${activeTab === 'home' ? 'active' : ''}`}
            onClick={() => setActiveTab('home')}>
            {/* <FaRegSquarePlus /> */}
            Home
          </button>
          {isProUser(currentUserId) && (
            <button
              className={`home-tab ${activeTab === 'docs' ? 'active' : ''}`}
              onClick={() => setActiveTab('docs')}>
              Create Documents
            </button>
          )}
          <button
            className={`home-tab ${activeTab === 'updates' ? 'active' : ''}`}
            onClick={() => setActiveTab('updates')}>
            New Updates
            {unreadPosts.length > 0 && (
              <span className="home-update-count">{unreadPosts.length}</span>
            )}
          </button>
        </div>
        {activeTab === 'updates' && (
          <div className="home-welcome ">
            <div className="home-welcome-header">
              <h1 className="home-welcome-title">
                Welcome back, {profile?.first_name || 'friend!'}
              </h1>
              <MarkAllReadButton
                onMarkAllRead={handleMarkAllRead}
                unreadCount={activityStats.totalUnread}
                loading={markingRead}
              />
            </div>
            <p className="home-welcome-subtitle">
              Your workspace has {activityStats.totalUnread} updates since your
              last visit
            </p>
          </div>
        )}
        {activeTab === 'docs' && (
          <div className="home-welcome">
            <div className="home-welcome-header">
              <h1 className="home-welcome-title">
                Generate Professional Documents in Seconds
              </h1>
            </div>
            <p className="home-welcome-subtitle">
              Select a template to begin crafting your document
            </p>
          </div>
        )}
        {activeTab === 'home' && (
          <div className="home-welcome">
            <div className="home-welcome-header">
              <h1 className="home-welcome-title">
                Intelligence at Your Service
              </h1>
            </div>
            <p className="home-welcome-subtitle">
              Just post updates to your threads and I'll take care of the rest.
              I'm standing by to create emails, presentations, or whatever else
              you need.
            </p>
          </div>
        )}
        <div className="home-tab-content">
          {activeTab === 'updates' && loadingUpdates && <SpinningIndicator />}
          {activeTab === 'home' && !loadingUpdates && (
            <WorkLogger unreadPosts={unreadPosts} />
          )}
          {activeTab === 'docs' && !loadingUpdates && <DocumentGenerator />}

          {activeTab === 'updates' && !loadingUpdates ? (
            unreadPosts.length > 0 ? (
              <div className="home-updates-content">
                {unreadPosts.map(content => (
                  <HomeMentionItem
                    key={content.id}
                    item={content}
                    thread={threads[content.thread_id]}
                    onClick={() => {
                      if (threads[content.thread_id].type !== 'direct') {
                        setIndex('thread-detail');
                        setCurrent(content.thread_id);
                        setContent(content.id);
                      } else {
                        setDirectID(content?.thread_id);
                        setShowDMs(true);
                      }
                    }}
                    isUnread={true}
                  />
                ))}
              </div>
            ) : (
              <div className="home-empty-state">
                <RxClock className="home-empty-icon" />
                <p>You're all caught up!</p>
                <p>No new updates since your last visit</p>
              </div>
            )
          ) : null}
        </div>
      </div>
      <DirectMessages
        active={showDM}
        setActive={setShowDMs}
        directID={directID}
      />
    </div>
  );
};

export default ThreadsHome;
