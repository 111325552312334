import React, {useState} from 'react';
import {BiPoll} from 'react-icons/bi';
import {HiOutlineDocumentText} from 'react-icons/hi';
import {
  MdLabelImportantOutline,
  MdOutlineAttachment,
  MdVerified,
} from 'react-icons/md';
import {RiPushpinFill, RiPushpinLine} from 'react-icons/ri';
import {
  RxCheckbox,
  RxQuestionMarkCircled,
  RxSewingPinFilled,
  RxTrash,
} from 'react-icons/rx';
import './CommentActionButtons.css';

// BUTTONS FOR CREATING A COMMENT
export const CommentActionButtons = ({
  formik,
  editor,
  handleSendMessage,
  inputMessage,
  setInputMessage,
  copyString,
  setShowFileUploader,
  isEdit = false,
  files,
  setSchedule,
  setSpecialComment,
  isOfficial,
  setIsOfficial,
  canMakeOfficial,
}) => {
  const prio = formik?.values?.priority || false;

  const [responseMessage, setResponseMessage] = useState('');

  const handleEditorCleanup = () => {
    try {
      const blocks = editor.document;

      // Then remove them all at once
      if (blocks.length > 0) {
        editor.removeBlocks(blocks);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="button-group">
      <div className="left-buttons">
        <button
          type="submit"
          onClick={() => {
            setResponseMessage(`${isEdit ? 'Saving...' : 'Posting...'}`);
            handleSendMessage();
          }}
          disabled={
            (!editor?.document?.[0].content?.length && files?.length === 0) ||
            formik.isSubmitting
          }
          className={
            formik.isSubmitting
              ? 'loading'
              : !editor?.document?.[0].content?.length && files?.length === 0
              ? 'text-bold text-secondary-button border-secondary-button'
              : ''
          }
          title={isEdit ? 'Save changes' : 'Post comment'}>
          {formik.isSubmitting ? responseMessage : isEdit ? 'Save' : 'Post'}
        </button>
      </div>
      {!isEdit && (
        <div className="left-buttons">
          {!formik.isSubmitting && (
            <button
              onClick={() => {
                setSchedule(true);
                setResponseMessage('Scheduling...');
              }}
              disabled={
                (!editor?.document?.[0].content?.length &&
                  files?.length === 0) ||
                formik.isSubmitting
              }
              className={formik.isSubmitting ? 'loading' : ''}
              title="Schedule comment for later">
              {formik.isSubmitting ? responseMessage : 'Schedule'}
            </button>
          )}
        </div>
      )}
      <div className="right-buttons">
        {canMakeOfficial && (
          <OfficialPostToggle
            isOfficial={isOfficial}
            setIsOfficial={setIsOfficial}
          />
        )}
        <button
          onClick={() => setShowFileUploader(prev => !prev)}
          title="Attach file">
          <MdOutlineAttachment />
        </button>

        <button
          disabled={
            (!editor?.document?.length && files?.length === 0) ||
            formik.isSubmitting
          }
          onClick={() => formik.setFieldValue('priority', !prio)}
          title={prio ? 'Unpin comment' : 'Pin comment to top'}>
          {prio ? <RiPushpinFill /> : <RiPushpinLine />}
        </button>

        {/* <button
          disabled={
            (!editor?.document?.length && files?.length === 0) || formik.isSubmitting
          }
          onClick={() => copyString(inputMessage, true)}
          title="Copy text">
          <RxCopy />
        </button> */}
        <button
          onClick={() => {
            formik.handleReset();
            handleEditorCleanup();
          }}
          title="Clear input">
          <RxTrash />
        </button>

        <span className="vertical-line " />
        <button
          title={'Create decision post'}
          onClick={() => {
            setSpecialComment('decision_point');
          }}>
          <MdLabelImportantOutline />
        </button>

        <button
          title={'Create milestone post'}
          onClick={() => {
            setSpecialComment('milestone');
          }}>
          <RxSewingPinFilled />
        </button>
        <button
          title={'Create question post'}
          onClick={() => {
            setSpecialComment('question');
          }}>
          <RxQuestionMarkCircled />
        </button>
        <button
          title={'Create poll post'}
          onClick={() => {
            setSpecialComment('poll');
          }}>
          <BiPoll />
        </button>
        <button
          title={'Create form'}
          onClick={() => {
            setSpecialComment('form');
          }}>
          <HiOutlineDocumentText />
        </button>
        <button
          title={'Create task post'}
          onClick={() => {
            setSpecialComment('task');
          }}>
          <RxCheckbox />
        </button>
        {/* <button
              disabled={editor?.document?.length || files?.length > 0}
              title={
                editor?.document?.length || files?.length > 0
                  ? 'Disabled when creating a post'
                  : 'Create meeting post'
              }
              onClick={() => {
                setSpecialComment('meeting');
              }}>
              <MdEventNote />
            </button> */}
      </div>
    </div>
  );
};

export const CommentActionButtonsHome = ({
  formik,
  editor,
  handleSendMessage,

  setShowFileUploader,
  isEdit = false,
  files,

  isOfficial,
  setIsOfficial,
  canMakeOfficial,
}) => {
  const prio = formik?.values?.priority || false;

  const [responseMessage, setResponseMessage] = useState('');

  const handleEditorCleanup = () => {
    try {
      const blocks = editor.document;

      // Then remove them all at once
      if (blocks.length > 0) {
        editor.removeBlocks(blocks);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="button-group">
      <div className="left-buttons">
        <button
          type="submit"
          onClick={() => {
            setResponseMessage(`${isEdit ? 'Saving...' : 'Posting...'}`);
            handleSendMessage();
          }}
          disabled={
            (!editor?.document?.[0].content?.length && files?.length === 0) ||
            formik.isSubmitting
          }
          className={
            formik.isSubmitting
              ? 'loading'
              : !editor?.document?.[0].content?.length && files?.length === 0
              ? 'text-bold text-secondary-button border-secondary-button'
              : ''
          }
          title={isEdit ? 'Save changes' : 'Post comment'}>
          {formik.isSubmitting ? responseMessage : isEdit ? 'Save' : 'Post'}
        </button>
      </div>

      <div className="right-buttons">
        {canMakeOfficial && (
          <OfficialPostToggle
            isOfficial={isOfficial}
            setIsOfficial={setIsOfficial}
          />
        )}
        <button
          onClick={() => setShowFileUploader(prev => !prev)}
          title="Attach file">
          <MdOutlineAttachment />
        </button>

        <button
          onClick={() => {
            formik.handleReset();
            handleEditorCleanup();
          }}
          title="Clear input">
          <RxTrash />
        </button>

        <span className="vertical-line " />
      </div>
    </div>
  );
};
// BUTTONS FOR CREATING A REPLY
export const ReplyActionButtons = ({
  formik,
  editor,
  handleSendMessage,
  inputMessage,
  setInputMessage,
  copyString,
  setShowFileUploader,
  isEdit = false,
  files,
}) => {
  const [responseMessage, setResponseMessage] = useState('');

  const handleEditorCleanup = () => {
    try {
      const blocks = editor.document;
      // Then remove them all at once
      if (blocks.length > 0) {
        editor.removeBlocks(blocks);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="button-group">
        <div className="left-buttons">
          <button
            type="submit"
            onClick={() => {
              setResponseMessage(`${isEdit ? 'Saving...' : 'Commenting...'}`);
              handleSendMessage();
            }}
            disabled={
              !editor?.document?.[0].content?.length || formik.isSubmitting
            }
            className={formik.isSubmitting ? 'loading' : ''}
            title={isEdit ? 'Save changes' : 'Post comment'}>
            {formik.isSubmitting
              ? responseMessage
              : isEdit
              ? 'Save'
              : 'Comment'}
          </button>
        </div>
        {!isEdit && (
          <div className="left-buttons">
            {!formik.isSubmitting && (
              <button
                onClick={() => {
                  setResponseMessage('Scheduling...');
                }}
                disabled={
                  !editor?.document?.[0].content?.length || formik.isSubmitting
                }
                className={formik.isSubmitting ? 'loading' : ''}
                title="Schedule comment for later">
                {formik.isSubmitting ? responseMessage : 'Schedule'}
              </button>
            )}
          </div>
        )}
        <div className="right-buttons">
          <button
            onClick={() => setShowFileUploader(prev => !prev)}
            title="Attach file">
            <MdOutlineAttachment />
          </button>
          {/* <button
            disabled={
              (!editor?.document?.length && files?.length === 0) ||
              formik.isSubmitting
            }
            onClick={() => copyString(inputMessage, true)}
            title="Copy text">
            <RxCopy />
          </button> */}
          <button
            disabled={
              !editor?.document?.[0].content?.length || formik.isSubmitting
            }
            onClick={() => {
              formik.handleReset();
              handleEditorCleanup();
            }}
            title="Clear input">
            <RxTrash />
          </button>
        </div>
      </div>
    </>
  );
};
export const OfficialPostToggle = ({isOfficial, setIsOfficial}) => {
  return (
    <button
      onClick={() => setIsOfficial(!isOfficial)}
      className={`official-post-toggle ${isOfficial ? 'active' : ''}`}
      title={
        isOfficial ? 'Switch to regular post' : 'Make official PublicMind post'
      }>
      <MdVerified className={isOfficial ? 'text-primary-button' : ''} />
    </button>
  );
};
