import {generateClient} from 'aws-amplify/api';
import {
  createEvent,
  createGroup,
  createGroupInvitation,
  createInteraction,
  createMessage,
  createMessageChain,
  createOpportunity,
  createOrganization,
  createPageView,
  createPost,
  createReferral,
  createRolodex,
  createTemplate,
  createThread,
  createThreadContent,
  createThreadRequest,
  createThreadSubscriptions,
  createUser,
  createWaitlist,
  deleteOpportunity,
  deleteOrganization,
  deletePageView,
  deleteTemplate,
  deleteThreadContent,
  deleteThreadSubscriptions,
  deleteUser,
  updateGroup,
  updateGroupInvitation,
  updateMessage,
  updateMessageChain,
  updateOpportunity,
  updateOrganization,
  updatePageView,
  updatePost,
  updateReferral,
  updateRolodex,
  updateTemplate,
  updateThread,
  updateThreadContent,
  updateThreadRequest,
  updateThreadSubscriptions,
  updateUser,
} from '../graphql/mutations';
import {
  getGroup,
  getGroupInvitation,
  getMessageChain,
  getOpportunity,
  getOrganization,
  getPageView,
  getPost,
  getReferral,
  getRolodex,
  getThread,
  getThreadContent,
  getThreadRequest,
  getThreadSubscriptions,
  getUser,
  interactionsBySource_userAndTimestamp,
  listGroupInvitations,
  listGroups,
  listOpportunities,
  listOrganizations,
  listPageViews,
  listPosts,
  listReferrals,
  listThreadContents,
  listThreads,
  listThreadSubscriptions,
  listUsers,
  messageChainsByTypeAndLast_timestamp,
  messagesByMessage_chainAndTimestamp,
  opportunitiesByStatusAndTimestamp,
  postsByStatusAndTimestamp,
  rolodexesBySource_user,
  templatesByOwner_id,
  threadContentsByThread_id,
  threadRequestsByThread_id,
  threadSubscriptionsByUser_id,
} from '../graphql/queries';
import {
  onCreateMessage,
  onCreateMessageChain,
  onCreateThread,
  onCreateThreadContent,
  onCreateThreadRequest,
  onUpdateThread,
  onUpdateThreadContent,
  onUpdateUser,
} from '../graphql/subscriptions';
import {track} from './analytics';
import {dateToTimestamp} from './dates';

const client = generateClient();

export const stripTypenames = obj => {
  if (Array.isArray(obj)) {
    return obj.map(stripTypenames);
  } else if (obj !== null && typeof obj === 'object') {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      if (key !== '__typename') {
        newObj[key] = stripTypenames(value);
      }
    }
    return newObj;
  }
  return obj;
};

// USER
const setProfile = async (user, options) => {
  try {
    const {data} = await client.graphql({
      query: createUser,
      variables: {input: user},
      ...options,
    });
    // track('api_success', {type: 'graphql', function: 'createUser'});
    return data.createUser;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'createUser', err});
    throw err;
  }
};

const updateUserProfile = async (user, options) => {
  try {
    const {data} = await client.graphql({
      query: updateUser,
      variables: {input: user},
      ...options,
    });
    // track('api_success', {type: 'graphql', function: 'updateUser'});
    return data.updateUser;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'updateUser', err});
    throw err;
  }
};

const updateUserProfileListener = async user => {
  try {
    const sub = client
      .graphql({query: onUpdateUser, variables: {id: user.id}})
      .subscribe({
        next: data => console.log(data),
        error: error => console.log(error),
      });
    // track('api_success', {type: 'graphql', function: 'onUpdateUser'});
    return sub;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'onUpdateUser', err});
    throw err;
  }
};

const fetchProfile = async (id, options) => {
  try {
    const {data} = await client.graphql({
      query: getUser,
      variables: {id},
      ...options,
    });
    // track('api_success', {type: 'graphql', function: 'getUser'});
    return stripTypenames(data.getUser);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'getUser', err});
    throw err;
  }
};

const fetchProfiles = async (params, options) => {
  try {
    const {filter, limit, nextToken} = params;
    const response = await client.graphql({
      query: listUsers,
      variables: {filter, limit, nextToken},
      ...options,
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'listUsers'});
    return stripTypenames(data.listUsers);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'listUsers', err});
    throw err;
  }
};

const removeProfile = async prof => {
  try {
    const {data} = await client.graphql({
      query: deleteUser,
      variables: {input: prof},
    });
    // track('api_success', {type: 'graphql', function: 'deleteUser'});
    return data.deleteUser;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'deleteUser', err});
    throw err;
  }
};

// ORGANIZATION
const setOrg = async org => {
  try {
    const {data} = await client.graphql({
      query: createOrganization,
      variables: {input: org},
    });
    // track('api_success', {
    //   type: 'graphql',
    // function: 'createOrganization',
    // });
    return data.createOrganization;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'createOrganization', err});
    throw err;
  }
};

const updateOrg = async org => {
  try {
    const {data} = await client.graphql({
      query: updateOrganization,
      variables: {input: org},
    });
    // track('api_success', {
    //   type: 'graphql',
    // function: 'updateOrganization',
    // });
    return data.updateOrganization;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'updateOrganization', err});
    throw err;
  }
};

const fetchOrg = async (id, options) => {
  try {
    const {data} = await client.graphql({
      query: getOrganization,
      variables: {id},
      ...options,
    });
    // track('api_success', {type: 'graphql', function: 'getOrganization'});
    return stripTypenames(data.getOrganization);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'getOrganization', err});
    throw err;
  }
};

const listOrgs = async params => {
  try {
    const {filter, limit, nextToken} = params;
    const response = await client.graphql({
      query: listOrganizations,
      variables: {filter, limit, nextToken},
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'listOrganizations'});
    return stripTypenames(data.listOrganizations);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'listOrganizations', err});
    throw err;
  }
};

const removeOrg = async org => {
  try {
    const {data} = await client.graphql({
      query: deleteOrganization,
      variables: {input: org},
    });
    // track('api_success', {
    //   type: 'graphql',
    // function: 'deleteOrganization',
    // });
    return data.deleteOrganization;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'deleteOrganization', err});
    throw err;
  }
};

// GROUPS
const setGroup = async eco => {
  try {
    const {data} = await client.graphql({
      query: createGroup,
      variables: {input: eco},
    });
    // track('api_success', {type: 'graphql', function: 'createGroup'});
    return data.createGroup;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'createGroup', err});
    throw err;
  }
};

const updateGrp = async eco => {
  try {
    const {data} = await client.graphql({
      query: updateGroup,
      variables: {input: eco},
    });
    // track('api_success', {type: 'graphql', function: 'updateGroup'});
    return data.updateGroup;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'updateGroup', err});
    throw err;
  }
};

const fetchGrp = async id => {
  try {
    const {data} = await client.graphql({
      query: getGroup,
      variables: {id},
    });
    // track('api_success', {type: 'graphql', function: 'getGroup'});
    return stripTypenames(data.getGroup);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'getGroup', err});
    throw err;
  }
};

const listGrps = async ({filter, limit, nextToken} = {}) => {
  try {
    const response = await client.graphql({
      query: listGroups,
      variables: {filter, limit, nextToken},
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'listGroups'});
    return stripTypenames(data.listGroups);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'listGroups', err});
    throw err;
  }
};

// MESSAGE CHAINS
const setChain = async chain => {
  try {
    const {data} = await client.graphql({
      query: createMessageChain,
      variables: {input: chain},
    });
    // track('api_success', {
    //   type: 'graphql',
    // function: 'createMessageChain',
    // });
    return data.createMessageChain;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'createMessageChain', err});
    throw err;
  }
};

const fetchChain = async id => {
  try {
    const {data} = await client.graphql({
      query: getMessageChain,
      variables: {id},
    });
    // track('api_success', {type: 'graphql', function: 'getMessageChain'});
    return stripTypenames(data.getMessageChain);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'getMessageChain', err});
    throw err;
  }
};

const updateChain = async chain => {
  try {
    const {data} = await client.graphql({
      query: updateMessageChain,
      variables: {input: chain},
    });
    // track('api_success', {
    //   type: 'graphql',
    // function: 'updateMessageChain',
    // });
    return data.updateMessageChain;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'updateMessageChain', err});
    throw err;
  }
};

const listSortedChains = async ({
  type,
  last_timestamp,
  sortDirection,
  filter,
  limit,
  nextToken,
} = {}) => {
  try {
    const response = await client.graphql({
      query: messageChainsByTypeAndLast_timestamp,
      variables: {
        type,
        last_timestamp,
        sortDirection,
        filter,
        limit,
        nextToken,
      },
    });
    const {data} = response;
    // track('api_success', {
    //   type: 'graphql',
    // function: 'messageChainsByTypeAndLast_timestamp',
    // });
    return data.messageChainsByTypeAndLast_timestamp;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'messageChainsByTypeAndLast_timestamp',
      err,
    });
    throw err;
  }
};

const chainListener = (variables, onEvent, onError) => {
  try {
    const sub = client
      .graphql({query: onCreateMessageChain, variables})
      .subscribe({
        next: value => {
          if (onEvent) {
            const {
              data: {onCreateMessageChain},
            } = value;
            onEvent({value: onCreateMessageChain});
          } else {
            console.log({value});
          }
        },
        error: error => {
          if (onError) {
            onError(error);
          } else {
            console.log(error);
          }
        },
      });
    // track('api_success', {
    //   type: 'graphql',
    // function: 'onCreateMessageChain',
    // });
    return sub;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'onCreateMessageChain',
      err,
    });
    throw err;
  }
};

// MESSAGES
const setMessage = async message => {
  try {
    const {data} = await client.graphql({
      query: createMessage,
      variables: {input: message},
    });
    // track('api_success', {type: 'graphql', function: 'createMessage'});
    return data.createMessage;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'createMessage', err});
    throw err;
  }
};

const updateMsg = async message => {
  try {
    const {data} = await client.graphql({
      query: updateMessage,
      variables: {input: message},
    });
    // track('api_success', {type: 'graphql', function: 'updateMessage'});
    return data.updateMessage;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'updateMessage', err});
    throw err;
  }
};

const messageListener = (variables, onEvent, onError) => {
  try {
    const sub = client.graphql({query: onCreateMessage, variables}).subscribe({
      next: value => {
        if (onEvent) {
          const {
            data: {onCreateMessage},
          } = value;
          onEvent({value: onCreateMessage});
        } else {
          console.log({value});
        }
      },
      error: error => {
        if (onError) {
          onError(error);
        } else {
          console.log(error);
        }
      },
    });
    // track('api_success', {type: 'graphql', function: 'onCreateMessage'});
    return sub;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'onCreateMessage', err});
    throw err;
  }
};

const listSortedMessages = async ({
  message_chain,
  timestamp,
  sortDirection,
  filter,
  limit,
  nextToken,
} = {}) => {
  try {
    const response = await client.graphql({
      query: messagesByMessage_chainAndTimestamp,
      variables: {
        message_chain,
        timestamp,
        sortDirection,
        filter,
        limit,
        nextToken,
      },
    });
    const {data} = response;
    // track('api_success', {
    //   type: 'graphql',
    // function: 'messagesByMessage_chainAndTimestamp',
    // });
    return data.messagesByMessage_chainAndTimestamp;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'messagesByMessage_chainAndTimestamp',
      err,
    });
    throw err;
  }
};

// EVENTS
const setEvent = async event => {
  try {
    const {data} = await client.graphql({
      query: createEvent,
      variables: {input: event},
    });
    // track('api_success', {type: 'graphql', function: 'createEvent'});
    return data.createEvent;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'createEvent', err});
    throw err;
  }
};

// WAITLIST
const setWaitlist = async waitlist => {
  try {
    const {data} = await client.graphql({
      query: createWaitlist,
      variables: {input: waitlist},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'createWaitlist'});
    return data.createWaitlist;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'createWaitlist', err});
    throw err;
  }
};

// PAGE VIEW
const setPageView = async view => {
  try {
    const {data} = await client.graphql({
      query: createPageView,
      variables: {input: view},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'createPageView'});
    return data.createPageView;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'createPageView', err});
    throw err;
  }
};

const updateView = async view => {
  try {
    const {data} = await client.graphql({
      query: updatePageView,
      variables: {input: view},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'updatePageView'});
    return data.updatePageView;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'updatePageView', err});
    throw err;
  }
};

const deleteView = async view => {
  try {
    const {data} = await client.graphql({
      query: deletePageView,
      variables: {input: view},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'deletePageView'});
    return data.deletePageView;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'deletePageView', err});
    throw err;
  }
};

const fetchPageView = async id => {
  try {
    const {data} = await client.graphql({
      query: getPageView,
      variables: {id},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'getPageView'});
    return stripTypenames(data.getPageView);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'getPageView', err});
    throw err;
  }
};

const fetchPageViews = async params => {
  try {
    const {filter, limit, nextToken} = params;
    const response = await client.graphql({
      query: listPageViews,
      variables: {filter, limit, nextToken},
      authMode: 'apiKey',
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'listPageViews'});
    return stripTypenames(data.listPageViews);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'listPageViews', err});
    throw err;
  }
};

// POSTS
const setPost = async post => {
  try {
    const {data} = await client.graphql({
      query: createPost,
      variables: {input: post},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'createPost'});
    return data.createPost;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'createPost', err});
    throw err;
  }
};

const fetchPost = async id => {
  try {
    const {data} = await client.graphql({
      query: getPost,
      variables: {id},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'getPost'});
    return stripTypenames(data.getPost);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'getPost', err});
    throw err;
  }
};

const fetchPosts = async params => {
  try {
    const {filter, limit, nextToken} = params;
    const response = await client.graphql({
      query: listPosts,
      variables: {filter, limit, nextToken},
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'listPosts'});
    return stripTypenames(data.listPosts);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'listPosts', err});
    throw err;
  }
};

const updatePosts = async post => {
  try {
    const {data} = await client.graphql({
      query: updatePost,
      variables: {input: post},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'updatePost'});
    return data.updatePost;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'updatePost', err});
    throw err;
  }
};

const listSortedPosts = async ({
  status,
  timestamp,
  sortDirection,
  filter,
  limit,
  nextToken,
} = {}) => {
  try {
    const response = await client.graphql({
      query: postsByStatusAndTimestamp,
      variables: {
        status,
        timestamp,
        sortDirection,
        filter,
        limit,
        nextToken,
      },
      authMode: 'apiKey',
    });
    const {data} = response;
    // track('api_success', {
    //   type: 'graphql',
    // function: 'postsByStatusAndTimestamp',
    // });
    return stripTypenames(data.postsByStatusAndTimestamp);
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'postsByStatusAndTimestamp',
      err,
    });
    throw err;
  }
};

// REFERRALS
const setReferral = async referral => {
  try {
    const {data} = await client.graphql({
      query: createReferral,
      variables: {input: referral},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'createReferral'});
    return data.createReferral;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'createReferral', err});
    throw err;
  }
};

const updateRef = async referral => {
  try {
    const {data} = await client.graphql({
      query: updateReferral,
      variables: {input: referral},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'updateReferral'});
    return data.updateReferral;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'updateReferral', err});
    throw err;
  }
};

const fetchReferrals = async params => {
  try {
    const {filter, limit, nextToken} = params;
    const response = await client.graphql({
      query: listReferrals,
      variables: {filter, limit, nextToken},
      authMode: 'apiKey',
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'listReferrals'});
    return stripTypenames(data.listReferrals);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'listReferrals', err});
    throw err;
  }
};

const fetchReferral = async (id, options) => {
  try {
    const {data} = await client.graphql({
      query: getReferral,
      variables: {id},
      ...options,
    });
    // track('api_success', {type: 'graphql', function: 'getReferral'});
    return stripTypenames(data.getReferral);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'getReferral', err});
    throw err;
  }
};

// OPPORTUNITIES
const setOpp = async opp => {
  try {
    const {data} = await client.graphql({
      query: createOpportunity,
      variables: {input: opp},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'createOpportunity'});
    return data.createOpportunity;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'createOpportunity', err});
    throw err;
  }
};

const deleteOpp = async opp => {
  try {
    const {data} = await client.graphql({
      query: deleteOpportunity,
      variables: {input: opp},
    });
    // track('api_success', {type: 'graphql', function: 'deleteOpportunity'});
    return data.deleteOpportunity;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'deleteOpportunity', err});
    throw err;
  }
};

const fetchOpp = async id => {
  try {
    const {data} = await client.graphql({
      query: getOpportunity,
      variables: {id},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'getOpportunity'});
    return stripTypenames(data.getOpportunity);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'getOpportunity', err});
    throw err;
  }
};

const updateOpp = async opp => {
  try {
    const {data} = await client.graphql({
      query: updateOpportunity,
      variables: {input: opp},
    });
    // track('api_success', {type: 'graphql', function: 'updateOpportunity'});
    return data.updateOpportunity;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'updateOpportunity', err});
    throw err;
  }
};

const fetchOpps = async params => {
  try {
    const {filter, limit, nextToken} = params;
    const response = await client.graphql({
      query: listOpportunities,
      variables: {filter, limit, nextToken},
      authMode: 'apiKey',
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'listOpportunities'});
    return stripTypenames(data.listOpportunities);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'listOpportunities', err});
    throw err;
  }
};

const listSortedOpps = async ({
  status,
  timestamp,
  sortDirection,
  filter,
  limit,
  nextToken,
} = {}) => {
  try {
    const response = await client.graphql({
      query: opportunitiesByStatusAndTimestamp,
      variables: {
        status,
        timestamp,
        sortDirection,
        filter,
        limit,
        nextToken,
      },
    });
    const {data} = response;
    return data.opportunitiesByStatusAndTimestamp;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'opportunitiesByStatusAndTimestamp',
      err,
    });
    throw err;
  }
};

// ROLODEX
const setDeck = async opp => {
  try {
    const {data} = await client.graphql({
      query: createRolodex,
      variables: {input: opp},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'createRolodex'});
    return data.createRolodex;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'createRolodex', err});
    throw err;
  }
};

const fetchDeck = async id => {
  try {
    const {data} = await client.graphql({
      query: getRolodex,
      variables: {id},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'getRolodex'});
    return stripTypenames(data.getRolodex);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'getRolodex', err});
    throw err;
  }
};

const updateDeck = async opp => {
  try {
    const {data} = await client.graphql({
      query: updateRolodex,
      variables: {input: opp},
    });
    // track('api_success', {type: 'graphql', function: 'updateRolodex'});
    return data.updateRolodex;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'updateRolodex', err});
    throw err;
  }
};

const listDeckByUser = async params => {
  try {
    const {source_user, sortDirection, filter, limit, nextToken} = params;
    const response = await client.graphql({
      query: rolodexesBySource_user,
      variables: {source_user, sortDirection, filter, limit, nextToken},
      authMode: 'apiKey',
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'rolodexesBySource_user'});
    return stripTypenames(data.rolodexesBySource_user);
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'rolodexesBySource_user',
      err,
    });
    throw err;
  }
};

// INTERACTIONS

const setInteraction = async opp => {
  try {
    const {data} = await client.graphql({
      query: createInteraction,
      variables: {input: opp},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'createInteraction'});
    return data.createInteraction;
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'createInteraction', err});
    throw err;
  }
};
const listSortedInteractions = async ({
  source_user,
  timestamp,
  sortDirection,
  filter,
  limit,
  nextToken,
} = {}) => {
  try {
    const response = await client.graphql({
      query: interactionsBySource_userAndTimestamp,
      variables: {
        source_user,
        timestamp,
        sortDirection,
        filter,
        limit,
        nextToken,
      },
      authMode: 'apiKey',
    });
    const {data} = response;
    // track('api_success', {
    //   type: 'graphql',
    // function: 'interactionsBySource_userAndTimestamp',
    // });
    return stripTypenames(data.interactionsBySource_userAndTimestamp);
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'interactionsBySource_userAndTimestamp',
      err,
    });
    throw err;
  }
};

// GROUP INVITES
const setInvite = async referral => {
  try {
    const {data} = await client.graphql({
      query: createGroupInvitation,
      variables: {input: referral},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'createGroupInvitation'});
    return data.createGroupInvitation;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'createGroupInvitation',
      err,
    });
    throw err;
  }
};

const updateInvite = async referral => {
  try {
    const {data} = await client.graphql({
      query: updateGroupInvitation,
      variables: {input: referral},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'updateGroupInvitation'});
    return data.updateGroupInvitation;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'updateGroupInvitation',
      err,
    });
    throw err;
  }
};

const fetchInvites = async params => {
  try {
    const {filter, limit, nextToken} = params;
    const response = await client.graphql({
      query: listGroupInvitations,
      variables: {filter, limit, nextToken},
      authMode: 'apiKey',
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'listGroupInvitations'});
    return stripTypenames(data.listGroupInvitations);
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'listGroupInvitations',
      err,
    });
    throw err;
  }
};

const fetchInvite = async (id, options) => {
  try {
    const {data} = await client.graphql({
      query: getGroupInvitation,
      variables: {id},
      ...options,
    });
    // track('api_success', {type: 'graphql', function: 'getGroupInvitation'});
    return stripTypenames(data.getGroupInvitation);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'getGroupInvitation', err});
    throw err;
  }
};

const setThread = async thread => {
  try {
    const {data} = await client.graphql({
      query: createThread,
      variables: {input: thread},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'createGroupInvitation'});
    return stripTypenames(data.createThread);
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'createThread',
      err,
    });
    throw err;
  }
};

const updateThreadChain = async update => {
  try {
    const {data} = await client.graphql({
      query: updateThread,
      variables: {input: update},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'updateThread'});
    return data.updateThread;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'updateThread',
      err,
    });
    throw err;
  }
};

const fetchThreads = async params => {
  try {
    const {filter, limit, nextToken} = params;
    const response = await client.graphql({
      query: listThreads,
      variables: {filter, limit, nextToken},
      authMode: 'apiKey',
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'listThreads'});
    return stripTypenames(data.listThreads);
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'listThreads',
      err,
    });
    throw err;
  }
};

const fetchThread = async (id, options) => {
  try {
    const {data} = await client.graphql({
      query: getThread,
      variables: {id},
      authMode: 'apiKey',
      ...options,
    });
    // track('api_success', {type: 'graphql', function: 'getThread'});
    return stripTypenames(data.getThread);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'getThread', err});
    throw err;
  }
};

const setThreadContent = async thread => {
  try {
    const {data} = await client.graphql({
      query: createThreadContent,
      variables: {input: thread},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'createGroupInvitation'});
    return data.createThreadContent;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'createThreadContent',
      err,
    });
    throw err;
  }
};

const removeThreadContent = async thread => {
  try {
    const {data} = await client.graphql({
      query: deleteThreadContent,
      variables: {input: thread},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'deleteThreadContent'});
    return data.deleteThreadContent;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'deleteThreadContent',
      err,
    });
    throw err;
  }
};

const updateThreadContentMessage = async update => {
  try {
    const {data} = await client.graphql({
      query: updateThreadContent,
      variables: {input: update},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'updateThreadContent'});
    return data.updateThreadContent;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'updateThreadContent',
      err,
    });
    throw err;
  }
};

const fetchThreadContents = async params => {
  try {
    const {thread_id, sortDirection, filter, limit, nextToken} = params;
    const response = await client.graphql({
      query: threadContentsByThread_id,
      variables: {thread_id, sortDirection, filter, limit, nextToken},
      authMode: 'apiKey',
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'threadContentsByThread_id'});
    return stripTypenames(data.threadContentsByThread_id);
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'threadContentsByThread_id',
      err,
    });
    throw err;
  }
};

const fetchUnsortedThreadContents = async params => {
  try {
    const {filter, limit, nextToken} = params;
    const response = await client.graphql({
      query: listThreadContents,
      variables: {filter, limit, nextToken},
      authMode: 'apiKey',
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'listThreadContents'});
    return stripTypenames(data.listThreadContents);
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'listThreadContents',
      err,
    });
    throw err;
  }
};

const fetchThreadContent = async (id, options) => {
  try {
    const {data} = await client.graphql({
      query: getThreadContent,
      variables: {id},
      authMode: 'apiKey',
      ...options,
    });
    // track('api_success', {type: 'graphql', function: 'getThreadContent'});
    return stripTypenames(data.getThreadContent);
  } catch (err) {
    track('api_error', {type: 'graphql', function: 'getThreadContent', err});
    throw err;
  }
};

const setThreadSub = async thread => {
  try {
    const {data} = await client.graphql({
      query: createThreadSubscriptions,
      variables: {input: thread},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'createGroupInvitation'});
    return data.createThreadSubscriptions;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'createThreadSubscriptions',
      err,
    });
    throw err;
  }
};

const updateThreadSub = async update => {
  try {
    const {data} = await client.graphql({
      query: updateThreadSubscriptions,
      variables: {input: update},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'updateThreadSubscriptions'});
    return data.updateThreadSubscriptions;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'updateThreadSubscriptions',
      err,
    });
    throw err;
  }
};

const fetchThreadSubsByUser = async params => {
  try {
    const {user_id, sortDirection, filter, limit, nextToken} = params;
    const response = await client.graphql({
      query: threadSubscriptionsByUser_id,
      variables: {user_id, sortDirection, filter, limit, nextToken},
      authMode: 'apiKey',
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'threadSubscriptionsByUser_id'});

    return stripTypenames(data.threadSubscriptionsByUser_id);
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'threadSubscriptionsByUser_id',
      err,
    });
    throw err;
  }
};

const fetchThreadSubs = async params => {
  try {
    const {filter, limit, nextToken} = params;
    const response = await client.graphql({
      query: listThreadSubscriptions,
      variables: {filter, limit, nextToken},
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'listThreadSubscriptions'});
    return stripTypenames(data.listThreadSubscriptions);
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'listThreadSubscriptions',
      err,
    });
    throw err;
  }
};

const removeThreadSub = async thread => {
  try {
    const {data} = await client.graphql({
      query: deleteThreadSubscriptions,
      variables: {input: thread},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'deleteThreadSubscriptions'});
    return data.deleteThreadSubscriptions;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'deleteThreadSubscriptions',
      err,
    });
    throw err;
  }
};

const fetchAllThreadSubs = async params => {
  try {
    const {filter, limit, nextToken} = params;
    const response = await client.graphql({
      query: listThreadSubscriptions,
      variables: {filter, limit, nextToken},
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'listThreadSubscriptions'});
    return stripTypenames(data.listThreadSubscriptions);
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'listThreadSubscriptions',
      err,
    });
    throw err;
  }
};

const fetchThreadSub = async (id, options) => {
  try {
    const {data} = await client.graphql({
      query: getThreadSubscriptions,
      variables: {id},
      authMode: 'apiKey',
      ...options,
    });
    // track('api_success', {type: 'graphql', function: 'getThreadSubscriptions'});
    return stripTypenames(data.getThreadSubscriptions);
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'getThreadSubscriptions',
      err,
    });
    throw err;
  }
};

const threadContentListener = (variables, onEvent, onError) => {
  try {
    const sub = client
      .graphql({query: onCreateThreadContent, variables})
      .subscribe({
        next: value => {
          if (onEvent) {
            const {
              data: {onCreateThreadContent},
            } = value;
            onEvent({value: onCreateThreadContent});
          } else {
            console.log({value});
          }
        },
        error: error => {
          if (onError) {
            onError(error);
          } else {
            console.log(error);
          }
        },
      });
    // track('api_success', {type: 'graphql', function: 'onCreateThreadContent'});
    return sub;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'onCreateThreadContent',
      err,
    });
    throw err;
  }
};

const threadContentUpdatedListener = (variables, onEvent, onError) => {
  try {
    const sub = client
      .graphql({query: onUpdateThreadContent, variables})
      .subscribe({
        next: value => {
          if (onEvent) {
            const {
              data: {onUpdateThreadContent},
            } = value;
            onEvent({value: stripTypenames(onUpdateThreadContent)});
          } else {
            console.log({value});
          }
        },
        error: error => {
          if (onError) {
            onError(error);
          } else {
            console.log(error);
          }
        },
      });
    // track('api_success', {type: 'graphql', function: 'onUpdateThread'});
    return sub;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'onUpdateThreadContent',
      err,
    });
    throw err;
  }
};

const setThreadRequest = async thread => {
  try {
    const {data} = await client.graphql({
      query: createThreadRequest,
      variables: {input: thread},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'createGroupInvitation'});
    return data.createThreadRequest;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'createThreadRequest',
      err,
    });
    throw err;
  }
};

const updateThreadReq = async update => {
  try {
    const {data} = await client.graphql({
      query: updateThreadRequest,
      variables: {input: update},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'updateThreadRequest'});
    return data.updateThreadRequest;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'updateThreadRequest',
      err,
    });
    throw err;
  }
};

const fetchThreadRequests = async params => {
  try {
    const {thread_id, sortDirection, filter, limit, nextToken} = params;
    const response = await client.graphql({
      query: threadRequestsByThread_id,
      variables: {thread_id, sortDirection, filter, limit, nextToken},
      authMode: 'apiKey',
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'threadRequestsByThread_id'});

    return stripTypenames(data.threadRequestsByThread_id);
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'threadRequestsByThread_id',
      err,
    });
    throw err;
  }
};

const fetchThreadReq = async (id, options) => {
  try {
    const {data} = await client.graphql({
      query: getThreadRequest,
      variables: {id},
      authMode: 'apiKey',
      ...options,
    });
    // track('api_success', {type: 'graphql', function: 'getThreadRequest'});
    return stripTypenames(data.getThreadRequest);
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'getThreadRequest',
      err,
    });
    throw err;
  }
};

const setThreadTemplate = async template => {
  try {
    const {data} = await client.graphql({
      query: createTemplate,
      variables: {input: template},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'createTemplate'});
    return data.createTemplate;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'createTemplate',
      err,
    });
    throw err;
  }
};

const updateThreadTemplate = async update => {
  try {
    const {data} = await client.graphql({
      query: updateTemplate,
      variables: {input: update},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'updateTemplate'});
    return data.updateTemplate;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'updateTemplate',
      err,
    });
    throw err;
  }
};

const deleteThreadTemplate = async template => {
  try {
    const {data} = await client.graphql({
      query: deleteTemplate,
      variables: {input: template},
      authMode: 'apiKey',
    });
    // track('api_success', {type: 'graphql', function: 'deleteTemplate'});
    return data.deleteTemplate;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'deleteTemplate',
      err,
    });
    throw err;
  }
};

const fetchTemplates = async params => {
  try {
    const {owner_id, sortDirection, filter, limit, nextToken} = params;
    const response = await client.graphql({
      query: templatesByOwner_id,
      variables: {owner_id, sortDirection, filter, limit, nextToken},
      authMode: 'apiKey',
    });
    const {data} = response;
    // track('api_success', {type: 'graphql', function: 'templatesByOwner_id'});

    return stripTypenames(data.templatesByOwner_id);
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'templatesByOwner_id',
      err,
    });
    throw err;
  }
};

const threadRequestListener = (variables, onEvent, onError) => {
  try {
    const sub = client
      .graphql({query: onCreateThreadRequest, variables})
      .subscribe({
        next: value => {
          if (onEvent) {
            const {
              data: {onCreateThreadRequest},
            } = value;
            onEvent({value: onCreateThreadRequest});
          } else {
            console.log({value});
          }
        },
        error: error => {
          if (onError) {
            onError(error);
          } else {
            console.log(error);
          }
        },
      });
    // track('api_success', {type: 'graphql', function: 'onCreateThreadRequest'});
    return sub;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'onCreateThreadRequest',
      err,
    });
    throw err;
  }
};

const threadCreatedListener = (variables, onEvent, onError) => {
  try {
    const sub = client.graphql({query: onCreateThread, variables}).subscribe({
      next: value => {
        if (onEvent) {
          const {
            data: {onCreateThread},
          } = value;
          onEvent({value: onCreateThread});
        } else {
          console.log({value});
        }
      },
      error: error => {
        if (onError) {
          onError(error);
        } else {
          console.log(error);
        }
      },
    });
    // track('api_success', {type: 'graphql', function: 'onCreateThread'});
    return sub;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'onCreateThread',
      err,
    });
    throw err;
  }
};

const threadUpdatedListener = (variables, onEvent, onError) => {
  try {
    const sub = client.graphql({query: onUpdateThread, variables}).subscribe({
      next: value => {
        if (onEvent) {
          const {
            data: {onUpdateThread},
          } = value;
          onEvent({value: onUpdateThread});
        } else {
          console.log({value});
        }
      },
      error: error => {
        if (onError) {
          onError(error);
        } else {
          console.log(error);
        }
      },
    });
    // track('api_success', {type: 'graphql', function: 'onUpdateThread'});
    return sub;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'onUpdateThread',
      err,
    });
    throw err;
  }
};

const createFormResponse = async (threadId, contentId, response) => {
  try {
    const content = await fetchThreadContent(contentId);
    if (!content) {
      throw new Error('Form content not found');
    }

    const form_responses = [...(content.form_responses || []), response];

    const update = {
      id: contentId,
      form_responses,
      updated: dateToTimestamp(),
    };

    const {data} = await client.graphql({
      query: updateThreadContent,
      variables: {input: update},
      authMode: 'apiKey',
    });

    return data.updateThreadContent;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'createFormResponse',
      err,
    });
    throw err;
  }
};

const updateFormContent = async (contentId, formItems) => {
  try {
    const update = {
      id: contentId,
      form_items: formItems,
      updated: dateToTimestamp(),
    };

    const {data} = await client.graphql({
      query: updateThreadContent,
      variables: {input: update},
      authMode: 'apiKey',
    });

    return data.updateThreadContent;
  } catch (err) {
    track('api_error', {
      type: 'graphql',
      function: 'updateFormContent',
      err,
    });
    throw err;
  }
};
export {
  chainListener,
  createFormResponse,
  deleteOpp,
  deleteThreadTemplate,
  deleteView,
  fetchAllThreadSubs,
  fetchChain,
  fetchDeck,
  fetchGrp,
  fetchInvite,
  fetchInvites,
  fetchOpp,
  fetchOpps,
  fetchOrg,
  fetchPageView,
  fetchPageViews,
  fetchPost,
  fetchPosts,
  fetchProfile,
  fetchProfiles,
  fetchReferral,
  fetchReferrals,
  fetchTemplates,
  fetchThread,
  fetchThreadContent,
  fetchThreadContents,
  fetchThreadReq,
  fetchThreadRequests,
  fetchThreads,
  fetchThreadSub,
  fetchThreadSubs,
  fetchThreadSubsByUser,
  fetchUnsortedThreadContents,
  listDeckByUser,
  listGrps,
  listOrgs,
  listSortedChains,
  listSortedInteractions,
  listSortedMessages,
  listSortedOpps,
  listSortedPosts,
  messageListener,
  removeOrg,
  removeProfile,
  removeThreadContent,
  removeThreadSub,
  setChain,
  setDeck,
  setEvent,
  setGroup,
  setInteraction,
  setInvite,
  setMessage,
  setOpp,
  setOrg,
  setPageView,
  setPost,
  setProfile,
  setReferral,
  setThread,
  setThreadContent,
  setThreadRequest,
  setThreadSub,
  setThreadTemplate,
  setWaitlist,
  threadContentListener,
  threadContentUpdatedListener,
  threadCreatedListener,
  threadRequestListener,
  threadUpdatedListener,
  updateChain,
  updateDeck,
  updateFormContent,
  updateGrp,
  updateInvite,
  updateMsg,
  updateOpp,
  updateOrg,
  updatePosts,
  updateRef,
  updateThreadChain,
  updateThreadContentMessage,
  updateThreadReq,
  updateThreadSub,
  updateThreadTemplate,
  updateUserProfile,
  updateUserProfileListener,
  updateView,
};
