import React from 'react';
import {RiArchiveLine, RiFlowChart, RiTeamLine} from 'react-icons/ri';
import './PublicMindDifferentiators.css';

const differentiators = [
  {
    icon: <RiTeamLine />,
    title: 'Works Like Your Team Does',
    description:
      'No more switching between systems or getting blocked by IT restrictions. PublicMind connects seamlessly across Microsoft, Google, and other platforms, making collaboration natural for everyone—just like working with colleagues down the hall.',
    comparison: 'Beyond Basic Collaboration',
    stats: {
      value: 'Zero',
      label: 'Communication Barriers',
      detail: 'Everyone can participate, regardless of their system',
    },
  },
  {
    icon: <RiArchiveLine />,
    title: "Preserves Your People's Wisdom",
    description:
      "Stop losing invaluable knowledge when team members retire or move on. PublicMind automatically captures the context behind decisions, preserving the 'why' behind every choice and helping new team members get up to speed quickly.",
    comparison: 'Beyond Document Storage',
    stats: {
      value: '100%',
      label: 'Knowledge Retention',
      detail: 'Every insight stays with your organization',
    },
  },
  {
    icon: <RiFlowChart />,
    title: 'Grows Smarter With You',
    description:
      'Unlike static tools that just store information, PublicMind learns from every project, helping your whole organization work smarter. Start new initiatives with proven approaches, avoid past pitfalls, and build on each success.',
    comparison: 'Beyond Project Management',
    stats: {
      value: '3x',
      label: 'Faster Project Starts',
      detail: 'Learn from every past success',
    },
  },
];

const PublicMindDifferentiators = () => {
  return (
    <section className="differentiators-section">
      <div className="differentiators-header">
        <div className="section-badge">Why PublicMind is Different</div>

        <h2 className="differentiators-title">
          Not Just Another Tool — A
          <span className="title-emphasis"> Complete Solution</span>
        </h2>
        <p className="differentiators-subtitle">
          PublicMind understands how public sector teams actually work. We've
          built a solution that adapts to your processes, not the other way
          around.
        </p>
      </div>

      <div className="differentiators-grid">
        {differentiators.map((item, index) => (
          <div key={index} className="differentiator-card">
            <div className="card-content">
              <div className="icon-wrapper">
                {item.icon}
                <div className="icon-glow"></div>
              </div>

              <div className="card-header">
                <h3>{item.title}</h3>
                <div className="comparison-tag">{item.comparison}</div>
              </div>

              <p className="card-description">{item.description}</p>
              <br />
              <br />
              <br />

              <div className="card-stats">
                <div className="stats-value">{item.stats.value}</div>
                <div className="stats-label">{item.stats.label}</div>
              </div>
            </div>

            {/* <div className="card-footer">
              <button className="learn-more-button">
                See How It Works
                <RiArrowRightLine />
              </button>
            </div> */}
          </div>
        ))}
      </div>
    </section>
  );
};

export default PublicMindDifferentiators;
