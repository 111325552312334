import {BlockNoteView} from '@blocknote/mantine';
import {useCreateBlockNote} from '@blocknote/react';
import React, {useEffect, useState} from 'react';
import {RxChevronDown, RxChevronUp} from 'react-icons/rx';
import {dateToTimestamp} from '../../../api/dates';
import {setupBlocks} from '../../../components/blocks/divider';
import {blockInputTheme, schema} from '../../../components/inputs/block-input';
import MagicMarkdown from '../../../components/markdown/magic-markdown';
import {useAuth} from '../../../hooks/use-auth';
import {useThreads} from '../../../hooks/use-threads';

const BLOCK_LIMIT = 5;
const COLLAPSED_HEIGHT = '200px';

// Video Preview Components
const LoomPreview = ({videoId}) => (
  <div className="embed-preview loom-preview">
    <iframe
      title={`Loom ${videoId}`}
      src={`https://www.loom.com/embed/${videoId}`}
      allowFullScreen
    />
  </div>
);

const YouTubePreview = ({videoId}) => (
  <div className="embed-preview youtube-preview">
    <iframe
      title={`YouTube ${videoId}`}
      src={`https://www.youtube.com/embed/${videoId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </div>
);

export const ContentDisplay = ({item, uneditable}) => {
  const {version, content} = item || {};

  return (
    <>
      {version === 'block' ? (
        <BlockContentDisplay item={item} uneditable={uneditable} />
      ) : (
        <MagicMarkdown content={content} />
      )}
    </>
  );
};

const BlockContentDisplay = ({item, uneditable}) => {
  const {id, content, owner_id, thread_id} = item;
  const {
    state: {id: user_id},
  } = useAuth();
  const {
    state: {threads, thread_content},
    updateContent,
  } = useThreads();
  const [expanded, setExpanded] = useState(false);
  const [shouldCollapse, setShouldCollapse] = useState(false);
  const [videoElements, setVideoElements] = useState([]);
  const cur_permission = threads?.[thread_id]?.permissions?.find(
    p => p.user_id === user_id,
  )?.role;
  const offical_edit =
    owner_id === 'assistant' &&
    (cur_permission === 'owner' || cur_permission === 'admin');
  const getCurrentTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark' ? blockInputTheme.dark : blockInputTheme.light;
  };

  const parseInitialContent = () => {
    try {
      if (!content) {
        return [{type: 'paragraph', content: ''}];
      }

      let parsedContent = JSON.parse(content);

      if (!Array.isArray(parsedContent) || parsedContent.length === 0) {
        return [{type: 'paragraph', content: ''}];
      }

      return parsedContent.map(block => ({
        type: block.type || 'paragraph',
        content: block.content || '',
        ...block,
      }));
    } catch (parseError) {
      console.error('Failed to parse content:', parseError);
      return [{type: 'paragraph', content: ''}];
    }
  };
  const editor = useCreateBlockNote(
    setupBlocks(
      {
        initialContent: parseInitialContent(),
        schema: schema,
        trailingBlock: false,
      },
      threads,
      thread_content,
    ),
  );
  // Extract video URLs from block content
  useEffect(() => {
    const extractVideoUrls = blocks => {
      const elements = [];
      const processContent = content => {
        if (typeof content !== 'string') return;
        // Check for Loom videos
        const loomMatch = content.match(
          /loom\.com\/(share|embed)\/([a-zA-Z0-9]+)/,
        );
        if (loomMatch) {
          elements.push({
            type: 'loom',
            id: loomMatch[2],
          });
          return;
        }

        // Check for YouTube videos
        const youtubeMatch = content.match(
          /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/,
        );
        if (youtubeMatch) {
          elements.push({
            type: 'youtube',
            id: youtubeMatch[1],
          });
          return;
        }
      };

      blocks.forEach(block => {
        if (block.content) {
          block.content.forEach(item => {
            if (item.type === 'link') {
              processContent(item.href);
            }
          });
        }
      });

      return elements;
    };

    const parsedContent = parseInitialContent();
    const videos = extractVideoUrls(parsedContent);
    setShouldCollapse(videos.length > 0);
    setVideoElements(videos);
  }, [content]);

  useEffect(() => {
    let sum = 0;
    editor.forEachBlock(block => (sum += 1));
    setShouldCollapse(sum > BLOCK_LIMIT || videoElements.length > 0);
  }, [editor.document, videoElements]);

  useEffect(() => {
    editor.replaceBlocks(editor.document, parseInitialContent());
  }, [content, editor]);

  useEffect(() => {
    const handleStorageChange = () => {
      editor.focus();
      editor.blur();
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [editor]);

  return (
    <div className="block-content-container">
      <div
        style={{
          position: 'relative',
          maxHeight: shouldCollapse && !expanded ? COLLAPSED_HEIGHT : 'none',
          transition: 'max-height 0.3s ease-out',
          overflow: shouldCollapse && !expanded ? 'hidden' : '',
        }}>
        <BlockNoteView
          editor={editor}
          editable={!uneditable && (owner_id === user_id || offical_edit)}
          theme={getCurrentTheme()}
          onBlur={async () => {
            const stringy = JSON.stringify(editor.document);
            const content_html = await editor.blocksToHTMLLossy();
            const mentions = [];

            editor.forEachBlock(block => {
              const {content} = block;
              if (!Array.isArray(content)) return;
              content.forEach(item => {
                const {type, props} = item;
                if (type === 'mention' && !mentions?.includes(props.email))
                  mentions.push(props.email);
              });
            });

            updateContent({
              id,
              content: stringy,
              content_html,
              mentions,
              updated: dateToTimestamp(),
            });
          }}
        />

        {videoElements.length > 0 && (
          // Change this in your component:
          <div className="video-previews-container">
            {videoElements.map((video, index) => (
              <React.Fragment key={index}>
                {video.type === 'loom' && <LoomPreview videoId={video.id} />}
                {video.type === 'youtube' && (
                  <YouTubePreview videoId={video.id} />
                )}
              </React.Fragment>
            ))}
          </div>
        )}

        {shouldCollapse && !expanded && (
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '50px',
              background: `linear-gradient(
                to bottom,
                transparent,
                var(--background) 90%
              )`,
              pointerEvents: 'none',
              transition: 'opacity 0.3s ease',
            }}
          />
        )}
      </div>

      {shouldCollapse && (
        <button
          onClick={() => setExpanded(!expanded)}
          style={{
            alignSelf: 'flex-start',
            marginTop: '10px',
            marginLeft: '48px',
            background: 'none',
            border: 'none',
            color: 'var(--primary-button)',
            cursor: 'pointer',
            fontSize: '14px',
            padding: '0px',
            borderRadius: '4px',
            transition: 'all 0.3s ease',
          }}>
          {expanded ? (
            <span className="flex-row">
              <RxChevronUp />
              Show less
            </span>
          ) : (
            <span className="flex-row">
              <RxChevronDown />
              Show more
            </span>
          )}
        </button>
      )}
    </div>
  );
};

export default React.memo(ContentDisplay);
