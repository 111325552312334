import React, {useEffect, useState} from 'react';
import {dateToTimestamp} from '../../api/dates';
import html from '../../assets/html/threads/default-thread-created';
import {useAuth} from '../../hooks/use-auth';

const EmailPreview = () => {
  const {
    state: {profile, organizations, current_organization},
  } = useAuth();
  const [emailHtml, setEmailHtml] = useState('');

  useEffect(() => {
    const generateHtml = props => {
      const data = {
        thread: {title: 'Testing title', content: 'Testing Content'},
        content: {
          content: 'testing content',
          scheduled: dateToTimestamp(),
          thread_id: '1',
        },
        profile,
        link: 'https://platform.publicmind.ai/messages/detail/message_789',
        note: 'note to the user',
      };
      const htmlContent = html(data);
      setEmailHtml(htmlContent);
    };

    generateHtml();
  }, []);

  return (
    <div>
      <div dangerouslySetInnerHTML={{__html: emailHtml}}></div>
    </div>
  );
};

export default EmailPreview;
