import JSZip from 'jszip';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {BsActivity} from 'react-icons/bs';
import {
  RiAddLine,
  RiCheckLine,
  RiCloseLine,
  RiEditLine,
  RiFileTextLine,
  RiLayoutGridLine,
  RiLinkM,
  RiUploadLine,
} from 'react-icons/ri';
import {RxArrowUp, RxCheck, RxFile, RxInfoCircled} from 'react-icons/rx';
import {isFileValidForClaude} from '../../../../api/ai';
import constants from '../../../../components/constants';
import {TooltipNoShadow} from '../../../../components/tooltip/tooltip';
import {useAuth} from '../../../../hooks/use-auth';
import {useThreads} from '../../../../hooks/use-threads';
import {TEMPLATE_ICONS, TEMPLATE_OPTIONS} from '../assistant-modal';
import './ChatControlPanel.css';
import './MiniWorkspaceGuide.css';

export const ChatControlPanel = ({
  onSendMessage,
  currentConfig,
  onThreadSelect,
  setFiles,
  showWizard,
  onLaunchWizard,
  onTemplateSelect,
  currentTemplate,
  isStreaming,
  isLoading,
  thread_data,
}) => {
  const {files, selectedThreads, generated, tokens} = currentConfig || {};
  const {thread} = thread_data || {};
  const [message, setMessage] = useState('');
  const [showThreadSelector, setShowThreadSelector] = useState(false);
  const [showFileUploader, setShowFileUploader] = useState(false);
  const [showTemplateSelector, setShowTemplateSelector] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const showAnySelector =
    showThreadSelector || showFileUploader || showTemplateSelector;

  const [showAttachments, setShowAttachments] = useState(false);
  const attachmentsRef = useRef(null);
  useEffect(() => {
    const totalItems = (selectedThreads?.length || 0) + (files?.length || 0);
    if (totalItems === 0) setShowAttachments(false);
  }, [selectedThreads, files]);
  const renderAttachmentsPreview = () => {
    const totalItems = (selectedThreads?.length || 0) + (files?.length || 0);
    if (totalItems === 0) return null;

    const previewItems = [...(selectedThreads || []), ...(files || [])].slice(
      0,
      3,
    );
    const invalidFile = files.some(x => {
      const {isValid, message} = isFileValidForClaude(x);
      return !isValid;
    });

    return (
      <div
        className="chat-panel-attachments-preview"
        onClick={e => {
          setShowAttachments(!showAttachments);
        }}>
        <div className="chat-panel-attachments-count">
          {totalItems} item{totalItems !== 1 ? 's' : ''} attached
        </div>
        {previewItems.map((item, index) => (
          <span
            key={item.id || item.name}
            className="chat-panel-attachment-icon">
            {item.status ? (
              item.status === 'draft' ? (
                <RxFile />
              ) : item.status === 'active' ? (
                <BsActivity />
              ) : (
                <RxCheck />
              )
            ) : (
              <RiFileTextLine />
            )}
          </span>
        ))}
        {totalItems > 3 && (
          <span className="chat-panel-attachments-toggle">
            +{totalItems - 3} more
          </span>
        )}
        {invalidFile && (
          <span className="chat-panel-attachments-count text-red">
            ! Invalid file
          </span>
        )}
      </div>
    );
  };

  const renderTemplateOrWizard = () => {
    if (currentTemplate) {
      return (
        <button
          type="button"
          className="chat-panel-template"
          onClick={() => setShowTemplateSelector(!showTemplateSelector)}>
          <div className="chat-panel-template-icon">
            {TEMPLATE_ICONS[currentTemplate?.value] || <RiFileTextLine />}
          </div>
          <div className="chat-panel-template-content">
            <span className="chat-panel-template-label">
              {currentTemplate?.label}
            </span>
            <span className="chat-panel-template-type">Template</span>
          </div>
        </button>
      );
    }
    // else if (showWizard) {
    //   // If no template is selected but wizard is available, show the wizard button
    //   return (
    //     <button
    //       type="button"
    //       onClick={onLaunchWizard}
    //       className="chat-panel-wizard">
    //       Setup Wizard
    //     </button>
    //   );
    // }
    return null;
  };
  const renderCurrentThread = () => {
    if (thread) {
      return (
        <TooltipNoShadow
          text={'Loaded Thread. Restart document generator to remove'}>
          <button type="button" className="chat-panel-template">
            <div className="chat-panel-template-icon">
              {thread?.logo || <RiLinkM size={24} />}
            </div>
            <div className="chat-panel-template-content">
              <span className="chat-panel-template-label">{thread?.title}</span>
              <span className="chat-panel-template-type">Thread</span>
            </div>
          </button>
        </TooltipNoShadow>
      );
    }

    return null;
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (message.trim()) {
      onSendMessage(message);
      setMessage('');
    }
  };

  // Updated to handle full thread array
  const handleRemoveThread = (e, threadId) => {
    e.stopPropagation();
    onThreadSelect(selectedThreads.filter(t => t.id !== threadId));
  };

  // Updated to handle full files array
  const handleRemoveFile = (e, fileToRemove) => {
    e.stopPropagation();
    setFiles(files.filter(f => f.name !== fileToRemove.name));
  };

  // Modified to handle thread selection properly
  const handleThreadSelect = updatedThreads => {
    onThreadSelect(updatedThreads);
    setShowThreadSelector(false);
  };

  // Modified to handle file selection properly
  const handleFileSelect = newFiles => {
    const existingNames = new Set(files.map(f => f.name));
    const uniqueNewFiles = newFiles.filter(f => !existingNames.has(f.name));
    setFiles([...files, ...uniqueNewFiles]);
    setShowFileUploader(false);
  };
  const contextInfo = useMemo(() => {
    const WARNING_THRESHOLD = 100;
    const MAX_TOKENS = constants.max_tokens;

    // Calculate percentage of token usage
    const tokenPercentage = (tokens / MAX_TOKENS) * 100;

    return {
      threadCount: selectedThreads.length,
      fileCount: files.length,
      tokens: tokens,
      maxTokens: MAX_TOKENS,
      tokenPercentage: tokenPercentage,
      hasContext: selectedThreads.length > 0 || files.length > 0,
      isNearLimit: tokenPercentage > WARNING_THRESHOLD,
      formattedTokens: `${Math.round(tokens).toLocaleString()} / ${Math.round(
        MAX_TOKENS,
      ).toLocaleString()}`,
    };
  }, [selectedThreads, files, tokens]);
  return (
    <div
      className={`chat-panel ${isStreaming || isLoading ? 'isStreaming' : ''}`}>
      {renderAttachmentsPreview()}
      {showAttachments && (
        <div
          ref={attachmentsRef}
          className={`chat-panel-attachments ${
            showAttachments ? 'expanded' : ''
          }`}>
          <div className="chat-panel-attachments-grid">
            {selectedThreads.map(thread => (
              <div key={thread.id} className="chat-panel-attachment">
                {thread.status === 'draft' ? (
                  <RxFile className="chat-panel-attachment-icon" />
                ) : thread.status === 'active' ? (
                  <BsActivity className="chat-panel-attachment-icon" />
                ) : (
                  <RxCheck className="chat-panel-attachment-icon" />
                )}
                <span className="chat-panel-attachment-name">
                  {thread.title}
                </span>
                <button
                  className="chat-panel-attachment-remove"
                  onClick={e => handleRemoveThread(e, thread.id)}>
                  <RiCloseLine />
                </button>
              </div>
            ))}
            {files.map(file => {
              const {isValid, message} = isFileValidForClaude(file);
              return (
                <div
                  key={file.name}
                  className={`chat-panel-attachment ${
                    !isValid ? 'invalid' : ''
                  }`}>
                  <RiFileTextLine className="chat-panel-attachment-icon" />
                  <span className="chat-panel-attachment-name">
                    {file.name}
                  </span>
                  {!isValid && (
                    <span className="chat-panel-attachment-error">
                      {message}
                    </span>
                  )}
                  <button
                    className="chat-panel-attachment-remove"
                    onClick={e => handleRemoveFile(e, file)}>
                    <RiCloseLine />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <form
        onSubmit={handleSubmit}
        onKeyDown={e => {
          if (onSendMessage && e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            if (message?.length !== 0 && tokens < constants.max_tokens) {
              onSendMessage(message);
            }
          }
        }}
        className="chat-panel-form">
        <div className="chat-panel-input-container">
          <div>
            <div className="context-size-label">
              <span>
                {Math.min(contextInfo.tokenPercentage, 100).toFixed(0)}% of
                knowledge capacity used
              </span>
              <span className={contextInfo.isNearLimit ? 'near-limit' : ''}>
                <TooltipNoShadow
                  text={
                    'Document knowledge is limited to 25,000 tokens per section.'
                  }>
                  <RxInfoCircled size={12} />
                </TooltipNoShadow>
                {/* {contextInfo.formattedTokens} */}
              </span>
            </div>
            <div className="context-progress">
              <div
                className={`context-progress-fill ${
                  contextInfo.isNearLimit ? 'near-limit' : ''
                }`}
                style={{
                  width: `${Math.min(contextInfo.tokenPercentage, 100)}%`,
                }}
              />
            </div>
            {contextInfo.isNearLimit && (
              <p className="context-warning">
                Over limit. Remove files or threads to continue.
              </p>
            )}
            {tokens > constants.max_tokens * 0.8 &&
              tokens < constants.max_tokens && (
                <p className="text-12 text-orange">
                  You are approaching the limit for this section
                </p>
              )}
          </div>
          <div className="chat-panel-actions">
            <button
              type="button"
              className="chat-panel-action-btn"
              onClick={() => setShowThreadSelector(!showThreadSelector)}>
              <RiLinkM />
              <span>Add threads</span>
            </button>
            <button
              type="button"
              className="chat-panel-action-btn"
              onClick={() => setShowFileUploader(!showFileUploader)}>
              <RiAddLine />
              <span>Add files</span>
            </button>
            <button
              type="button"
              className="chat-panel-action-btn"
              onClick={() => setShowTemplateSelector(!showTemplateSelector)}>
              <RiLayoutGridLine />
              <span>Select template</span>
            </button>
            {/* <button
              type="button"
              className="chat-panel-action-btn"
              onClick={() => setShowGuide(!showGuide)}>
              <RiQuestionLine />
              <span>{showGuide ? 'Hide' : 'Show'} guide</span>
            </button> */}
          </div>
          <span className="chat-text-submit-container">
            <textarea
              value={message}
              onChange={e => setMessage(e.target.value)}
              placeholder={
                generated
                  ? 'Edit the document by clicking in the document or tell me what to change...'
                  : 'Tell me about the document...'
              }
              className="chat-panel-input"
              rows={1}
            />
            {message?.length > 0 && tokens < constants.max_tokens && (
              <button
                type="submit"
                disabled={
                  message?.length === 0 && tokens < constants.max_tokens
                }
                className="chat-panel-submit">
                <RxArrowUp size={20} />
              </button>
            )}
          </span>
          <span className="flex-row">
            <div className="chat-panel-buttons">{renderTemplateOrWizard()}</div>
            <span className="margin-h4" />
            <div className="chat-panel-buttons">{renderCurrentThread()}</div>
          </span>
        </div>
      </form>

      {showThreadSelector && (
        <div className="chat-panel-selector">
          <div className="chat-panel-selector-header">
            <h3>Select Thread</h3>
            <button onClick={() => setShowThreadSelector(false)}>
              <RiCloseLine />
            </button>
          </div>
          <MiniThreadSelector
            selectedThreads={selectedThreads} // Pass current selection
            onThreadSelect={handleThreadSelect}
          />
        </div>
      )}

      {showFileUploader && (
        <div className="chat-panel-selector">
          <div className="chat-panel-selector-header">
            <h3>Upload File</h3>
            <button onClick={() => setShowFileUploader(false)}>
              <RiCloseLine />
            </button>
          </div>
          <MiniFileUploader
            files={files} // Pass current files
            onFileSelect={handleFileSelect}
          />
        </div>
      )}

      {showTemplateSelector && (
        <div className="chat-panel-selector">
          <div className="chat-panel-selector-header">
            <h3>Select Template</h3>
            <button onClick={() => setShowTemplateSelector(false)}>
              <RiCloseLine />
            </button>
          </div>
          <MiniTemplateSelector
            selectedTemplate={currentTemplate}
            onTemplateSelect={template => {
              onTemplateSelect(template);
              setShowTemplateSelector(false);
            }}
          />
        </div>
      )}

      {/* {showGuide && (
        <MiniWorkspaceGuide
          selectedThreads={selectedThreads}
          files={files}
          selectedTemplate={currentTemplate}
          showWizard={showWizard}
          onLaunchWizard={onLaunchWizard}
          setShowThreadSelector={setShowThreadSelector}
          setShowFileUploader={setShowFileUploader}
          setShowTemplateSelector={setShowTemplateSelector}
          isProjectDescription={message?.length > 0}
          showAnySelector={showAnySelector}
        />
      )} */}
    </div>
  );
};

export default ChatControlPanel;

const MiniThreadSelector = ({selectedThreads = [], onThreadSelect}) => {
  const {
    state: {id},
  } = useAuth();
  const {
    state: {threads},
  } = useThreads();
  const [searchQuery, setSearchQuery] = useState('');

  const filteredThreads = Object.values(threads)
    .filter(
      thread =>
        thread?.permissions?.find(x => x.user_id === id) &&
        (!searchQuery ||
          thread.title.toLowerCase().includes(searchQuery.toLowerCase())),
    )
    .sort((a, b) => b.updated - a.updated);

  const canAddMore = selectedThreads.length < MAX_ITEMS;

  const handleThreadSelect = thread => {
    const isAlreadySelected = selectedThreads.some(t => t.id === thread.id);

    if (isAlreadySelected) {
      const updatedThreads = selectedThreads.filter(t => t.id !== thread.id);
      onThreadSelect(updatedThreads);
    } else if (canAddMore) {
      const updatedThreads = [...selectedThreads, thread];
      onThreadSelect(updatedThreads);
    }
  };

  return (
    <div className="mini-selector">
      <div className="mini-selector-header">
        <div className="mini-selector-count">
          {selectedThreads.length}/{MAX_ITEMS} threads selected
        </div>
        <input
          type="search"
          placeholder="Search threads..."
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          className="mini-selector-search"
        />
      </div>

      <div className="mini-selector-list">
        {filteredThreads.map(thread => {
          const isSelected = selectedThreads.some(t => t.id === thread.id);

          return (
            <button
              key={thread.id}
              className={`mini-selector-item ${isSelected ? 'selected' : ''}`}
              onClick={() => handleThreadSelect(thread)}
              disabled={!canAddMore && !isSelected}>
              {thread.status === 'draft' ? (
                <RxFile />
              ) : thread.status === 'active' ? (
                <BsActivity />
              ) : (
                <RxCheck />
              )}
              {thread.custom_logo && (
                <img
                  src={thread.custom_logo.url}
                  alt=""
                  className="mini-selector-logo"
                />
              )}
              <span className="mini-selector-title" title={thread.title}>
                {thread.title}

                <span className={`mini-selector-status`}>
                  (
                  {thread.status === 'complete'
                    ? 'Only uses final post'
                    : 'Uses thread summary'}
                  )
                </span>
              </span>
              {isSelected && <RiCheckLine className="mini-selector-check" />}
            </button>
          );
        })}
      </div>
    </div>
  );
};

const MAX_ITEMS = 10;
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const MiniFileUploader = ({files = [], onFileSelect}) => {
  const fileInputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [processingFolder, setProcessingFolder] = useState(false);
  const canAddMore = files?.length < MAX_ITEMS;

  const processCompressedFile = async file => {
    const zip = new JSZip();
    try {
      const zipContents = await zip.loadAsync(file);
      const extractedFiles = [];

      for (const [path, zipEntry] of Object.entries(zipContents.files)) {
        // Skip macOS system files and directories
        if (
          zipEntry.dir ||
          path.startsWith('__MACOSX/') ||
          path.startsWith('._') ||
          path.includes('/.')
        ) {
          continue;
        }

        const content = await zipEntry.async('blob');
        const extractedFile = new File([content], path.split('/').pop(), {
          type: content.type || 'application/octet-stream',
        });

        if (extractedFile.size <= MAX_FILE_SIZE) {
          extractedFiles.push(extractedFile);
        }
      }

      return extractedFiles;
    } catch (error) {
      console.error('Error processing compressed file:', error);
      return [];
    }
  };

  const processEntry = async entry => {
    if (entry.isFile) {
      return new Promise(resolve => {
        entry.file(async file => {
          if (file.type.includes('zip') || file.type.includes('compressed')) {
            const extractedFiles = await processCompressedFile(file);
            resolve(extractedFiles);
          } else if (file.size <= MAX_FILE_SIZE) {
            resolve(file);
          } else {
            resolve(null);
          }
        });
      });
    } else if (entry.isDirectory) {
      const dirReader = entry.createReader();
      const entries = await new Promise(resolve => {
        dirReader.readEntries(resolve);
      });

      const files = await Promise.all(
        entries.map(entry => processEntry(entry)),
      );
      return files.flat().filter(Boolean);
    }
    return null;
  };

  const handleFiles = async newFiles => {
    const remainingSlots = MAX_ITEMS - files?.length;
    if (remainingSlots <= 0) return;

    const processedFiles = [];
    for (const file of Array.from(newFiles)) {
      if (file.type.includes('zip') || file.type.includes('compressed')) {
        const extractedFiles = await processCompressedFile(file);
        processedFiles.push(...extractedFiles);
      } else if (file.size <= MAX_FILE_SIZE) {
        processedFiles.push(file);
      }
    }

    // Only take up to the remaining slots
    const validFiles = processedFiles.slice(0, remainingSlots);
    if (validFiles?.length > 0) {
      onFileSelect([...files, ...validFiles]);
    }
  };

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(e.type === 'dragenter' || e.type === 'dragover');
  };

  const handleDrop = async e => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const items = Array.from(e.dataTransfer.items);
    if (items.length > 0) {
      setProcessingFolder(true);
      const entries = items
        .map(item => (item.webkitGetAsEntry ? item.webkitGetAsEntry() : null))
        .filter(Boolean);

      const processedFiles = await Promise.all(
        entries.map(entry => processEntry(entry)),
      );
      const flattenedFiles = processedFiles.flat().filter(Boolean);

      handleFiles(flattenedFiles);
      setProcessingFolder(false);
    } else {
      handleFiles(e.dataTransfer.files);
    }
  };

  return (
    <div className="mini-selector">
      <div className="mini-selector-header">
        <div className="mini-selector-count">
          {files?.length}/{MAX_ITEMS} files selected
        </div>
        <div className="mini-selector-constraints">Max 10MB per file</div>
      </div>
      <div
        className={`mini-selector-dropzone ${dragActive ? 'active' : ''}`}
        onClick={() => canAddMore && fileInputRef.current?.click()}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}>
        <RiUploadLine
          className={`mini-selector-icon ${processingFolder ? 'spinning' : ''}`}
        />
        <div className="mini-selector-dropzone-text">
          <span>
            {processingFolder
              ? 'Processing folder...'
              : canAddMore
              ? 'Choose files or drag & drop'
              : 'Maximum files reached'}
          </span>
        </div>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        multiple
        onChange={e => {
          handleFiles(e.target.files);
          e.target.value = null;
        }}
        className="mini-selector-input"
        style={{display: 'none'}}
      />
    </div>
  );
};

const MiniTemplateSelector = ({selectedTemplate, onTemplateSelect}) => {
  const [activeSection, setActiveSection] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  const sections = [
    {
      id: 'all',
      label: 'All',
      options: Object.values(TEMPLATE_OPTIONS).flat(),
    },
    {
      id: 'summary',
      label: 'Summaries',
      options: TEMPLATE_OPTIONS.SUMMARY,
    },
    {
      id: 'email',
      label: 'Emails',
      options: TEMPLATE_OPTIONS.EMAIL,
    },
    {
      id: 'presentation',
      label: 'Presentations',
      options: TEMPLATE_OPTIONS.PRESENTATION,
    },
    {
      id: 'completed',
      label: 'Project Completion',
      options: TEMPLATE_OPTIONS.COMPLETED_PROJECT,
    },
    {
      id: 'live',
      label: 'Status Reports',
      options: TEMPLATE_OPTIONS.LIVE_PROJECT,
    },
    {
      id: 'draft',
      label: 'Project Planning',
      options: TEMPLATE_OPTIONS.DRAFT_PROJECT,
    },
  ];
  const currentOptions =
    sections.find(s => s.id === activeSection)?.options || [];

  const filteredTemplates = currentOptions.filter(
    template =>
      !searchQuery ||
      template.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
      template.description.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <div className="mini-selector">
      <div className="mini-selector-header">
        <div className="mini-selector-count">
          {selectedTemplate ? '1/1 template selected' : '0/1 template selected'}
        </div>
        <input
          type="search"
          placeholder="Search templates..."
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          className="mini-selector-search"
        />
      </div>

      <div className="template-sections">
        {sections.map(section => (
          <button
            key={section.id}
            className={`template-section-tab ${
              activeSection === section.id ? 'active' : ''
            }`}
            onClick={() => setActiveSection(section.id)}>
            <p className="text-center">{section.label}</p>
          </button>
        ))}
      </div>

      <div className="mini-selector-list">
        {filteredTemplates.map(template => {
          const isSelected = selectedTemplate?.value === template.value;

          return (
            <button
              key={template.value}
              className={`mini-selector-item ${isSelected ? 'selected' : ''}`}
              onClick={() => onTemplateSelect(isSelected ? null : template)}>
              <div className="mini-selector-icon">
                {TEMPLATE_ICONS[template.value]}
              </div>
              <div className="mini-selector-content">
                <span className="mini-selector-title">{template.label}</span>
                <span className="mini-selector-description">
                  {template.description}
                </span>
              </div>
              {isSelected && <RiCheckLine className="mini-selector-check" />}
            </button>
          );
        })}
        {filteredTemplates?.length === 0 && (
          <p className="text-center text-secondary padding-bottom8">
            No matching templates
          </p>
        )}
      </div>
    </div>
  );
};

const MiniWorkspaceGuide = ({
  selectedThreads,
  files,
  selectedTemplate,
  showWizard,
  onLaunchWizard,
  isProjectDescription,
  setShowThreadSelector,
  setShowFileUploader,
  setShowTemplateSelector,
  showAnySelector, // Boolean indicating if any selector is showing
}) => {
  const steps = [
    {
      title: 'Choose Template',
      description: 'Select a template for your document',
      icon: <RiLayoutGridLine size={20} />,
      action: 'Select template',
      completed: selectedTemplate !== null,
      onClick: () => setShowTemplateSelector(true),
    },
    {
      title: 'Add Reference Threads',
      description: 'Connect completed projects for context',
      icon: <RiLinkM size={20} />,
      action: 'Add threads',
      completed: selectedThreads?.length > 0,
      onClick: () => setShowThreadSelector(true),
    },
    {
      title: 'Upload Project Files',
      description: 'Share relevant documents and files',
      icon: <RiUploadLine size={20} />,
      action: 'Add files',
      completed: files?.length > 0,
      onClick: () => setShowFileUploader(true),
    },
    {
      title: 'Describe Your Project',
      description: 'Tell me about what you want to create',
      icon: <RiEditLine size={20} />,
      action: 'Start writing',
      completed: isProjectDescription,
    },
  ];

  return (
    <div
      className={`chat-panel-guide ${showAnySelector ? 'with-selector' : ''}`}>
      <div className="mini-guide">
        <div className="mini-guide-steps">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`mini-guide-step ${step.completed ? 'completed' : ''}`}
              onClick={step.onClick}
              style={{cursor: step.onClick ? 'pointer' : 'default'}}>
              <div className="mini-guide-step-icon">
                {step.completed ? (
                  <RiCheckLine className="check-icon" size={18} />
                ) : (
                  step.icon
                )}
              </div>

              <div className="mini-guide-step-content">
                <h4>{step.title}</h4>
                <p>{step.description}</p>
              </div>

              {/* {showWizard && index === 0 && (
                <button
                  className="mini-guide-wizard-button"
                  onClick={e => {
                    e.stopPropagation();
                    onLaunchWizard();
                  }}>
                  Use Wizard
                </button>
              )} */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
