import JSZip from 'jszip';
import React, {useEffect, useRef, useState} from 'react';
import {
  RiCloseLine,
  RiErrorWarningLine,
  RiFile3Line,
  RiFileTextLine,
  RiFileZipLine,
  RiFolderLine,
  RiImageLine,
  RiMovieLine,
  RiUploadCloud2Line,
} from 'react-icons/ri';
import './AIFileUploader.css';

const MAX_FILES = 5;
const MAX_FILE_SIZE = 5 * 1024 * 1024;

const getFileIcon = fileType => {
  if (!fileType) return null;
  if (fileType.startsWith('image/')) return <RiImageLine size={24} />;
  if (fileType.startsWith('video/')) return <RiMovieLine size={24} />;
  if (fileType.startsWith('text/')) return <RiFileTextLine size={24} />;
  if (fileType.includes('zip') || fileType.includes('compressed'))
    return <RiFileZipLine size={24} />;
  return <RiFile3Line size={24} />;
};

const AIFileUploader = ({files, setFiles, types, limit}) => {
  const [dragActive, setDragActive] = useState(false);
  const [prettyTypes, setPrettyTypes] = useState('');
  const [fileError, setFileError] = useState(null);
  const [processingFolder, setProcessingFolder] = useState(false);
  const inputRef = useRef(null);

  const validateFiles = currentFiles => {
    if (!Array.isArray(currentFiles)) return null;

    if (currentFiles.length > MAX_FILES) {
      return `Please remove ${currentFiles.length - MAX_FILES} file${
        currentFiles.length - MAX_FILES > 1 ? 's' : ''
      }. Maximum allowed is ${MAX_FILES}.`;
    }

    const oversizedFiles = currentFiles.filter(file => file.size > limit);
    if (oversizedFiles.length > 0) {
      return `${oversizedFiles.length} file${
        oversizedFiles.length > 1 ? 's exceed' : ' exceeds'
      } the ${(limit / (1024 * 1024)).toFixed(0)}MB limit.`;
    }

    return null;
  };

  const processCompressedFile = async file => {
    const zip = new JSZip();
    try {
      const zipContents = await zip.loadAsync(file);
      const extractedFiles = [];

      for (const [path, zipEntry] of Object.entries(zipContents.files)) {
        // Skip macOS system files and directories
        if (
          zipEntry.dir ||
          path.startsWith('__MACOSX/') ||
          path.startsWith('._') ||
          path.includes('/.')
        ) {
          continue;
        }

        const content = await zipEntry.async('blob');
        const extractedFile = new File([content], path.split('/').pop(), {
          type: content.type || 'application/octet-stream',
        });

        if (!types || types.includes(extractedFile.type)) {
          extractedFiles.push(extractedFile);
        }
      }

      return extractedFiles;
    } catch (error) {
      console.error('Error processing compressed file:', error);
      setFileError('Failed to process compressed file');
      return [];
    }
  };

  const processEntry = async entry => {
    if (entry.isFile) {
      return new Promise(resolve => {
        entry.file(async file => {
          if (file.type.includes('zip') || file.type.includes('compressed')) {
            const extractedFiles = await processCompressedFile(file);
            resolve(extractedFiles);
          } else if (!types || types.includes(file.type)) {
            resolve(file);
          } else {
            resolve(null);
          }
        });
      });
    } else if (entry.isDirectory) {
      const dirReader = entry.createReader();
      const entries = await new Promise(resolve => {
        dirReader.readEntries(resolve);
      });

      const files = await Promise.all(
        entries.map(entry => processEntry(entry)),
      );
      return files.flat().filter(Boolean);
    }
    return null;
  };

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = async e => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const items = Array.from(e.dataTransfer.items);
    if (items.length > 0) {
      setProcessingFolder(true);
      const entries = items
        .map(item => (item.webkitGetAsEntry ? item.webkitGetAsEntry() : null))
        .filter(Boolean);

      const processedFiles = await Promise.all(
        entries.map(entry => processEntry(entry)),
      );
      // Just use the flattened files directly
      const flattenedFiles = processedFiles.flat().filter(Boolean);

      // Combine with existing files if any
      const updatedFiles = [
        ...(Array.isArray(files) ? files : []),
        ...flattenedFiles,
      ];

      const error = validateFiles(updatedFiles);
      setFileError(error);

      setFiles(updatedFiles);
      setProcessingFolder(false);
    }
  };

  const handleChange = e => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(Array.from(e.target.files));
    }
  };

  const handleFiles = async newFiles => {
    const processedFiles = [];

    for (const file of newFiles) {
      if (file.type.includes('zip') || file.type.includes('compressed')) {
        const extractedFiles = await processCompressedFile(file);
        processedFiles.push(...extractedFiles);
      } else if (!types || types.includes(file.type)) {
        processedFiles.push(file);
      }
    }

    const updatedFiles = [
      ...(Array.isArray(files) ? files : []),
      ...processedFiles,
    ];
    const error = validateFiles(updatedFiles);
    setFileError(error);

    setFiles(updatedFiles);
  };

  const removeFile = index => {
    let updatedFiles = [];
    if (Array.isArray(files)) {
      updatedFiles = files.filter((_, i) => i !== index);
      const error = validateFiles(updatedFiles);
      setFileError(error);
    }

    setFiles(updatedFiles);
  };

  const getFileSize = size => {
    const units = ['B', 'KB', 'MB', 'GB'];
    let value = size;
    let unitIndex = 0;
    while (value > 1024 && unitIndex < units.length - 1) {
      value /= 1024;
      unitIndex++;
    }
    return `${value.toFixed(1)} ${units[unitIndex]}`;
  };

  useEffect(() => {
    if (types?.length) {
      const pretty = types.map(type => type.split('/')[1]).join(', ');
      setPrettyTypes(pretty);
    }
  }, [types]);

  return (
    <div className="ai-file-uploader">
      <div
        className={`ai-file-dropzone ${dragActive ? 'active' : ''} ${
          fileError ? 'error' : ''
        }`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onClick={() => inputRef.current.click()}>
        <input
          ref={inputRef}
          type="file"
          multiple
          onChange={handleChange}
          accept={types ? types.join(',') : undefined}
          style={{display: 'none'}}
        />

        <div className="ai-file-dropzone-content">
          {processingFolder ? (
            <>
              <RiFolderLine className="upload-icon spinning" size={32} />
              <p className="upload-text">Processing folder...</p>
            </>
          ) : (
            <>
              <RiUploadCloud2Line
                className={`upload-icon ${dragActive ? 'floating' : ''}`}
                size={32}
              />
              <p className="upload-text">
                <span>Drag & drop files or folders here</span>
                <span className="upload-subtext">or click to browse</span>
              </p>
            </>
          )}
        </div>

        <div className="ai-file-constraints">
          <span className={`constraint ${fileError ? 'error' : ''}`}>
            Max {MAX_FILES} files
          </span>
          <span className={`constraint ${fileError ? 'error' : ''}`}>
            Max {(MAX_FILE_SIZE / (1024 * 1024)).toFixed(0)}MB per file
          </span>
          {prettyTypes && (
            <span className="constraint">Allowed: {prettyTypes}</span>
          )}
        </div>
      </div>

      {fileError && (
        <div className="ai-file-error">
          <RiErrorWarningLine className="ai-file-error-icon" size={18} />
          <span>{fileError}</span>
        </div>
      )}

      {Array.isArray(files) && files.length > 0 && (
        <div className="ai-file-preview">
          {files.map((file, index) => (
            <div
              key={index}
              className={`ai-file-item ${fileError ? 'error' : ''}`}>
              <div className="ai-file-item-content">
                <div className="ai-file-icon">{getFileIcon(file.type)}</div>
                <div className="ai-file-details">
                  <p className="ai-file-name" title={file.name}>
                    {file.name}
                  </p>
                  <p className="ai-file-size">{getFileSize(file.size)}</p>
                </div>
                <button
                  className="ai-file-remove"
                  onClick={e => {
                    e.stopPropagation();
                    removeFile(index);
                  }}
                  title="Remove file">
                  <RiCloseLine size={18} />
                </button>
              </div>
              {file.type.startsWith('image/') && (
                <div className="ai-file-preview-image">
                  <img
                    src={file.url ? file.url : URL.createObjectURL(file)}
                    alt={file.name}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AIFileUploader;
