import React from 'react';
import './AILoading.css';

const LoadingSpinner = () => (
  <div className="ai-loading-brain">
    {/* <div className="ai-loading-synapse"></div> */}
    {/* <div className="ai-loading-synapse"></div> */}
    {/* <div className="ai-loading-synapse"></div> */}
  </div>
);

const LoadingMessage = ({message}) => (
  <div className="ai-loading-text">{message || 'Processing your request'}</div>
);

const LoadingOverlay = ({message, isStreaming}) => {
  return (
    <div className="ai-assistant-loading">
      <div className="ai-loading-container">
        <LoadingSpinner />
        <LoadingMessage message={isStreaming ? 'Analyzing' : message} />
      </div>
    </div>
  );
};

export default LoadingOverlay;
