import constants from '../components/constants';

const mergeArrays = (array1, array2) => {
  const set1 = new Set(array1);
  const set2 = new Set(array2);

  // Merged array without duplicates
  const merged = [...new Set([...array1, ...array2])];

  // Array of duplicates
  const duplicates = array1.filter(item => set2.has(item));

  // Array of unique items
  const unique = [
    ...array1.filter(item => !set2.has(item)),
    ...array2.filter(item => !set1.has(item)),
  ];

  return {merged, duplicates, unique};
};
// Utility function to check if the browser supports notifications
const checkNotificationSupport = () => {
  return 'Notification' in window; // && 'serviceWorker' in navigator;
};
// Function to request notification permission
const requestNotificationPermission = async () => {
  if (!checkNotificationSupport()) return false;

  const permission = await Notification.requestPermission();
  return permission === 'granted';
};

// Function to register service worker
const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        '/service-worker.js',
      );
      console.log(
        'ServiceWorker registration successful with scope: ',
        registration.scope,
      );
      return registration;
    } catch (err) {
      console.log('ServiceWorker registration failed: ', err);
    }
  }
};

// Main notification function
const sendNotification = async ({title, content, thread_id, id}) => {
  if (!checkNotificationSupport()) {
    console.log('Notifications not supported in this browser');
    return;
  }

  const permission = await checkPermissionStatus();

  if (!permission) {
    console.log('Notification permission not granted');
    return;
  }

  // const registration = await registerServiceWorker();
  // if (!registration) {
  //   console.log('Service worker registration failed');
  //   return;
  // }

  const options = {
    body: content,
    icon: '/favicon.ico',
    badge: '/favicon.ico',
    sound: '/Glass.wav',
    data: {
      url: `${constants.root_url}/threads/${thread_id}/${id}`,
    },
  };

  try {
    // await registration.showNotification(title, options);
    console.log('Notification sent successfully');
  } catch (error) {
    console.error('Error sending notification:', error);
  }
};
const checkPermissionStatus = () => {
  if ('Notification' in window) {
    // Check current permission status without requesting
    const currentPermission = Notification.permission;
    return currentPermission;
  }
  return null;
};

const sanitizeMarkdown = markdownText => {
  if (typeof markdownText !== 'string') {
    return '';
  }

  return (
    markdownText
      // Remove headers
      .replace(/#{1,6}\s?/g, '')
      // Remove emphasis (bold, italic)
      .replace(/(\*\*|__)(.*?)\1/g, '$2')
      .replace(/(\*|_)(.*?)\1/g, '$2')
      // Remove links
      .replace(/\[([^\]]+)\]\(([^\)]+)\)/g, '$1')
      // Remove code blocks and inline code
      .replace(/`{3}[\s\S]*?`{3}/g, '')
      .replace(/`(.+?)`/g, '$1')
      // Remove blockquotes
      .replace(/^\s*>\s*/gm, '')
      // Remove horizontal rules
      .replace(/^(-{3,}|\*{3,})$/gm, '')
      // Remove strikethrough
      .replace(/~~(.*?)~~/g, '$1')
      // Remove images
      .replace(/!\[([^\]]+)\]\(([^\)]+)\)/g, '$1')
      // Remove HTML tags (basic)
      .replace(/<[^>]*>/g, '')
      // Remove @s
      .replace(/@\[([^\]]+)\]\([^)]+\)/g, '$1')
      // Trim whitespace
      .trim()
  );
};

const isValidEmail = email => {
  if (!email) {
    return false;
  }
  // Regular expression pattern for validating email addresses
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

const isValidEmailList = string => {
  // If the input is empty, return false
  if (!string) {
    return false;
  }

  // Split the input string into an array of email addresses
  const emails = string.split(',').map(email => email.trim());

  // Regular expression pattern for validating email addresses
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check each email in the array
  const results = emails.map(email => {
    return {
      email,
      valid: emailPattern.test(email),
    };
  });

  // Return an object with overall validity and individual results
  return {
    valid: results.every(result => result.valid),
    results: results,
  };
};
function convertUrlsToMarkdown(text) {
  if (typeof text !== 'string') {
    return String(text || '');
  }

  const urlRegex =
    /\b(?:https?:\/\/|www\.)(?:[a-z0-9-]+\.)+[a-z]{2,}(?:\/[^\s]*)?(?<![.,;:!?])\b/gi;
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
  const mentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
  const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
  const codeBlockRegex = /(`[^`\n]+`|```[\s\S]+?```)/g;
  const specialPatterns = [
    {regex: mentionRegex, handler: match => match},
    {regex: emailRegex, handler: match => match},
    {regex: markdownLinkRegex, handler: match => match},
    {regex: codeBlockRegex, handler: match => match},
  ];

  // Function to process URLs
  const processUrl = match => {
    let url = match.startsWith('http') ? match : `https://${match}`;
    return `[${match}](${url})`;
  };

  // Function to handle special cases
  const handleSpecialCases = text => {
    return text
      .replace(/\b(e\.g\.|i\.e\.|etc\.|vs\.|fig\.|eq\.)\s/gi, match =>
        match.toLowerCase(),
      )
      .replace(/(?<=\S)\/(?=\S)/g, '\\/')
      .replace(/\[([\w.-]+)\]\(https:\/\/\1\)/g, '$1');
  };

  // Main processing function
  const processText = text => {
    let result = text;
    let placeholders = {};
    let placeholderCounter = 0;

    // Replace special patterns with placeholders
    specialPatterns.forEach(({regex, handler}) => {
      result = result.replace(regex, match => {
        const placeholder = `__PLACEHOLDER_${placeholderCounter++}__`;
        placeholders[placeholder] = handler(match);
        return placeholder;
      });
    });

    // Convert URLs to markdown links
    result = result.replace(urlRegex, match => {
      return match.startsWith('__PLACEHOLDER_') ? match : processUrl(match);
    });

    // Restore placeholders
    Object.entries(placeholders).forEach(([placeholder, value]) => {
      result = result.replace(placeholder, value);
    });

    return handleSpecialCases(result);
  };

  try {
    return processText(text);
  } catch (error) {
    console.error('Error in convertUrlsToMarkdown:', error);
    return text; // Return original text if an error occurs
  }
}

/**
 * Estimates the number of tokens in the input text
 * @param {string|string[]} text - Text to count tokens for
 * @returns {number} Estimated token count
 */
const countTokens = (text, correctionFactor = 1.2) => {
  const patterns = [
    // Common punctuation and special characters
    /[.,!?;:]/g,
    // Words (including contractions)
    /\b\w+'\w+\b|\b\w+\b/g,
    // Whitespace
    /\s+/g,
    // Numbers
    /\d+/g,
    // Common symbols
    /["#$%&'()*+,\-/<=>@\\^_`{|}~\[\]]/g,
  ];

  // Handle array input
  if (Array.isArray(text)) {
    text = text.join(' ');
  }

  // Convert to string if not already
  text = String(text);

  let tokenCount = 0;
  let remainingText = text;

  // Process text using our patterns
  for (const pattern of patterns) {
    const matches = remainingText.match(pattern) || [];
    tokenCount += matches.length;

    // Remove matched portions from remaining text
    remainingText = remainingText.replace(pattern, '');
  }

  // Add correction factor since this is an approximation
  return Math.round(tokenCount * correctionFactor);
};

export {
  checkPermissionStatus,
  convertUrlsToMarkdown,
  countTokens,
  isValidEmail,
  isValidEmailList,
  mergeArrays,
  requestNotificationPermission,
  sanitizeMarkdown,
  sendNotification,
};
