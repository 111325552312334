import React, {useState} from 'react';

import {
  AssistantModal,
  TEMPLATE_ICONS,
  TEMPLATE_OPTIONS,
} from '../modals/assistant-modal';
import './QuickDoc.css';

const CATEGORIES = {
  QUICK_ACCESS: 'Essential Documents',
  EMAIL: 'Emails',
  SUMMARY: 'Quick Summary',
  PRESENTATION: 'Team Briefings',
  COMPLETED_PROJECT: 'Final Report',
  DRAFT_PROJECT: 'Fast Planning',
  LIVE_PROJECT: 'Active Initiatives',
};

// Most commonly used templates across categories
const QUICK_ACCESS_TEMPLATES = [
  TEMPLATE_OPTIONS.EMAIL[0],
  TEMPLATE_OPTIONS.SUMMARY[0],
  // TEMPLATE_OPTIONS.LIVE_PROJECT[1],
  TEMPLATE_OPTIONS.PRESENTATION[1],
];

const DocumentGenerator = () => {
  const [selectedCategory, setSelectedCategory] = useState(
    CATEGORIES.QUICK_ACCESS,
  );
  const [assistantActive, setAssistantActive] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleTemplateSelect = template => {
    setSelectedTemplate(template);
    setAssistantActive(true);
  };

  const getCurrentTemplates = () => {
    switch (selectedCategory) {
      case CATEGORIES.QUICK_ACCESS:
        return QUICK_ACCESS_TEMPLATES;
      case CATEGORIES.EMAIL:
        return TEMPLATE_OPTIONS.EMAIL;
      case CATEGORIES.SUMMARY:
        return TEMPLATE_OPTIONS.SUMMARY;
      case CATEGORIES.PRESENTATION:
        return TEMPLATE_OPTIONS.PRESENTATION;
      case CATEGORIES.COMPLETED_PROJECT:
        return TEMPLATE_OPTIONS.COMPLETED_PROJECT;
      case CATEGORIES.DRAFT_PROJECT:
        return TEMPLATE_OPTIONS.DRAFT_PROJECT;
      case CATEGORIES.LIVE_PROJECT:
        return TEMPLATE_OPTIONS.LIVE_PROJECT;
      default:
        return [];
    }
  };

  return (
    <div className="document-generator">
      {/* <div className="document-generator-header">
        <h2>Generate Professional Documents</h2>
        <p>Select a template to begin crafting your document</p>
      </div> */}

      <div className="category-navigation">
        {Object.values(CATEGORIES).map(category => (
          <button
            key={category}
            className={`category-button ${
              selectedCategory === category ? 'active' : ''
            }`}
            onClick={() => setSelectedCategory(category)}>
            {category}
          </button>
        ))}
      </div>

      <div className="templates-grid">
        {getCurrentTemplates().map(template => {
          const Icon = TEMPLATE_ICONS[template.value];
          return (
            <button
              key={template.value}
              className="template-card"
              onClick={() => handleTemplateSelect(template)}>
              <div className="template-icon">{Icon}</div>
              <div className="template-info">
                <h3>{template.label}</h3>
                <p>{template.description}</p>
              </div>
              <div className="template-arrow">→</div>
            </button>
          );
        })}
      </div>

      {selectedTemplate && (
        <AssistantModal
          active={assistantActive}
          setActive={setAssistantActive}
          selectedTemplate={selectedTemplate}
        />
      )}
    </div>
  );
};

export default DocumentGenerator;
