import React from 'react';
import {isMobile} from 'react-device-detect';
import {
  RiBrainLine,
  RiInboxArchiveLine,
  RiLightbulbLine,
  RiPulseLine,
} from 'react-icons/ri';
import './ProjectTimeline.css';

const timelineSteps = [
  {
    step: 1,
    title: "Save Your Team's Expertise",
    description:
      "Your team's knowledge is too valuable to lose. As your people do their daily work, PublicMind quietly captures their insights and decision-making wisdom. When someone retires or moves on, their expertise stays with you.",
    highlight: 'Never Lose Critical Knowledge',
    icon: <RiInboxArchiveLine />,
    metric: '100% Knowledge Preserved',
    outcome: "Your team's expertise, always available",
  },
  {
    step: 2,
    title: 'Learn From Every Success',
    description:
      "What if every project made your whole organization smarter? PublicMind studies what works best across all your initiatives, helping everyone benefit from each other's experiences. Your collective wisdom grows every day.",
    highlight: 'Shared Learning, Shared Success',
    icon: <RiBrainLine />,
    metric: 'Everyone Learns Together',
    outcome: 'Better decisions, proven approaches',
  },
  {
    step: 3,
    title: 'Start Strong, Move Faster',
    description:
      'Why start from scratch when you can build on what works? When you begin a new project, PublicMind shares relevant insights from past successes. Your team gets a head start using approaches that have already proven effective.',
    highlight: 'Build on What Works',
    icon: <RiLightbulbLine />,
    metric: '3x Faster Project Starts',
    outcome: 'More impact, less wheel-spinning',
  },
  {
    step: 4,
    title: 'Grow Stronger Together',
    description:
      "This is about more than just saving time—it's about helping your people do their best work. Each project strengthens your collective knowledge, making it easier to serve your community effectively and adapt to new challenges.",
    highlight: 'Continuous Improvement',
    icon: <RiPulseLine />,
    metric: '92% More Efficient',
    outcome: 'Excellence becomes natural',
  },
];

const ProjectTimeline = () => {
  return (
    <>
      {!isMobile && (
        <img
          src={require('../../assets/images/generate-image.png')}
          alt="Intelligence at your service"
          style={{
            borderRadius: '8px',
            width: '100%',
            height: '600px',
            maxWidth: '600px',
            border: '1px solid var(--divider)',
            alignSelf: 'center',
          }}
        />
      )}
      <section className="timeline-section">
        <div className="timeline-header">
          <h2 className="timeline-title">
            <span className="timeline-title-highlight">PublicMind-Powered</span>{' '}
            Results
          </h2>
          <p className="timeline-subtitle">
            Transform your organization into a learning system that gets smarter
            with every project
          </p>
        </div>

        <div className="timeline-container">
          {timelineSteps.map((step, index) => (
            <div key={index} className="timeline-step">
              <div className="timeline-step-connector">
                <div className="connector-line"></div>
                <div className="connector-dot"></div>
              </div>

              <div className="timeline-card">
                <div className="timeline-card-header">
                  <div className="step-icon">{step.icon}</div>
                  <span className="step-number">0{step.step}</span>
                  <div className="step-metric">{step.metric}</div>
                </div>

                <h3 className="step-title">{step.title}</h3>
                <span className="step-highlight">{step.highlight}</span>
                <p className="step-description">{step.description}</p>

                <div className="step-indicator"></div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default ProjectTimeline;
