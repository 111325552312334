import React, {useState} from 'react';
import {AiOutlineFile, AiOutlineFileText} from 'react-icons/ai';
import {FaConnectdevelop} from 'react-icons/fa';
import {TbMail, TbMailBolt} from 'react-icons/tb';

import {MdOutlineSummarize} from 'react-icons/md';
import {
  RiBarChartBoxLine,
  RiBookOpenLine,
  RiCheckboxCircleLine,
  RiDashboardLine,
  RiFileTextLine,
  RiLightbulbLine,
  RiNumber1,
  RiNumber3,
  RiNumber5,
  RiNumber8,
} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';
import prompts from '../../../components/ai-prompts';
import {ModalPortalAbsolute} from '../../../components/modal/modal-portal';
import {useAuth} from '../../../hooks/use-auth';
import {useThreads} from '../../../hooks/use-threads';
import {
  useAIAssistantState,
  useContentGeneration,
  useSaveContent,
} from './ai-document-generator/ai-document-state';
import ChatControlPanel from './ai-document-generator/chat-control-panel';
import LoadingOverlay from './ai-document-generator/loading';
import WizardView from './ai-document-generator/set-up-wizard';
import WorkspaceView from './ai-document-generator/workspace';
import './AIAssistance.css';

export const THREAD_TYPES = {
  DRAFT: 'draft',
  LIVE: 'active',
  COMPLETED: 'complete',
};

export const PROMPT_STYLES = {
  // Completed Project Templates
  EXECUTIVE: 'executive',
  CONCISE: 'concise',
  FULL: 'full',
  METRICS: 'metrics',
  LESSONS: 'lessons',
  KNOWLEDGE_PRESERVATION: 'knowledge_preservation',

  // New Project Templates
  QUICK_PLAN: 'quick_plan',
  FULL_PLAN: 'full_plan',
  ACTION_PLAN: 'action_plan',
  RESOURCE_PLAN: 'resource_plan',
  TIMELINE_PLAN: 'timeline_plan',
  PROJECT_VALIDATION: 'project_validation',

  // Live Project Templates
  MILESTONE_TRACK: 'milestone_track',
  PROGRESS_REPORT: 'progress_report',
  STAKEHOLDER_BRIEF: 'stakeholder_brief',
  RISK_MONITOR: 'risk_monitor',

  // Email Templates
  SHORT_EMAIL_SUMMARY: 'short_email_summary',
  LONG_EMAIL_SUMMARY: 'long_email_summary',

  // Summary Templates
  SHORT_SUMMARY: 'short_summary',
  LONG_SUMMARY: 'long_summary',
  PAGE_SUMMARY: 'page_summary',
  BULLET_SUMMARY: 'bullet_summary',

  // Presentation Templates
  SLIDE_PRESENTATION_1: 'slide_presentation_1',
  SLIDE_PRESENTATION_3: 'slide_presentation_3',
  SLIDE_PRESENTATION_5: 'slide_presentation_5',
  SLIDE_PRESENTATION_8: 'slide_presentation_8',

  // Special Purpose Templates
  TEST: 'test',
  CUSTOM: 'custom',
};

export const TEMPLATE_OPTIONS = {
  EMAIL: [
    {
      value: PROMPT_STYLES.SHORT_EMAIL_SUMMARY,
      label: 'Short Summary Email',
      description: 'Concise overview of key points in bullet point format.',
      prompt: prompts.template_mapping.SHORT_EMAIL_SUMMARY,
    },
    {
      value: PROMPT_STYLES.LONG_EMAIL_SUMMARY,
      label: 'Long Summary Email',
      description:
        'Detailed summary in paragraph form with thorough explanation.',
      prompt: prompts.template_mapping.LONG_EMAIL_SUMMARY,
    },
  ],
  SUMMARY: [
    {
      value: PROMPT_STYLES.SHORT_SUMMARY,
      label: 'Short Summary',
      description:
        'High-level overview in bullet point format focused on main takeaways.',
      prompt: prompts.template_mapping.SHORT_SUMMARY,
    },
    {
      value: PROMPT_STYLES.LONG_SUMMARY,
      label: 'Long Summary',
      description:
        'Comprehensive summary in paragraph form with deeper analysis and context.',
      prompt: prompts.template_mapping.LONG_SUMMARY,
    },
    {
      value: PROMPT_STYLES.PAGE_SUMMARY,
      label: '1 Page Summary',
      description:
        'Single page summary document with balanced coverage of critical details.',
      prompt: prompts.template_mapping.PAGE_SUMMARY,
    },
    {
      value: PROMPT_STYLES.BULLET_SUMMARY,
      label: 'Bullet Point Summary',
      description:
        'Summary presented as a structured list, easy to scan and reference key points.',
      prompt: prompts.template_mapping.BULLET_SUMMARY,
    },
  ],
  PRESENTATION: [
    {
      value: PROMPT_STYLES.SLIDE_PRESENTATION_1,
      label: '1 Slide Presentation',
      description:
        'Single slide with core information, maximizes visual impact.',
      prompt: prompts.template_mapping.SLIDE_PRESENTATION_1,
    },
    {
      value: PROMPT_STYLES.SLIDE_PRESENTATION_3,
      label: '3 Slide Presentation',
      description: 'Brief 3-slide deck covering essential points.',
      prompt: prompts.template_mapping.SLIDE_PRESENTATION_3,
    },
    {
      value: PROMPT_STYLES.SLIDE_PRESENTATION_5,
      label: '5 Slide Presentation',
      description:
        'Standard 5-slide presentation format allowing for more detailed coverage.',
      prompt: prompts.template_mapping.SLIDE_PRESENTATION_5,
    },
    {
      value: PROMPT_STYLES.SLIDE_PRESENTATION_8,
      label: '8 Slide Presentation',
      description:
        'Extended 8-slide presentation with comprehensive coverage and examples.',
      prompt: prompts.template_mapping.SLIDE_PRESENTATION_8,
    },
  ],
  COMPLETED_PROJECT: [
    {
      value: PROMPT_STYLES.KNOWLEDGE_PRESERVATION,
      label: 'Institutional Knowledge',
      description:
        'Capture project history, processes, and key decisions to preserve organizational knowledge and ensure continuity.',
      prompt: prompts.template_mapping.KNOWLEDGE_PRESERVATION,
    },
    {
      value: PROMPT_STYLES.CONCISE,
      label: 'Concise Summary',
      description:
        'Quick overview of key achievements, metrics, and learnings for efficient communication.',
      prompt: prompts.template_mapping.CONCISE,
    },
    {
      value: PROMPT_STYLES.EXECUTIVE,
      label: 'Executive Brief',
      description:
        'Strategic overview highlighting outcomes, innovations, value creation, and future opportunities.',
      prompt: prompts.template_mapping.EXECUTIVE,
    },
    {
      value: PROMPT_STYLES.FULL,
      label: 'Implementation Guide',
      description:
        'Comprehensive documentation of project framework, impact, and success patterns for future reference.',
      prompt: prompts.template_mapping.FULL,
    },
    {
      value: PROMPT_STYLES.METRICS,
      label: 'Performance Analysis',
      description:
        'Detailed analysis of impact measures, collaboration metrics, and stakeholder outcomes with benchmarking.',
      prompt: prompts.template_mapping.METRICS,
    },
    {
      value: PROMPT_STYLES.LESSONS,
      label: 'Knowledge Summary',
      description:
        'Analysis of success elements, challenge resolutions, and future improvement opportunities.',
      prompt: prompts.template_mapping.LESSONS,
    },
  ],
  DRAFT_PROJECT: [
    {
      value: PROMPT_STYLES.QUICK_PLAN,
      label: 'Quick Start Guide',
      description:
        'Initial 3-month framework covering scope, timeline, and critical success requirements.',
      prompt: prompts.template_mapping.QUICK_PLAN,
    },
    {
      value: PROMPT_STYLES.PROJECT_VALIDATION,
      label: 'Project Assessment',
      description:
        'Analyze project viability through problem definition, solution validation, and stakeholder mapping.',
      prompt: prompts.template_mapping.PROJECT_VALIDATION,
    },
    {
      value: PROMPT_STYLES.FULL_PLAN,
      label: 'Strategic Plan',
      description:
        'Three-phase project plan covering framework, resources, and implementation strategy.',
      prompt: prompts.template_mapping.FULL_PLAN,
    },
    {
      value: PROMPT_STYLES.ACTION_PLAN,
      label: 'Action Framework',
      description:
        'Prioritized immediate steps, near-term goals, and concrete success measures.',
      prompt: prompts.template_mapping.ACTION_PLAN,
    },
    {
      value: PROMPT_STYLES.RESOURCE_PLAN,
      label: 'Resource Framework',
      description:
        'Structured planning for team, financial, and technical resource allocation.',
      prompt: prompts.template_mapping.RESOURCE_PLAN,
    },
    {
      value: PROMPT_STYLES.TIMELINE_PLAN,
      label: 'Project Timeline',
      description:
        'Phased timeline covering launch, development, and optimization activities.',
      prompt: prompts.template_mapping.TIMELINE_PLAN,
    },
  ],
  LIVE_PROJECT: [
    {
      value: PROMPT_STYLES.MILESTONE_TRACK,
      label: 'Milestone Status',
      description:
        'Current progress, resource status, and risk updates for active projects.',
      prompt: prompts.template_mapping.MILESTONE_TRACK,
    },
    {
      value: PROMPT_STYLES.PROGRESS_REPORT,
      label: 'Status Report',
      description:
        'Comprehensive update on achievements, resources, and forward planning.',
      prompt: prompts.template_mapping.PROGRESS_REPORT,
    },
    {
      value: PROMPT_STYLES.STAKEHOLDER_BRIEF,
      label: 'Stakeholder Update',
      description:
        'Strategic overview of progress, partnerships, and next steps for key stakeholders.',
      prompt: prompts.template_mapping.STAKEHOLDER_BRIEF,
    },
    {
      value: PROMPT_STYLES.RISK_MONITOR,
      label: 'Risk Assessment',
      description:
        'Active monitoring of current risks, resource challenges, and response planning.',
      prompt: prompts.template_mapping.RISK_MONITOR,
    },
  ],
};
export const TEMPLATE_ICONS = {
  // Completed Project Icons
  knowledge_preservation: <FaConnectdevelop size={24} />,
  executive: <RiDashboardLine size={24} />,
  concise: <RiFileTextLine size={24} />,
  full: <RiBookOpenLine size={24} />,
  metrics: <RiBarChartBoxLine size={24} />,
  lessons: <RiLightbulbLine size={24} />,

  // Draft Project Icons
  project_validation: <RiLightbulbLine size={24} />,
  quick_plan: <RiFileTextLine size={24} />,
  full_plan: <RiBookOpenLine size={24} />,
  action_plan: <RiCheckboxCircleLine size={24} />,
  resource_plan: <RiBarChartBoxLine size={24} />,
  timeline_plan: <RiDashboardLine size={24} />,

  // Live Project Icons
  milestone_track: <RiCheckboxCircleLine size={24} />,
  progress_report: <RiBarChartBoxLine size={24} />,
  stakeholder_brief: <RiFileTextLine size={24} />,
  risk_monitor: <RiDashboardLine size={24} />,

  // Email Icons
  short_email_summary: <TbMail size={24} />,
  long_email_summary: <TbMailBolt size={24} />,

  // Summary Icons
  short_summary: <MdOutlineSummarize size={24} />,
  long_summary: <AiOutlineFileText size={24} />,
  page_summary: <AiOutlineFile size={24} />,
  bullet_summary: <MdOutlineSummarize size={24} />,

  // Presentation Icons
  slide_presentation_1: <RiNumber1 size={24} />,
  slide_presentation_3: <RiNumber3 size={24} />,
  slide_presentation_5: <RiNumber5 size={24} />,
  slide_presentation_8: <RiNumber8 size={24} />,
  // Default
  test: <RiFileTextLine size={24} />,
};

const AIAssistant = ({setActive, thread_data, selectedTemplate}) => {
  const navigate = useNavigate();
  const {
    state: {id: user_id, profile},
  } = useAuth();
  const {createThread, createThreadContent, updateThread} = useThreads();
  const [index, setIndex] = useState(thread_data?.toComplete ? 0 : 1);
  const [showWizard, setShowWizard] = useState(true);
  const [currentTemplate, setCurrentTemplate] = useState(
    selectedTemplate ? selectedTemplate : null,
  );
  const {
    editor,
    loading,
    tokens,
    resetSystem,
    setLoading,
    streaming,
    wizardStep,
    setWizardStep,
    setStreaming,
    saving,
    setSaving,
    streamingContent,
    setStreamingContent,
    sectionConfigs,
    activeSection,
    setActiveSection,
    updateSectionConfig,
  } = useAIAssistantState({
    thread_data,
  });

  //IF NO SECTION, duplicate prior config?
  const currentConfig = sectionConfigs[activeSection];
  const {generateInitialDocument, generateChatResponse} = useContentGeneration({
    setLoading,
    setStreaming,
    setStreamingContent,
  });

  const {saveContent, saveContentToExisting} = useSaveContent({
    editor,
    setSaving,
  });

  const handleClose = () => setActive(false);
  const handleReload = () => {
    setCurrentTemplate(null);
    resetSystem();
  };

  const handleSendMessage = async message => {
    setShowWizard(false);
    let newChatHistory = [
      ...currentConfig.chatHistory,
      {
        role: 'user',
        content: [
          {
            type: 'text',
            text: message,
          },
        ],
      },
    ];
    updateSectionConfig('chatHistory', newChatHistory);
    if (!currentConfig?.generated && currentTemplate) {
      updateSectionConfig('generated', true);
      await generateInitialDocument({
        currentTemplate,
        chatHistory: newChatHistory,
        profile,
      });
    } else {
      await generateChatResponse({
        updateSectionConfig,
        currentConfig,
        chatHistory: newChatHistory,
        editor: editor,
        activeSection: activeSection,
      });
    }
  };

  const handleWizardComplete = async () => {
    setIndex(1);
    updateSectionConfig('generated', true);
    let newChatHistory = [
      ...currentConfig.chatHistory,
      {
        role: 'user',
        content: [
          {
            type: 'text',
            text: currentConfig.wizard_content,
          },
        ],
      },
    ];
    await generateInitialDocument({
      currentTemplate,
      chatHistory: currentConfig.chatHistory,
      wizard_content: currentConfig.wizard_content,
      profile,
    });
    updateSectionConfig('chatHistory', newChatHistory);
  };

  const handleSave = async () => {
    await saveContent({
      user_id,
      thread_data,
      createThread,
      createThreadContent,
      updateThread,

      onSuccess: id => {
        navigate(`/threads/${thread_data?.thread?.id || id}`);
        setActive(false);
      },
    });
  };

  const handleSaveToExisting = async thread_id => {
    await saveContentToExisting({
      user_id,
      thread_id,
      createThreadContent,
      updateThread,

      onSuccess: () => {
        navigate(`/threads/${thread_id}`);
        setActive(false);
      },
    });
  };

  return (
    <div className="ai-assistant-modal">
      {index === 0 && (
        <WizardView
          thread_data={thread_data}
          currentStep={wizardStep}
          config={currentConfig}
          currentTemplate={currentTemplate}
          onTemplateSelect={template => setCurrentTemplate(template)}
          onThreadSelect={thread => {
            const updatedThreads = currentConfig.selectedThreads.some(
              t => t.id === thread.id,
            )
              ? currentConfig.selectedThreads.filter(t => t.id !== thread.id)
              : [...currentConfig.selectedThreads, thread];
            updateSectionConfig('selectedThreads', updatedThreads);
          }}
          onFilesChange={files => updateSectionConfig('files', files)}
          onContentChange={content =>
            updateSectionConfig('wizard_content', content)
          }
          onStepComplete={() => {
            if (wizardStep === 2) handleWizardComplete();
            else setWizardStep(prev => prev + 1);
          }}
          onBack={() =>
            setWizardStep(prev => {
              if (prev === 1) {
                setIndex(1);
                return 1;
              }
              return prev - 1;
            })
          }
          onClose={handleClose}
        />
      )}
      {index === 1 && (
        <div className="chat-layout">
          <div className="chat-workspace">
            <WorkspaceView
              editor={editor}
              currentConfig={currentConfig}
              isStreaming={streaming}
              streamingContent={streamingContent}
              thread_data={thread_data}
              onClose={handleClose}
              onSave={handleSave}
              onSaveToExisting={handleSaveToExisting}
              isLoading={loading}
              isSaving={saving}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              handleReload={handleReload}
            />
          </div>
          <ChatControlPanel
            onSendMessage={handleSendMessage}
            currentConfig={currentConfig}
            onThreadSelect={updatedThreads => {
              updateSectionConfig('selectedThreads', updatedThreads);
            }}
            onTemplateSelect={template => setCurrentTemplate(template)}
            setFiles={files => updateSectionConfig('files', files)}
            showWizard={showWizard}
            onLaunchWizard={() => setIndex(0)}
            currentTemplate={currentTemplate}
            isStreaming={streaming}
            isLoading={loading}
            thread_data={thread_data}
          />

          {loading && (
            <LoadingOverlay
              message={
                streamingContent
                  ? 'Reviewing'
                  : !currentConfig?.generated
                  ? 'Creating your document'
                  : 'Updating'
              }
              isStreaming={!!streamingContent}
            />
          )}
        </div>
      )}
    </div>
  );
};

export const AssistantModal = ({
  active,
  setActive,
  thread_data,
  selectedTemplate,
}) => {
  if (!active) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        paddingTop: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}>
      <ModalPortalAbsolute>
        <AIAssistant
          setActive={setActive}
          thread_data={thread_data}
          selectedTemplate={selectedTemplate}
        />
      </ModalPortalAbsolute>
    </div>
  );
};
