import React, {useMemo, useState} from 'react';
import {BsActivity} from 'react-icons/bs';
import {RiFileTextLine, RiInformationLine, RiLinkM} from 'react-icons/ri';
import {RxCheck, RxFile} from 'react-icons/rx';
import constants from '../../../components/constants';
import './SectionIndicator.css';

const SectionIndicator = ({
  currentConfig,
  activeSection,
  totalSections,
  onSectionClick,
}) => {
  const [showContextMenu, setShowContextMenu] = useState(false);

  // Extract configuration with safe defaults
  const {selectedThreads = [], files = [], tokens} = currentConfig;

  // Calculate context information based on tokens
  const contextInfo = useMemo(() => {
    const WARNING_THRESHOLD = 100; // Show warning at 80% usage
    const MAX_TOKENS = constants.max_tokens;

    // Calculate percentage of token usage
    const tokenPercentage = (tokens / MAX_TOKENS) * 100;

    return {
      threadCount: selectedThreads.length,
      fileCount: files.length,
      tokens: tokens,
      maxTokens: MAX_TOKENS,
      tokenPercentage: tokenPercentage,
      hasContext: selectedThreads.length > 0 || files.length > 0,
      isNearLimit: tokenPercentage > WARNING_THRESHOLD,
      formattedTokens: `${Math.round(tokens).toLocaleString()} / ${Math.round(
        MAX_TOKENS,
      ).toLocaleString()}`,
    };
  }, [selectedThreads, files, tokens]);

  return (
    <div className="section-indicator-container">
      <div
        className="section-indicator-wrapper"
        onMouseEnter={() => setShowContextMenu(true)}
        onMouseLeave={() => setShowContextMenu(false)}>
        {/* Context status dot with token-based warning */}
        {contextInfo.hasContext && (
          <div
            className={`context-status-dot ${
              contextInfo.isNearLimit ? 'near-limit' : ''
            }`}
            title={
              contextInfo.isNearLimit
                ? `Near token limit (${contextInfo.formattedTokens})`
                : `Has context (${contextInfo.formattedTokens})`
            }
          />
        )}

        {/* Section number display */}
        <div className="section-indicator-number">
          <span className="section-current">{activeSection + 1}</span>
          <span className="section-total">/{totalSections}</span>
        </div>

        {/* Enhanced context menu */}
        {showContextMenu && (
          <div className="section-context-menu">
            <div className="context-header">
              <RiInformationLine className="context-icon" />
              <div className="context-title">
                <h4>Section {activeSection + 1} Context</h4>
                <p className="context-tip">Modify in workbench below</p>
              </div>
            </div>

            {/* Thread section with token info */}
            <div className="context-section">
              <div className="context-label">
                <RiLinkM className="context-icon-small" />
                <span>Reference Threads ({contextInfo.threadCount})</span>
              </div>
              <div className="context-items">
                {selectedThreads.length > 0 ? (
                  <div className="context-item-grid">
                    {selectedThreads.map(thread => (
                      <div key={thread.id} className="context-item">
                        {thread.status === 'draft' ? (
                          <RxFile className="context-item-icon" />
                        ) : thread.status === 'active' ? (
                          <BsActivity className="context-item-icon" />
                        ) : (
                          <RxCheck className="context-item-icon" />
                        )}
                        <span
                          className="context-item-name"
                          title={thread.title}>
                          {thread.title}
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <span className="context-empty">
                    No reference threads yet
                  </span>
                )}
              </div>
            </div>

            {/* Files section with token info */}
            <div className="context-section">
              <div className="context-label">
                <RiFileTextLine className="context-icon-small" />
                <span>Files ({contextInfo.fileCount})</span>
              </div>
              <div className="context-items">
                {files.length > 0 ? (
                  <div className="context-item-grid">
                    {files.map(file => (
                      <div key={file.name} className="context-item">
                        <RiFileTextLine className="context-item-icon" />
                        <span className="context-item-name" title={file.name}>
                          {file.name}
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <span className="context-empty">No files added yet</span>
                )}
              </div>
            </div>

            {/* Token-based progress bar */}
            {/* {contextInfo.hasContext && ( */}
            <div className="context-size">
              <div className="context-size-label">
                <span>Context Token Usage</span>
                <span className={contextInfo.isNearLimit ? 'near-limit' : ''}>
                  {contextInfo.formattedTokens}
                </span>
              </div>
              <div className="context-progress">
                <div
                  className={`context-progress-fill ${
                    contextInfo.isNearLimit ? 'near-limit' : ''
                  }`}
                  style={{
                    width: `${Math.min(contextInfo.tokenPercentage, 100)}%`,
                  }}
                />
              </div>
              {contextInfo.isNearLimit && (
                <p className="context-warning">
                  Over context limit. Remove files or threads to continue.
                </p>
              )}
            </div>
            {/* )} */}
          </div>
        )}

        {/* Section navigation with context indicators */}
        <div className="section-dots" role="navigation">
          {Array.from({length: totalSections}, (_, index) => {
            const hasContext =
              currentConfig?.sectionConfigs?.[index]?.tokens > 0;

            return (
              <button
                key={index}
                className={`section-dot ${
                  index === activeSection ? 'active' : ''
                } 
                  ${hasContext ? 'has-context' : ''}`}
                onClick={() => onSectionClick(index)}
                aria-label={`Go to section ${index + 1}`}
                aria-current={index === activeSection ? 'true' : 'false'}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SectionIndicator;
