import {useCallback, useEffect, useRef, useState} from 'react';
import {BiPoll} from 'react-icons/bi';
import {MdEventNote, MdLabelImportantOutline} from 'react-icons/md';
import {RiPushpinFill} from 'react-icons/ri';
import {
  RxBookmarkFilled,
  RxCheckCircled,
  RxQuestionMarkCircled,
  RxSewingPinFilled,
} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {
  awsTimestampToDate,
  dateToTimestamp,
  formatChainDate,
  timeSince,
} from '../../../api/dates';
import iconLight from '../../../assets/logos/icon-dark.png';
import {MultilineTextfield} from '../../../components/inputs/textfields';
import {
  FileViewerSimple,
  ProfileImage,
} from '../../../components/inputs/uploader';
import DeletedContent from '../../../components/item-details/deleted-content';
import {TooltipNoShadow} from '../../../components/tooltip/tooltip';
import {useAuth} from '../../../hooks/use-auth';
import useCrypto from '../../../hooks/use-crypto';
import useLoader from '../../../hooks/use-loader';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import '../inputs/BlockNote.css';
import {ContentActions} from '../inputs/interact-content-action-buttons';
import {TaskDisplayGroup} from '../overview-tabs/tasks';
import {ContentDisplay} from './content-display';
import './DecisionPoint.css';
import './FormComment.css';
import './Meeting.css';
import MentionedUsers from './mentions-highlight';
import './Milestone.css';
import './Poll.css';
import './QuestionComment.css';
import './TaskComment.css';
import ThreadChildren from './thread-children';

import {RxChevronDown, RxChevronUp} from 'react-icons/rx';

// DISPLAYS ALL TYPES OF PARENT CONTENT IN THE THREAD DETAIL
const ThreadComment = ({
  item,
  onReply,
  onQuote,
  focused,
  onClick,
  sub,
  setFocusedContent,
  viewOnly = false,
  setFullScreenContent,
  dm,
}) => {
  const {loadProfiles} = useLoader();

  const {
    state: {id, profiles},
  } = useAuth();

  const contentRef = useRef(null);

  const {type, owner_id, created, children} = item;

  const [isTarget, setIsTarget] = useState(false);

  const new_content = owner_id !== id && (!sub || created > sub.last_read);

  useEffect(() => {
    if (!profiles?.[owner_id]) {
      loadProfiles([owner_id], {authMode: 'apiKey'});
    }
  }, [owner_id]);

  const scrollToContent = useCallback(() => {
    setTimeout(() => {
      if (contentRef.current) {
        contentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        setIsTarget(true);
        if (setFocusedContent) {
          setFocusedContent(null);
        }
      }
    }, 100);
  }, [setFocusedContent]);

  useEffect(() => {
    if (focused) {
      scrollToContent();
    }
  }, [focused, scrollToContent]);

  if (Object.keys(item).length === 0) {
    return null; // Return nothing
  }

  if (!item.id) {
    return <DeletedContent type="comment" />;
  }

  const commonProps = {
    item,
    onQuote,
    onReply,
    setFullScreenContent,
    dm,
  };

  // Update the renderContent switch case in ThreadComment
  const renderContent = () => {
    switch (type) {
      case 'comment':
        return <BaseContent {...commonProps} />;
      case 'milestone':
        return (
          <>
            <BaseContent {...commonProps} />
            <MilestoneDetails item={item} />
          </>
        );
      case 'question':
        return (
          <>
            <BaseContent {...commonProps} />
            <QuestionContent {...commonProps} />
          </>
        );
      case 'decision_point':
        return (
          <>
            <BaseContent {...commonProps} />
            <DecisionPointDetails item={item} />
            <MentionedUsers mentions={item.mentions} />
          </>
        );
      case 'poll':
        return (
          <>
            <BaseContent {...commonProps} />
            <PollContent {...commonProps} />
          </>
        );
      case 'form':
        return (
          <>
            <BaseContent {...commonProps} />
            <FormContent {...commonProps} />
            <MentionedUsers mentions={item.mentions} />
          </>
        );
      case 'meeting':
        return (
          <>
            <BaseContent {...commonProps} />
            <MeetingContent {...commonProps} />
          </>
        );
      case 'task':
        return (
          <>
            <BaseContent {...commonProps} />
            <TaskDisplayGroup item={item} />
          </>
        );
      default:
        return <DeletedContent type="comment" />;
    }
  };

  return (
    <div
      className={`content-item ${
        new_content ? 'new' : isTarget ? 'target' : ''
      } ${type}-item`}
      ref={contentRef}
      onClick={onClick}>
      {renderContent()}
      {!viewOnly && (
        <ContentActions
          item={item}
          onQuote={onQuote}
          onReply={onReply}
          setFullScreenContent={setFullScreenContent}
          allowEdit={item?.type === 'decision_point' ? false : true}
          allowReactions={item?.type === 'comment'}
          dm={dm}
        />
      )}
      {!!children?.length && (
        <div className={type === 'comment' ? 'content-children' : ''}>
          <ThreadChildren children={children} />
        </div>
      )}
    </div>
  );
};

export const BaseContent = ({item, dm}) => {
  const {encryptString} = useCrypto();
  const navigate = useNavigate();
  const {prettyName, prettyType} = useStringFormatter();
  const {
    state: {id, profiles, organizations},
  } = useAuth();
  const {updateContent} = useThreads();

  const {
    owner_id,
    media,
    priority,
    reactions,
    content,
    version,
    type,
    subtype,
  } = item;

  const [reactionSum, setReactions] = useState([]);
  const timeSinceString = timeSince(item?.created);

  // const user = profiles?.[owner_id] ?? {};
  const user =
    owner_id === 'assistant'
      ? OfficialPostProfile()
      : profiles?.[owner_id] ?? {};
  const profile_image = user?.profile_image;
  const {first_name, last_name, position, organization_ids} = user;
  const current_org =
    owner_id === 'assistant'
      ? {name: user?.organization}
      : organization_ids?.length
      ? organizations?.[organization_ids[0]]
      : null;

  useEffect(() => {
    const sum = {};
    reactions?.forEach(item => {
      const {reaction, user_id} = item;
      if (sum[reaction] === undefined) {
        sum[reaction] = {count: 0, ids: []};
      }
      sum[reaction].count += 1;
      sum[reaction].ids.push(user_id);
    });
    const mapped = Object.keys(sum).map(key => {
      const values = sum[key];
      return {reaction: key, ...values};
    });
    setReactions(mapped);
  }, [reactions]);

  return (
    <div>
      <div className="flex-row justify-between">
        <div className="content-header">
          <ProfileImage
            data={profile_image}
            style={{
              height: '40px',
              width: '40px',
              ...(owner_id === 'assistant' &&
                {
                  // padding: '4px',
                  // backgroundColor: 'var(--background)',
                  // border: '1px solid var(--primary-button)',
                }),
            }}
            onClick={() => {
              // Only navigate to profile for regular users
              if (owner_id !== 'assistant') {
                const parsed = encryptString(owner_id);
                navigate(`/feed/profiles/detail/${parsed}`);
              }
            }}
          />
          <div className="user-info">
            <span className="flex-column">
              <h3 className="user-name">{`${first_name} ${last_name}`}</h3>
              <div className="flex-row align-center">
                {!dm && position && (
                  <p className="content-org">
                    {position}
                    {current_org?.name ? `, ${current_org?.name}` : null}
                  </p>
                )}
                {!dm && <p className="content-time">{timeSinceString}</p>}
                {dm && (
                  <p className="text-secondary text-10 ">{timeSinceString}</p>
                )}
              </div>
            </span>
          </div>
        </div>
        <span
          className={`flex-column align-center ${
            prettyType(type, subtype) === 'Post' ? 'padding-right54' : ''
          }`}>
          {!dm && (
            <p className="text-secondary text-12">
              {prettyType(type, subtype)}
            </p>
          )}
        </span>
      </div>
      <div className="content-body">
        <ContentDisplay item={item} />
        {media?.length > 0 && (
          <div className="content-files">
            <FileViewerSimple files={media} />
          </div>
        )}
        <div className="content-reactions flex-row-wrap">
          {reactionSum.map(rct => {
            const {reaction, count, ids} = rct;
            const included = ids.includes(id);
            const people = ids
              ?.map(id => prettyName(profiles?.[id]))
              ?.join(', ');
            return (
              <TooltipNoShadow key={reaction} text={people}>
                <div
                  onClick={async () => {
                    if (!included) {
                      return;
                    }
                    const removed = reactions.filter(
                      item => item.user_id !== id,
                    );

                    await updateContent({id: item.id, reactions: removed});
                  }}
                  key={reaction}
                  className={`reaction-bubble ${included ? 'active' : ''}`}>
                  {reaction} {count}
                </div>
              </TooltipNoShadow>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const calculateTimelineProgress = (start, end) => {
  const now = Date.now() / 1000; // Current time in seconds
  const totalDuration = end - start;
  const elapsed = now - start;
  return Math.min(100, Math.max(0, (elapsed / totalDuration) * 100));
};
const MilestoneDetails = ({item}) => {
  const {dates, metrics} = item;
  const [hasContent, setHasContent] = useState(true);
  const [timelineProgress, setTimelineProgress] = useState(0);
  const [remainingDays, setRemainingDays] = useState(null);

  useEffect(() => {
    const areDatesEmpty = dates.every(date => date.date === null);
    const areMetricsEmpty = !metrics || metrics.length === 0;
    setHasContent(!(areDatesEmpty && areMetricsEmpty));

    const startDate = dates.find(d => d.name?.toLowerCase() === 'start')?.date;
    const endDate = dates.find(
      d => d.name?.toLowerCase() === 'due' || d.name?.toLowerCase() === 'end',
    )?.date;

    if (startDate && endDate) {
      const progress = calculateTimelineProgress(startDate, endDate);
      setTimelineProgress(progress);

      const now = dateToTimestamp();
      const remaining = Math.max(
        -1,
        Math.floor((endDate - now) / (24 * 60 * 60)),
      );
      setRemainingDays(remaining);
    }
  }, [dates, metrics]);

  const formatDate = timestamp => {
    if (!timestamp) return 'Not set';
    const date = awsTimestampToDate(timestamp);
    return date.toLocaleDateString('en-US', {month: 'short', day: 'numeric'});
  };
  const calculateProgress = (goal, progress) => {
    const goalValue = parseFloat(goal);
    const progressValue = parseFloat(progress);
    if (!isNaN(goalValue) && !isNaN(progressValue)) {
      if (goalValue > progressValue) {
        // Count up
        return {
          type: 'countUp',
          progress: Math.min(100, (progressValue / goalValue) * 100),
          remaining: goalValue - progressValue,
        };
      } else if (goalValue === progressValue) {
        return {
          type: 'complete',
          progress: 100,
          remaining: 0,
        };
      } else {
        // Count down
        return {
          type: 'countDown',
          progress: Math.max(
            0,
            ((goalValue - progressValue) / goalValue) * 100,
          ),
          remaining: progressValue - goalValue,
        };
      }
    }
    return {type: 'invalid', progress: 0, remaining: 0};
  };
  if (!hasContent) {
    return null;
  }

  return (
    <div className="milestone-details">
      {!dates.every(date => date.date === null) && (
        <div className="milestone-timeline">
          <div
            className="milestone-timeline-progress"
            style={{width: `${timelineProgress}%`}}></div>
          {dates.map((date, index) => {
            if (date.name === 'completed') return null;
            return (
              <div key={index} className="milestone-date">
                <span className="milestone-date-value">
                  {formatDate(date.date)}
                </span>
                <span className="milestone-date-label">{date.name}</span>
                {date.date && (
                  <span className="milestone-date-relative">
                    {timeSince(date.date, {shortFormat: true})}
                  </span>
                )}
              </div>
            );
          })}
          {remainingDays !== null && remainingDays >= 0 && (
            <div className="milestone-remaining-days">
              {remainingDays}d left
            </div>
          )}
        </div>
      )}
      {metrics && metrics.length > 0 && (
        <div className="milestone-metrics">
          {metrics.map((metric, index) => {
            const progressData = calculateProgress(
              metric.goal,
              metric.progress,
            );
            return (
              <div key={index} className="milestone-metric">
                <div className="milestone-metric-header">
                  <span className="milestone-metric-goal">
                    {metric.progress} / {metric.goal}
                  </span>
                  <span className="milestone-metric-unit">{metric.unit}</span>
                </div>
                {progressData.type !== 'invalid' && (
                  <div className="milestone-metric-progress-bar">
                    <div
                      className={`milestone-metric-progress-fill ${progressData.type}`}
                      style={{width: `${progressData.progress}%`}}></div>
                  </div>
                )}
                <span className="milestone-metric-progress-text">
                  {progressData.type === 'invalid'
                    ? ''
                    : progressData.type === 'complete'
                    ? 'Completed!'
                    : progressData.type === 'countDown'
                    ? 'Exceeding by: '
                    : 'Remaining: '}
                  {progressData.type !== 'complete' &&
                  progressData.type !== 'invalid'
                    ? `${progressData.remaining.toFixed(2)} ${metric.unit}`
                    : null}
                </span>
                <p className="milestone-metric-description">
                  {metric.description}
                </p>
              </div>
            );
          })}
        </div>
      )}
      <MentionedUsers mentions={item.mentions} />
    </div>
  );
};

const QuestionContent = ({item}) => {
  const {prettyName} = useStringFormatter();
  const {
    state: {id, profiles},
  } = useAuth();
  const {updateContent} = useThreads();

  const {content, owner_id, responses, expiration} = item;

  const [input, setInput] = useState('');
  const [timeRemaining, setTimeRemaining] = useState('');
  const [showResponses, setShowResponses] = useState(false);
  const expired = expiration ? dateToTimestamp() > expiration : false;

  const hasUserAnswered = responses?.some(response => response.user_id === id);
  const totalResponses = responses?.length || 0;

  useEffect(() => {
    if (expiration) {
      const updateTimeRemaining = () => {
        const now = dateToTimestamp();
        const remainingTime = expiration - now;
        if (remainingTime <= 0) {
          setTimeRemaining('Expired');
        } else {
          const days = Math.floor(remainingTime / (24 * 60 * 60));
          const hours = Math.floor(
            (remainingTime % (24 * 60 * 60)) / (60 * 60),
          );
          const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
          setTimeRemaining(`${days}d ${hours}h ${minutes}m`);
        }
      };

      updateTimeRemaining();
      const timer = setInterval(updateTimeRemaining, 60000);
      return () => clearInterval(timer);
    }
  }, [expiration]);

  const handleSubmitResponse = async () => {
    if (input.trim() && !hasUserAnswered) {
      const newResponses = [
        ...(responses || []),
        {user_id: id, response: input.trim()},
      ];
      await updateContent({id: item.id, responses: newResponses});
      setInput('');
      setShowResponses(true);
    }
  };

  return (
    <div>
      <div className="content-body">
        <div className="question-meta">
          <span className="question-responses-count">
            {totalResponses} answer{totalResponses === 1 ? '' : 's'}
          </span>
          {expiration && (
            <span className="question-expiration">
              Expires in: {timeRemaining}
            </span>
          )}
        </div>

        {responses?.length > 0 && (
          <div className="question-responses-header">
            <button
              onClick={() => setShowResponses(!showResponses)}
              className="question-responses-toggle">
              {showResponses ? (
                <>
                  <RxChevronUp className="question-toggle-icon" />
                  Hide Responses
                </>
              ) : (
                <>
                  <RxChevronDown className="question-toggle-icon" />
                  Show Responses
                </>
              )}
            </button>
          </div>
        )}

        {showResponses && responses?.length > 0 && (
          <div className="question-responses">
            {responses?.map((response, index) => (
              <div key={index} className="question-response">
                <ProfileImage
                  data={profiles[response.user_id]?.profile_image}
                  style={{height: '24px', width: '24px'}}
                />
                <div className="question-response-content">
                  <span className="question-response-user">
                    {prettyName(profiles[response.user_id])}
                  </span>
                  <p>{response.response}</p>
                </div>
              </div>
            ))}
          </div>
        )}

        {!hasUserAnswered && timeRemaining !== 'Expired' && (
          <div className="question-input pretty-form-group">
            <MultilineTextfield
              disabled={expired}
              placeholder="Your answer..."
              value={input}
              onChange={e => setInput(e.target.value)}
            />
            <button
              className="question-submit"
              disabled={!input.trim()}
              onClick={handleSubmitResponse}>
              Submit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const DecisionPointDetails = ({item}) => {
  const {content, dates} = item;
  const decisionDate =
    dates.find(date => date.name === 'completed')?.date || item.created;
  const now = Date.now();
  const isDecisionInFuture = decisionDate > now;

  return (
    <div className="decision-point-details">
      <div className="decision-point-date">
        <span className="decision-point-date-label">
          {isDecisionInFuture ? 'Must decide by:' : 'Decision made:'}
        </span>
        <span className="decision-point-date-value">
          {formatChainDate(awsTimestampToDate(decisionDate))}
        </span>
      </div>
    </div>
  );
};

const FormContent = ({item}) => {
  const {
    state: {id: currentUserId, profiles},
  } = useAuth();
  const {updateContent} = useThreads();
  const {owner_id, form_items = [], form_responses = [], expiration} = item;
  const isOwner = currentUserId === owner_id;

  const [timeRemaining, setTimeRemaining] = useState('');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [viewingResponses, setViewingResponses] = useState(false);
  const [showOwnerForm, setShowOwnerForm] = useState(false);
  const [showUserResponse, setShowUserResponse] = useState(false);

  const expired = expiration ? dateToTimestamp() > expiration : false;
  const userResponse = form_responses?.find(
    response => response.user_id === currentUserId,
  );

  useEffect(() => {
    if (expiration) {
      const updateTimeRemaining = () => {
        const now = dateToTimestamp();
        const remainingTime = expiration - now;
        if (remainingTime <= 0) {
          setTimeRemaining('Expired');
        } else {
          const days = Math.floor(remainingTime / (24 * 60 * 60));
          const hours = Math.floor(
            (remainingTime % (24 * 60 * 60)) / (60 * 60),
          );
          const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
          setTimeRemaining(`${days}d ${hours}h ${minutes}m`);
        }
      };

      updateTimeRemaining();
      const timer = setInterval(updateTimeRemaining, 60000);
      return () => clearInterval(timer);
    }
  }, [expiration]);

  const validateQuestion = (item, value) => {
    if (item.required && !value) {
      return 'This field is required';
    }

    return null;
  };

  const handleNext = () => {
    const currentItem = form_items[currentQuestionIndex];
    const error = validateQuestion(currentItem, formData[currentItem.id]);

    if (error) {
      setFormErrors({...formErrors, [currentItem.id]: error});
      return;
    }

    if (currentQuestionIndex < form_items.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSubmit = async () => {
    const currentItem = form_items[currentQuestionIndex];
    const error = validateQuestion(currentItem, formData[currentItem.id]);

    if (error) {
      setFormErrors({...formErrors, [currentItem.id]: error});
      return;
    }

    const answers = Object.entries(formData).map(([item_id, value]) => ({
      item_id,
      value: Array.isArray(value) ? value.join(',') : String(value),
      values: Array.isArray(value) ? value : undefined,
    }));

    try {
      const newResponse = {
        user_id: currentUserId,
        timestamp: dateToTimestamp(),
        answers: answers,
      };

      const updatedResponses = [...(form_responses || []), newResponse];
      await updateContent({
        id: item.id,
        form_responses: updatedResponses,
      });

      setFormData({});
      setFormErrors({});
    } catch (err) {
      console.error('Error submitting form:', err);
    }
  };

  const renderResponseSummary = () => {
    const totalResponses = form_responses?.length || 0;
    const responsesByQuestion = form_items?.map(question => {
      const answers = form_responses
        .map(response => response.answers.find(a => a.item_id === question.id))
        .filter(Boolean);

      let summary = {};
      if (question.type === 'choice' || question.type === 'multi-choice') {
        summary = answers.reduce((acc, curr) => {
          const values = curr.values || [curr.value];
          values.forEach(value => {
            acc[value] = (acc[value] || 0) + 1;
          });
          return acc;
        }, {});
      }

      return {
        question: question.question,
        totalAnswers: answers.length,
        summary,
        answers: answers,
        type: question.type,
      };
    });

    return (
      <div className="form-response-summary">
        <div className="form-summary-header">
          <h4>Response Summary</h4>
          <span className="form-total-responses">
            {totalResponses} total{' '}
            {totalResponses === 1 ? 'response' : 'responses'}
          </span>
        </div>

        {responsesByQuestion.map((item, index) => (
          <div key={index} className="form-question-summary">
            <h5>
              Q{index + 1}: {item.question}
            </h5>
            <div className="form-answer-stats">
              <div className="form-answer-rate">
                Response rate:{' '}
                {Math.round((item.totalAnswers / totalResponses) * 100)}%
              </div>

              {(item.type === 'choice' || item.type === 'multi-choice') && (
                <div className="form-choice-breakdown">
                  {Object.entries(item.summary).map(([choice, count]) => (
                    <div key={choice} className="form-choice-stat">
                      <div className="form-choice-bar">
                        <div
                          className="form-choice-fill"
                          style={{width: `${(count / totalResponses) * 100}%`}}
                        />
                      </div>
                      <span className="form-choice-label">{choice}</span>
                      <span className="form-choice-count">
                        {count} ({Math.round((count / totalResponses) * 100)}%)
                      </span>
                    </div>
                  ))}
                </div>
              )}

              <div className="form-text-responses">
                {item.answers.map((answer, i) => (
                  <div key={i} className="form-text-response">
                    <span className="form-response-user">
                      {profiles[form_responses[i].user_id]?.first_name}{' '}
                      {profiles[form_responses[i].user_id]?.last_name}:
                    </span>
                    <span>{answer.value}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderUserResponse = () => {
    if (!userResponse) return null;

    const calculateResponseStats = () => {
      const totalQuestions = form_items.length;
      const answeredQuestions = userResponse.answers.length;
      const completionRate = Math.round(
        (answeredQuestions / totalQuestions) * 100,
      );

      return {
        totalQuestions,
        answeredQuestions,
        completionRate,
        responseDate: new Date(userResponse.timestamp * 1000),
      };
    };

    const stats = calculateResponseStats();

    return (
      <div className="user-response-container">
        <div className="user-response-header">
          <h4>Your Response</h4>
          <span className="user-response-date">
            Submitted on{' '}
            {stats.responseDate.toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        </div>

        <div className="user-response-stats">
          <div className="user-stat-card">
            <span className="user-stat-value">{stats.completionRate}%</span>
            <span className="user-stat-label">Completion Rate</span>
          </div>
          <div className="user-stat-card">
            <span className="user-stat-value">{stats.answeredQuestions}</span>
            <span className="user-stat-label">Questions Answered</span>
          </div>
        </div>

        <div className="user-responses-detail">
          {form_items.map((question, index) => {
            const answer = userResponse.answers.find(
              a => a.item_id === question.id,
            );

            return (
              <div key={index} className="user-response-item">
                <div className="user-response-question">
                  <span className="question-number">Q{index + 1}</span>
                  <h5>{question.question}</h5>
                </div>

                {question.type === 'choice' ||
                question.type === 'multi-choice' ? (
                  <div className="user-response-choices">
                    {question.choices.map((choice, choiceIndex) => {
                      const isSelected =
                        answer?.values?.includes(choice) ||
                        answer?.value === choice;

                      return (
                        <div
                          key={choiceIndex}
                          className={`user-choice-item ${
                            isSelected ? 'selected' : ''
                          }`}>
                          <span className="choice-indicator">
                            {isSelected ? '✓' : ''}
                          </span>
                          <span className="choice-text">{choice}</span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="user-response-text">
                    {answer?.value || (
                      <span className="no-answer">No answer provided</span>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderQuestionForm = () => {
    if (!form_items?.length) return <p>This form has no questions.</p>;
    if (expired && !userResponse) {
      return (
        <div className="form-expired-message">
          <p>This form has expired and is no longer accepting responses.</p>
          <p className="form-expired-date">
            Expired on{' '}
            {new Date(expiration * 1000).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </p>
        </div>
      );
    }
    if (userResponse && !showUserResponse) {
      return (
        <div className="form-response-preview">
          <p className="form-response-date">
            Submitted on{' '}
            {new Date(userResponse.timestamp * 1000).toLocaleDateString(
              'en-US',
              {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              },
            )}
          </p>
          <button
            className="form-view-response-button"
            onClick={() => setShowUserResponse(true)}>
            View Your Response
          </button>
        </div>
      );
    }
    if (userResponse && showUserResponse) {
      return renderUserResponse();
    }

    const currentQuestion = form_items[currentQuestionIndex];
    const progress = ((currentQuestionIndex + 1) / form_items.length) * 100;

    return (
      <div className="form-question-view">
        <div className="form-progress">
          <div className="form-progress-bar" style={{width: `${progress}%`}} />
          <span className="form-progress-text">
            Question {currentQuestionIndex + 1} of {form_items.length}
          </span>
        </div>

        <div className="form-question">
          <label className="form-question-label">
            {currentQuestion.question}
            {currentQuestion.required && (
              <span className="form-required">*</span>
            )}
          </label>

          {currentQuestion.type === 'text' && (
            <input
              type="text"
              value={formData[currentQuestion.id] || ''}
              onChange={e => {
                setFormData({
                  ...formData,
                  [currentQuestion.id]: e.target.value,
                });
                setFormErrors({...formErrors, [currentQuestion.id]: null});
              }}
              className={`form-text-input ${
                formErrors[currentQuestion.id] ? 'error' : ''
              }`}
            />
          )}

          {currentQuestion.type === 'choice' && (
            <div className="form-choice-options">
              {currentQuestion.choices?.map((choice, index) => (
                <label key={index} className="form-choice-label">
                  <input
                    type="radio"
                    name={currentQuestion.id}
                    value={choice}
                    checked={formData[currentQuestion.id] === choice}
                    onChange={e => {
                      setFormData({
                        ...formData,
                        [currentQuestion.id]: e.target.value,
                      });
                      setFormErrors({
                        ...formErrors,
                        [currentQuestion.id]: null,
                      });
                    }}
                  />
                  <span>{choice}</span>
                </label>
              ))}
            </div>
          )}

          {currentQuestion.type === 'multi-choice' && (
            <div className="form-choice-options">
              {currentQuestion.choices?.map((choice, index) => (
                <label key={index} className="form-choice-label">
                  <input
                    type="checkbox"
                    value={choice}
                    checked={
                      Array.isArray(formData[currentQuestion.id]) &&
                      formData[currentQuestion.id].includes(choice)
                    }
                    onChange={e => {
                      const currentValues = Array.isArray(
                        formData[currentQuestion.id],
                      )
                        ? formData[currentQuestion.id]
                        : [];
                      const newValues = e.target.checked
                        ? [...currentValues, choice]
                        : currentValues.filter(v => v !== choice);
                      setFormData({
                        ...formData,
                        [currentQuestion.id]: newValues,
                      });
                      setFormErrors({
                        ...formErrors,
                        [currentQuestion.id]: null,
                      });
                    }}
                  />
                  <span>{choice}</span>
                </label>
              ))}
            </div>
          )}

          {formErrors[currentQuestion.id] && (
            <div className="form-error">{formErrors[currentQuestion.id]}</div>
          )}

          <div className="form-navigation">
            {currentQuestionIndex > 0 && (
              <button onClick={handlePrevious} className="form-nav-button">
                Previous
              </button>
            )}
            {currentQuestionIndex < form_items.length - 1 ? (
              <button onClick={handleNext} className="form-nav-button">
                Next
              </button>
            ) : (
              <div className="form-submit-area">
                <button onClick={handleSubmit} className="form-submit-button">
                  <TooltipNoShadow
                    text={'Submissions are final. You cannot submit again.'}>
                    Submit Form
                  </TooltipNoShadow>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderOwnerView = () => {
    if (!viewingResponses) {
      return (
        <div className="form-owner-summary">
          <div className="form-stats-card">
            <div className="form-stats-header">
              <h4>Form Responses</h4>
              <span className="form-stats-count">
                {form_responses?.length || 0}
              </span>
            </div>
            <div className="form-stats-details">
              <div className="form-stat-item">
                <span className="form-stat-label">Response Rate</span>
                <span className="form-stat-value">
                  {form_responses?.length || 0} responses
                </span>
              </div>
              <div className="form-stat-item">
                <span className="form-stat-label">Status</span>
                <span
                  className={`form-status ${expired ? 'expired' : 'active'}`}>
                  {expired ? 'Expired' : 'Active'}
                </span>
              </div>
            </div>
            <div className="form-action-buttons">
              <button
                className="form-view-responses"
                disabled={!form_responses?.length}
                onClick={() => setViewingResponses(true)}>
                View All Responses
              </button>
              {!expired && !userResponse && (
                <button
                  className="form-submit-response"
                  onClick={() => setShowOwnerForm(true)}>
                  Submit Response
                </button>
              )}
            </div>
          </div>
        </div>
      );
    }

    return renderResponseSummary();
  };

  const renderMainContent = () => {
    if (isOwner) {
      if (showOwnerForm) {
        return renderQuestionForm();
      }
      return renderOwnerView();
    }

    return renderQuestionForm();
  };

  return (
    <div className="form-content">
      <div className="form-meta">
        <span className="form-responses-count">
          {form_responses?.length || 0} response
          {form_responses?.length !== 1 ? 's' : ''}
        </span>
        {expiration && timeRemaining !== 'Expired' && (
          <span className="form-expiration">Expires in: {timeRemaining}</span>
        )}
      </div>

      {isOwner && (viewingResponses || showOwnerForm) && (
        <button
          onClick={() => {
            setViewingResponses(false);
            setShowOwnerForm(false);
          }}
          className="form-back-button">
          ← Back to Overview
        </button>
      )}

      {!isOwner && userResponse && showUserResponse && (
        <button
          onClick={() => setShowUserResponse(false)}
          className="form-back-button">
          ← Back to Summary
        </button>
      )}

      {renderMainContent()}
    </div>
  );
};

const PollContent = ({item}) => {
  const {
    state: {id, profiles, organizations},
  } = useAuth();
  const {updateContent} = useThreads();

  const {content, owner_id, created, sub_type, choices, responses, expiration} =
    item || {};

  const [timeRemaining, setTimeRemaining] = useState('');
  const expired = expiration ? dateToTimestamp() > expiration : false;

  const [freeformResponse, setFreeformResponse] = useState('');

  useEffect(() => {
    if (expiration) {
      const updateTimeRemaining = () => {
        const now = Date.now() / 1000;
        const remainingTime = expiration - now;
        if (remainingTime <= 0) {
          setTimeRemaining('Expired');
        } else {
          const days = Math.floor(remainingTime / (24 * 60 * 60));
          const hours = Math.floor(
            (remainingTime % (24 * 60 * 60)) / (60 * 60),
          );
          const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
          setTimeRemaining(`${days}d ${hours}h ${minutes}m`);
        }
      };

      updateTimeRemaining();
      const timer = setInterval(updateTimeRemaining, 60000);
      return () => clearInterval(timer);
    }
  }, [expiration]);

  const handleVote = async choice => {
    try {
      if (!choice || !item || !item.id || !responses) {
        console.error('Invalid data for voting');
        return;
      }

      const userResponse = responses.find(r => r.user_id === id);

      if (sub_type === 'multi_choice_poll') {
        const selected = responses.find(
          r => r.user_id === id && r.response === choice,
        );
        if (selected) {
          const removed = responses.filter(
            r => !(r.user_id === id && r.response === choice),
          );
          const update = {id: item.id, responses: removed};
          await updateContent(update);
        } else {
          const added = [...responses, {user_id: id, response: choice}];
          const update = {id: item.id, responses: added};
          await updateContent(update);
        }
      } else {
        if (userResponse) {
          if (userResponse.response === choice) {
            const removed = responses.filter(r => r.user_id !== id);
            const update = {id: item.id, responses: removed};
            await updateContent(update);
          } else {
            const updated = responses.map(r =>
              r.user_id === id ? {user_id: id, response: choice} : r,
            );
            const update = {id: item.id, responses: updated};
            await updateContent(update);
          }
        } else {
          const added = [...responses, {user_id: id, response: choice}];
          const update = {id: item.id, responses: added};
          await updateContent(update);
        }
      }
    } catch (error) {
      console.error('Error in handleVote:', error);
    }
  };

  const handleFreeformSubmit = async () => {
    if (!responses || !item || !item.id) return;

    if (freeformResponse.trim()) {
      const newResponses = responses.filter(r => r.user_id !== id);
      newResponses.push({user_id: id, response: freeformResponse.trim()});
      await updateContent({id: item.id, responses: newResponses});
      setFreeformResponse('');
    }
  };

  const calculateResponsePercentage = choice => {
    if (!responses?.length === 0) return 0;

    const totalVotes = responses?.length;

    const choiceVotes = responses.reduce(
      (sum, r) => sum + (r.response === choice ? 1 : 0),
      0,
    );

    return totalVotes > 0 ? (choiceVotes / totalVotes) * 100 : 0;
  };

  if (!item) {
    return <div>Error: Poll data is missing</div>;
  }

  return (
    <>
      <div className="content-body">
        <div className="poll-details milestone-details">
          {sub_type === 'freeform' ? (
            <div className="poll-freeform">
              <textarea
                value={freeformResponse}
                onChange={e => setFreeformResponse(e.target.value)}
                placeholder="Your response..."
                className="poll-freeform-input"
              />
              <button
                onClick={handleFreeformSubmit}
                className="poll-submit-button">
                Submit
              </button>
            </div>
          ) : (
            <div className="poll-choices ">
              {choices?.map((choice, index) => {
                const selected = responses.find(
                  item => item.user_id === id && item.response === choice,
                );

                return (
                  <div key={index} className="poll-choice milestone-metric">
                    <div className="milestone-metric-header">
                      <span className="milestone-metric-goal">{choice}</span>
                      <span className="milestone-metric-unit">
                        {calculateResponsePercentage(choice).toFixed(1)}%
                      </span>
                    </div>
                    <div className="milestone-metric-progress-bar">
                      <div
                        className="milestone-metric-progress-fill"
                        style={{
                          width: `${calculateResponsePercentage(choice)}%`,
                        }}></div>
                    </div>
                    <button
                      title={expired ? 'Poll Expired' : 'Select option'}
                      disabled={expired}
                      onClick={() => handleVote(choice)}
                      className={`poll-choice-button ${
                        selected ? 'selected' : ''
                      }`}>
                      {selected ? <RxCheckCircled /> : 'Select'}
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          <div className="poll-meta">
            <span className="poll-responses-count">
              {responses?.length || 0} response
              {responses?.length > 1 || responses?.length === 0 ? 's' : ''}
            </span>
            {expiration && (
              <span className="poll-expiration">{timeRemaining}</span>
            )}
          </div>
          <p className="poll-type-info">
            This is a {sub_type === 'multi_choice_poll' ? 'multiple' : 'single'}{' '}
            choice poll.
            {sub_type === 'multi_choice_poll'
              ? ' You can select multiple options.'
              : ' You can select only one option.'}
          </p>
          <MentionedUsers mentions={item.mentions} />
        </div>
      </div>
    </>
  );
};

const MeetingContent = ({item}) => {
  const {encryptString} = useCrypto();
  const navigate = useNavigate();
  const {
    state: {id, profiles, organizations},
  } = useAuth();

  const {content, owner_id, created, scheduled, mentions} = item;

  const user = profiles?.[owner_id] ?? {};
  const {first_name, last_name, position, organization_ids} = user;
  const current_org = organization_ids?.length
    ? organizations?.[organization_ids[0]]
    : null;

  const formatDate = timestamp => {
    const date = awsTimestampToDate(timestamp);
    return date.toLocaleString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    });
  };

  return (
    <div className="meeting-content">
      <div className="meeting-body">
        <h4 className="meeting-title">{content}</h4>
        <div className="meeting-details">
          <p className="meeting-time">
            <strong>When:</strong> {formatDate(scheduled)}
          </p>
          <p className="meeting-attendees">
            <strong>Attendees:</strong> {mentions.join(', ')}
          </p>
        </div>
        <div className="meeting-actions">
          <button className="meeting-join">Join Meeting</button>
          <button className="meeting-reschedule">Reschedule</button>
        </div>
      </div>
    </div>
  );
};

export const BreakoutComment = ({
  item,
  description,
  onReply,
  onQuote,
  focused,
  onClick,
  sub,
  setFocusedContent,
  viewOnly = false,
  setFullScreenContent,
}) => {
  const {loadProfiles} = useLoader();
  const navigate = useNavigate();
  const {encryptString} = useCrypto();
  const {prettyName} = useStringFormatter();

  const {
    state: {id, profiles, organizations},
  } = useAuth();
  const {updateContent} = useThreads();

  const contentRef = useRef(null);
  const {
    type,
    content,
    owner_id,
    created,
    media,
    priority,
    reactions,
    choices,
    responses,
    sub_type,
    dates,
    expiration,
    scheduled,
    mentions,
  } = item || {};

  const [reactionSum, setReactions] = useState([]);
  const [isTarget, setIsTarget] = useState(false);
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [freeformResponse, setFreeformResponse] = useState('');

  const new_content = owner_id !== id && (!sub || created > sub.last_read);
  const timeSinceString = timeSince(item?.updated);

  const user = profiles?.[owner_id] ?? {};
  const profile_image = user?.profile_image;
  const {first_name, last_name, position, organization_ids} = user;
  const current_org = organization_ids?.length
    ? organizations?.[organization_ids[0]]
    : null;

  useEffect(() => {
    if (!profiles?.[owner_id]) {
      loadProfiles([owner_id], {authMode: 'apiKey'});
    }
  }, [owner_id]);

  useEffect(() => {
    if (focused && contentRef.current) {
      contentRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
      setIsTarget(true);
      setFocusedContent(null);
    }
  }, [focused]);

  useEffect(() => {
    const sum = {};
    reactions?.forEach(item => {
      const {reaction, user_id} = item;
      if (sum[reaction] === undefined) {
        sum[reaction] = {count: 0, ids: []};
      }
      sum[reaction].count += 1;
      sum[reaction].ids.push(user_id);
    });
    const mapped = Object.keys(sum).map(key => {
      const values = sum[key];
      return {reaction: key, ...values};
    });
    setReactions(mapped);
  }, [reactions]);

  useEffect(() => {
    if (responses) {
      const userResponse = responses.find(r => r.user_id === id);
      if (userResponse) {
        setSelectedChoices(userResponse.response.split(','));
      }
    }
  }, [responses, id]);

  //How to show this when not authed?
  if (!item.id) {
    return (
      <div
        className={`content-item breakout`}
        ref={contentRef}
        onClick={onClick}>
        <div className="flex-row justify-between">
          <div className="content-header">
            <RxBookmarkFilled size={'40px'} />
            <div className="user-info">
              <span className="flex-column">
                <span className="flex-row">
                  <h3 className="user-name">Thread Description</h3>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="content-body">
          <ContentDisplay item={item} />
        </div>
      </div>
    );
  }

  const handleVote = async choice => {
    try {
      if (!choice || !item || !item.id || !responses) {
        console.error('Invalid data for voting');
        return;
      }

      let newSelectedChoices;
      if (sub_type === 'multi_choice_poll') {
        newSelectedChoices = selectedChoices.includes(choice)
          ? selectedChoices.filter(c => c !== choice)
          : [...selectedChoices, choice];
      } else {
        newSelectedChoices = [choice];
      }

      setSelectedChoices(newSelectedChoices);

      const newResponses = responses.filter(r => r.user_id !== id);
      newResponses.push({user_id: id, response: newSelectedChoices.join(',')});

      await updateContent({id: item.id, responses: newResponses});
    } catch (error) {
      console.error('Error in handleVote:', error);
    }
  };

  const handleFreeformSubmit = async () => {
    if (!responses || !item || !item.id) return;

    if (freeformResponse.trim()) {
      const newResponses = responses.filter(r => r.user_id !== id);
      newResponses.push({user_id: id, response: freeformResponse.trim()});
      await updateContent({id: item.id, responses: newResponses});
      setFreeformResponse('');
    }
  };

  const renderContent = () => {
    switch (type) {
      case 'comment':
        return null;
      case 'milestone':
        return <MilestoneDetails item={item} />;
      case 'question':
        return <QuestionDetails item={item} />;
      case 'decision_point':
        return <DecisionPointDetails item={item} />;
      case 'task':
        return <TaskDisplayGroup item={item} />;
      case 'poll':
        return (
          <PollDetails
            item={item}
            handleVote={handleVote}
            handleFreeformSubmit={handleFreeformSubmit}
          />
        );

      default:
        return <DeletedContent type="comment" />;
    }
  };

  const getContentIcon = () => {
    switch (type) {
      case 'milestone':
        return <RxSewingPinFilled size={'20px'} />;
      case 'question':
        return <RxQuestionMarkCircled size={'20px'} />;
      case 'decision_point':
        return (
          <MdLabelImportantOutline
            size={'20px'}
            className="decision-point-icon"
          />
        );
      case 'poll':
        return <BiPoll size={'20px'} />;
      case 'meeting':
        return <MdEventNote size={'20px'} />;
      default:
        return priority ? (
          <RiPushpinFill size={'16px'} className="margin-h8" />
        ) : null;
    }
  };

  return (
    <div
      className={`content-item breakout ${
        new_content ? 'new' : isTarget ? 'target' : ''
      } ${type}-item`}
      ref={contentRef}
      onClick={onClick}>
      <BaseContent item={item} />
      <div className="content-body">{renderContent()}</div>
      {!viewOnly && (
        <ContentActions
          item={item}
          onQuote={onQuote}
          onReply={onReply}
          setFullScreenContent={setFullScreenContent}
        />
      )}
    </div>
  );
};

export const PollDetails = ({item}) => {
  const navigate = useNavigate();
  const {
    state: {id, profiles, organizations},
  } = useAuth();
  const {updateContent} = useThreads();

  const {content, owner_id, created, sub_type, choices, responses, expiration} =
    item || {};

  const [timeRemaining, setTimeRemaining] = useState('');
  const expired = expiration ? dateToTimestamp() > expiration : false;

  const [freeformResponse, setFreeformResponse] = useState('');

  const user = profiles?.[owner_id] ?? {};
  const {first_name, last_name, position, organization_ids} = user;
  const current_org = organization_ids?.length
    ? organizations?.[organization_ids[0]]
    : null;

  useEffect(() => {
    if (expiration) {
      const updateTimeRemaining = () => {
        const now = Date.now() / 1000;
        const remainingTime = expiration - now;
        if (remainingTime <= 0) {
          setTimeRemaining('Expired');
        } else {
          const days = Math.floor(remainingTime / (24 * 60 * 60));
          const hours = Math.floor(
            (remainingTime % (24 * 60 * 60)) / (60 * 60),
          );
          const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
          setTimeRemaining(`${days}d ${hours}h ${minutes}m`);
        }
      };

      updateTimeRemaining();
      const timer = setInterval(updateTimeRemaining, 60000);
      return () => clearInterval(timer);
    }
  }, [expiration]);

  const handleVote = async choice => {
    try {
      if (!choice || !item || !item.id || !responses) {
        console.error('Invalid data for voting');
        return;
      }

      const userResponse = responses.find(r => r.user_id === id);

      if (sub_type === 'multi_choice_poll') {
        const selected = responses.find(
          r => r.user_id === id && r.response === choice,
        );
        if (selected) {
          const removed = responses.filter(
            r => !(r.user_id === id && r.response === choice),
          );
          const update = {id: item.id, responses: removed};
          await updateContent(update);
        } else {
          const added = [...responses, {user_id: id, response: choice}];
          const update = {id: item.id, responses: added};
          await updateContent(update);
        }
      } else {
        if (userResponse) {
          if (userResponse.response === choice) {
            const removed = responses.filter(r => r.user_id !== id);
            const update = {id: item.id, responses: removed};
            await updateContent(update);
          } else {
            const updated = responses.map(r =>
              r.user_id === id ? {user_id: id, response: choice} : r,
            );
            const update = {id: item.id, responses: updated};
            await updateContent(update);
          }
        } else {
          const added = [...responses, {user_id: id, response: choice}];
          const update = {id: item.id, responses: added};
          await updateContent(update);
        }
      }
    } catch (error) {
      console.error('Error in handleVote:', error);
    }
  };

  const handleFreeformSubmit = async () => {
    if (!responses || !item || !item.id) return;

    if (freeformResponse.trim()) {
      const newResponses = responses.filter(r => r.user_id !== id);
      newResponses.push({user_id: id, response: freeformResponse.trim()});
      await updateContent({id: item.id, responses: newResponses});
      setFreeformResponse('');
    }
  };

  const calculateResponsePercentage = choice => {
    if (!responses?.length === 0) return 0;

    const totalVotes = responses?.length;

    const choiceVotes = responses.reduce(
      (sum, r) => sum + (r.response === choice ? 1 : 0),
      0,
    );

    return totalVotes > 0 ? (choiceVotes / totalVotes) * 100 : 0;
  };

  if (!item) {
    return <div>Error: Poll data is missing</div>;
  }

  return (
    <div className="poll-details milestone-details">
      {sub_type === 'freeform' ? (
        <div className="poll-freeform">
          <textarea
            value={freeformResponse}
            onChange={e => setFreeformResponse(e.target.value)}
            placeholder="Your response..."
            className="poll-freeform-input"
          />
          <button onClick={handleFreeformSubmit} className="poll-submit-button">
            Submit
          </button>
        </div>
      ) : (
        <div className="poll-choices">
          {choices?.map((choice, index) => {
            const selected = responses.find(
              r => r?.user_id === id && r?.response === choice,
            );

            return (
              <div key={index} className="poll-choice milestone-metric">
                <div className="milestone-metric-header">
                  <span className="milestone-metric-goal">{choice}</span>
                  <span className="milestone-metric-unit">
                    {calculateResponsePercentage(choice).toFixed(1)}%
                  </span>
                </div>
                <div className="milestone-metric-progress-bar">
                  <div
                    className="milestone-metric-progress-fill"
                    style={{
                      width: `${calculateResponsePercentage(choice)}%`,
                    }}></div>
                </div>
                <button
                  title={expired ? 'Poll Expired' : 'Select option'}
                  disabled={expired}
                  onClick={() => handleVote(choice)}
                  className={`poll-choice-button ${
                    selected ? 'selected' : ''
                  }`}>
                  {selected ? <RxCheckCircled /> : 'Select'}
                </button>
              </div>
            );
          })}
        </div>
      )}
      <div className="poll-meta">
        <span className="poll-responses-count">
          {responses?.length || 0} response{responses?.length !== 1 ? 's' : ''}
        </span>
        {expiration && <span className="poll-expiration">{timeRemaining}</span>}
      </div>
      <p className="poll-type-info">
        This is a {sub_type === 'multi_choice_poll' ? 'multiple' : 'single'}{' '}
        choice poll.
        {sub_type === 'multi_choice_poll'
          ? ' You can select multiple options.'
          : ' You can select only one option.'}
      </p>
    </div>
  );
};

export const QuestionDetails = ({item}) => {
  const {
    state: {id, profiles},
  } = useAuth();
  const {updateContent} = useThreads();
  const {content, responses, expiration} = item;

  const [input, setInput] = useState('');
  const [timeRemaining, setTimeRemaining] = useState('');
  const expired = expiration ? dateToTimestamp() > expiration : false;

  const hasUserAnswered = responses?.some(response => response.user_id === id);
  const totalResponses = responses?.length || 0;

  useEffect(() => {
    if (expiration) {
      const updateTimeRemaining = () => {
        const now = Date.now() / 1000;
        const remainingTime = expiration - now;
        if (remainingTime <= 0) {
          setTimeRemaining('Expired');
        } else {
          const days = Math.floor(remainingTime / (24 * 60 * 60));
          const hours = Math.floor(
            (remainingTime % (24 * 60 * 60)) / (60 * 60),
          );
          const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
          setTimeRemaining(`${days}d ${hours}h ${minutes}m`);
        }
      };

      updateTimeRemaining();
      const timer = setInterval(updateTimeRemaining, 60000);
      return () => clearInterval(timer);
    }
  }, [expiration]);

  const handleSubmitResponse = async () => {
    if (input.trim() && !hasUserAnswered) {
      const newResponses = [
        ...(responses || []),
        {user_id: id, response: input.trim()},
      ];
      await updateContent({id: item.id, responses: newResponses});
      setInput('');
    }
  };

  return (
    <div className="question-details">
      <div className="question-meta">
        <span className="question-responses-count">
          {totalResponses} answer{totalResponses !== 1 ? 's' : ''}
        </span>
        {expiration && (
          <span className="question-expiration">
            Expires in: {timeRemaining}
          </span>
        )}
      </div>
      <div className="question-responses">
        {responses?.map((response, index) => (
          <div key={index} className="question-response">
            <ProfileImage
              data={profiles[response.user_id]?.profile_image}
              style={{height: '24px', width: '24px'}}
            />
            <div className="question-response-content">
              <span className="question-response-user">
                {profiles[response.user_id]?.first_name}{' '}
                {profiles[response.user_id]?.last_name}
              </span>
              <p>{response.response}</p>
            </div>
          </div>
        ))}
      </div>
      {!hasUserAnswered && timeRemaining !== 'Expired' && (
        <div className="question-input">
          <MultilineTextfield
            disabled={expired}
            placeholder="Your answer..."
            value={input}
            onChange={e => setInput(e.target.value)}
          />
          <button
            className="question-submit"
            disabled={!input.trim()}
            onClick={handleSubmitResponse}>
            Submit
          </button>
        </div>
      )}
    </div>
  );
};
export const OfficialPostProfile = () => {
  return {
    id: 'assistant',
    first_name: 'Official',
    last_name: 'Post',
    position: 'Assistant',
    organization: 'PublicMind',
    profile_image: {
      key: 'publicmind-logo',
      url: iconLight,
      type: 'image/png',
    },
  };
};
export default ThreadComment;
