import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactMarkdown from 'react-markdown';

import {
  RxActivityLog,
  RxCheckbox,
  RxCross2,
  RxLightningBolt,
  RxPerson,
  RxReload,
} from 'react-icons/rx';
import {StreamAIMessageBasic} from '../../../api/ai';
import {readableDateandTime} from '../../../api/dates';
import prompts from '../../../components/ai-prompts';
import {useAuth} from '../../../hooks/use-auth';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import './AIFloatingChat.css';

const INITIAL_MESSAGE = {
  type: 'assistant',
  content:
    "Hi! I'm your PublicMind Agent. I've analyzed the thread data and am ready to help answer any questions.",
};

const HELP_GUIDE_INFO = {
  type: 'user',
  content: `# PublicMind Functionality Guide

## Thread Management

### Creating Threads
- Access by hovering over nine dots menu in upper left and clicking "New Thread"
- Alternative: Launch Agent via upper left after completing three projects (knowledge base requirement)

### Thread Security Levels
- Public: Viewable by anyone
- Private: Discoverable but requires invitation
- Secure: Hidden and invitation-only

## Thread Features

### Basic Posting
- Text posts with Markdown formatting
- File attachments via drag and drop
- Official posting (admins/owners only)
  - Enable via checkmark circle in post creation UI

### Specialized Post Types
- Decision Points
- Milestones with Metrics
- Questions
- Polls
- Forms (Multi-question/poll series)
- Tasks

### Collaboration Roles
- Subscriber: Read-only access
- Collaborator: Can create posts
- Admin: Full thread management and posting rights

### Thread Organization
- Search functionality:
  - Global search in upper center
  - Thread-specific search in thread header
- AI chat via hover interface in bottom right (draft/live threads)
- Customizable sidebar colors via About modal
- Thread detail editing via About modal
- Organized view creation:
  - Access via vertical side menu between thread and thread list
  - Look for stacked cards icon at bottom
- Custom logo upload via pencil icon in thread header

## Navigation & Interface

### Home Screen Features
- Initial load displays:
  - Messages since last visit
  - Recent posts
  - Activity statistics
  - Discoverable threads

### Communication Tools
- Direct messaging:
  - Access via clicking profile pictures
  - Chat icon in upper right
  - New conversations require visiting user profile (click profile picture)
- AI Agent integration

### User Settings
- Access via profile picture in upper right:
  - Profile management
  - Theme switching (light/dark mode)
- Thread customization in About modal
- Notification preferences

## Best Practices

### Thread Organization
1. Start with clear thread purpose (draft/live)
2. Set appropriate security level
3. Assign proper user roles
4. Utilize specialized post types for structured information
5. Break out complex topics into sub-threads

### Collaboration Tips
1. Use official posts for important announcements (admins/owners)
2. Leverage AI chat for quick reference
3. Maintain organized views for better navigation
4. Monitor thread activity for engagement
5. Utilize knowledge base from completed threads

### Security Considerations
1. Choose appropriate thread visibility
2. Manage user roles carefully
3. Use secure threads for sensitive information
4. Review member access regularly
5. Monitor thread activity`,
};

const TypingIndicator = () => (
  <div className="chat-message assistant-message">
    <div className="typing-indicator">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
);

const AIChatAssistant = ({sub, data, onClose}) => {
  const {prettyName} = useStringFormatter();
  const {
    state: {profile},
  } = useAuth();

  const {
    state: {threads},
  } = useThreads();
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const hasLoadedInitialSummary = useRef(false);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);
  const questions = [
    {
      id: 'tasks',
      icon: <RxCheckbox className="quick-action-icon" />,
      label: 'Tasks Status',
      question: "What's the status of recent tasks?",
    },
    {
      id: 'updates',
      icon: <RxActivityLog className="quick-action-icon" />,
      label: 'Key Updates',
      question: 'What should I be aware of?',
    },
    {
      id: 'urgent',
      icon: <RxLightningBolt className="quick-action-icon" />,
      label: 'Urgent',
      question: 'What is the most pressing update I should know about?',
    },
    {
      id: 'team',
      icon: <RxPerson className="quick-action-icon" />,
      label: 'Team Activity',
      question: "What's the latest team activity?",
    },
  ];
  const generateInitialSummary = useCallback(async () => {
    if (hasLoadedInitialSummary.current || !data) return;
    hasLoadedInitialSummary.current = true;
    setIsTyping(true);

    try {
      let initialSummary = '';
      const userMessages = [
        {
          role: 'user',
          content: [
            {
              type: 'text',
              text: `Hello! my name is ${prettyName(profile)}. I am a ${
                profile?.position
              } Here is my background: ${profile?.bio}.`,
            },
          ],
        },
        {
          role: 'user',
          content: [
            {
              type: 'text',
              text: `Please provide a brief summary of the thread project data in 2-3 sentences since I last read this thread ${readableDateandTime(
                sub?.last_read,
              )}`,
            },
          ],
        },
      ];

      await StreamAIMessageBasic({
        messages: userMessages,
        system: prompts.base_system_chat + (data?.promptData || 'No data'),
        onUpdate: message => {
          initialSummary += message;
          setMessages([{type: 'assistant', content: initialSummary}]);
        },
      });
    } catch (error) {
      console.error('Error generating initial summary:', error);
      setMessages([INITIAL_MESSAGE]);
    } finally {
      setIsTyping(false);
    }
  }, [data, profile, prettyName]);

  useEffect(() => {
    generateInitialSummary();
  }, [generateInitialSummary]);

  const handleSendMessage = useCallback(
    async message => {
      const trimmedMessage = inputMessage.trim()
        ? inputMessage.trim()
        : message.trim();
      console.log(trimmedMessage);
      if (!trimmedMessage) return;

      setMessages(prev => [...prev, {type: 'user', content: trimmedMessage}]);
      setInputMessage('');
      setIsTyping(true);

      try {
        let assistantResponse = '';
        await StreamAIMessageBasic({
          messages: [
            {
              role: 'user',
              content: [{type: 'text', text: trimmedMessage}],
            },
          ],
          system: prompts.base_system_chat + (data?.promptData || 'No data'),
          onUpdate: message => {
            assistantResponse += message;
            setMessages(prev => {
              const newMessages = [...prev];
              const lastMessage = newMessages[newMessages.length - 1];

              if (lastMessage?.type === 'assistant') {
                lastMessage.content = assistantResponse;
                return [...newMessages];
              }
              return [
                ...newMessages,
                {type: 'assistant', content: assistantResponse},
              ];
            });
          },
        });
      } catch (error) {
        console.error('Error getting AI response:', error);
        setMessages(prev => [
          ...prev,
          {
            type: 'assistant',
            content:
              'I apologize, but I encountered an error. Please try again later.',
          },
        ]);
      } finally {
        setIsTyping(false);
      }
    },
    [inputMessage, data?.promptData],
  );

  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage],
  );
  const handleQuickAction = useCallback(
    question => {
      setInputMessage(question);
      // Use setTimeout to ensure state is updated before sending
      setTimeout(() => {
        handleSendMessage(question);
      }, 0);
    },
    [handleSendMessage],
  );
  const handleReset = useCallback(() => {
    hasLoadedInitialSummary.current = false;
    setMessages([]);
    generateInitialSummary();
  }, [generateInitialSummary]);

  const messageElements = useMemo(() => {
    if (messages.length === 1) {
      return (
        <>
          <div className="chat-message assistant-message">
            <div className="chat-message-content">{messages[0].content}</div>
          </div>
          {/* <div className="quick-actions-row">
            {questions.map(({id, icon, label, question}) => (
              <button
                key={id}
                className="quick-action-row-button"
                onClick={() => handleQuickAction(question)}
                title={question}>
                {icon}
                <span className="quick-action-row-label">{label}</span>
              </button>
            ))}
          </div> */}
        </>
      );
    }
    return messages.map((message, index) => (
      <div key={index} className={`chat-message ${message.type}-message`}>
        <div className="chat-message-content">
          <ReactMarkdown>{message.content}</ReactMarkdown>
        </div>
      </div>
    ));
  }, [messages, handleQuickAction]);

  return (
    <div className="chat-assistant">
      <div className="chat-assistant-header">
        <span className="flex-column">
          <h3>
            {threads?.[sub?.thread_id]?.logo} {threads?.[sub?.thread_id]?.title}
          </h3>
          <p className="text-12 text-secondary">
            Below, PublicMind Agent is restricted to this thread. All
            conversations are deleted on close.
          </p>
        </span>
        <button
          className="chat-assistant-close"
          onClick={onClose}
          aria-label="Close chat">
          <RxCross2 />
        </button>
      </div>

      <div className="chat-assistant-messages">
        {messageElements}
        {isTyping && <TypingIndicator />}
        <div ref={messagesEndRef} />
      </div>

      <div className="chat-assistant-input">
        <textarea
          ref={inputRef}
          value={inputMessage}
          onChange={e => setInputMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type your message..."
          rows={1}
        />
        <div className="chat-assistant-actions">
          <button
            className="chat-action-button"
            onClick={handleReset}
            title="Reset conversation">
            <RxReload />
          </button>
          <button
            className="chat-send-button"
            onClick={handleSendMessage}
            disabled={!inputMessage.trim() || isTyping}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AIChatAssistant);
