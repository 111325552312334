import {
  awsTimestampToDate,
  formatTimeDuration,
  getAWSTimestamp,
  timeSince,
} from '../../../api/dates';

// Enhanced helper for richer text extraction from blocks
export const extractTextFromBlocks = blocks => {
  if (!Array.isArray(blocks)) {
    console.error('Invalid blocks format:', blocks);
    return '';
  }

  let text = '';
  let metadata = {
    mentions: [],
    links: [],
    headings: [],
    listItems: 0,
    codeBlocks: 0,
  };

  const processContent = (content, inList = false) => {
    if (!Array.isArray(content)) return '';

    return content
      .map(item => {
        if (typeof item === 'string') return item;
        if (item.type === 'link') {
          metadata.links.push(item.href);
          return `[${item.text || item.href}](${item.href})`;
        }
        if (item.type === 'mention') {
          metadata.mentions.push(item.props.email);
          return `@${item.props.user}`;
        }
        if (item.type === 'code') {
          metadata.codeBlocks++;
          return `\`${item.text}\``;
        }
        if (item.text) return item.text;
        return '';
      })
      .join(' ');
  };

  blocks.forEach(block => {
    if (!block) return;

    if (block.type?.startsWith('heading')) {
      const level = block.type.slice(-1);
      const headingText = processContent(block.content);
      metadata.headings.push({level, text: headingText});
      const prefix = '#'.repeat(parseInt(level));
      text += `\n${prefix} ${headingText}\n`;
    } else if (block.type === 'bulletList' || block.type === 'orderedList') {
      metadata.listItems += block.content?.length || 0;
      text +=
        block.content
          ?.map(item => `\n- ${processContent(item, true)}`)
          .join('') + '\n';
    } else if (block.type === 'codeBlock') {
      metadata.codeBlocks++;
      text += `\n\`\`\`\n${processContent(block.content)}\n\`\`\`\n`;
    } else {
      text += processContent(block.content) + '\n';
    }
  });

  return {text: text.trim(), metadata};
};

// Enhanced media processing with more detailed information
const processMediaFiles = media => {
  if (!media || !Array.isArray(media)) return [];

  return media.map(file => {
    const size = file.size || 0;
    const units = ['B', 'KB', 'MB', 'GB'];
    let fileSize = size;
    let unitIndex = 0;

    while (fileSize >= 1024 && unitIndex < units.length - 1) {
      fileSize /= 1024;
      unitIndex++;
    }

    const fileExtension = file.key.split('.').pop().toLowerCase();
    const fileCategory = categorizeFile(fileExtension);

    return {
      type: file.type,
      category: fileCategory,
      size: size,
      name: file.key.split('/').pop(),
      readableSize: `${fileSize.toFixed(2)}${units[unitIndex]}`,
      extension: fileExtension,
      url: file.url || null,
      uploadTime: file.created ? awsTimestampToDate(file.created) : null,
    };
  });
};

const categorizeFile = extension => {
  const categories = {
    document: ['pdf', 'doc', 'docx', 'txt', 'rtf', 'odt'],
    spreadsheet: ['xls', 'xlsx', 'csv'],
    presentation: ['ppt', 'pptx'],
    image: ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'],
    video: ['mp4', 'mov', 'avi', 'webm'],
    audio: ['mp3', 'wav', 'ogg'],
    archive: ['zip', 'rar', '7z'],
  };

  for (const [category, extensions] of Object.entries(categories)) {
    if (extensions.includes(extension)) return category;
  }
  return 'other';
};

// Enhanced time-based content processing
const processTimeBasedContent = content => {
  const {dates, expiration, reminder, scheduled} = content;
  const now = getAWSTimestamp();

  const processDate = (timestamp, opts = {}) => {
    if (!timestamp) return null;

    const diffInSecs = timestamp - now;

    return {
      date: awsTimestampToDate(timestamp),
      isPast: diffInSecs < 0,
      timeLeft: formatTimeDuration(diffInSecs * 1000, {
        addPrefix: true,
        maxUnits: 2,
        roundSmallValues: true,
      }),
      // Keep timeFromNow for backward compatibility
      timeFromNow: formatTimeDuration(diffInSecs * 1000, {
        addPrefix: true,
        maxUnits: 2,
        roundSmallValues: true,
      }),
    };
  };

  return {
    dates: dates?.filter(Boolean).map(date => ({
      name: date.name,
      date: date.date ? awsTimestampToDate(date.date) : null,
      isPast: date.date ? date.date - now < 0 : false,
      timeFromNow: date.date
        ? formatTimeDuration((date.date - now) * 1000, {
            addPrefix: true,
            maxUnits: 2,
            roundSmallValues: true,
          })
        : null,
    })),

    expiration: expiration ? processDate(expiration) : null,
    reminder: reminder ? processDate(reminder) : null,
    scheduled: scheduled ? processDate(scheduled) : null,
  };
};

// Enhanced poll content processing
const processPollContent = content => {
  const {choices, responses, sub_type} = content;
  const baseInfo = {
    totalResponses: responses?.length || 0,
    uniqueRespondents: new Set(responses?.map(r => r.user_id) || []).size,
    responseRate: responses?.length
      ? (responses.length / content.mentions?.length || 1) * 100
      : 0,
  };

  if (sub_type === 'freeform') {
    return {
      ...baseInfo,
      sub_type,
      responses: responses?.map(r => ({
        response: r.response,
        user_id: r.user_id,
        timestamp: r.timestamp ? awsTimestampToDate(r.timestamp) : null,
      })),
      responseAnalysis: {
        averageLength:
          responses?.reduce((acc, r) => acc + r.response.length, 0) /
            responses?.length || 0,
        commonWords: analyzeResponseText(responses?.map(r => r.response) || []),
      },
    };
  }

  const responseBreakdown = choices?.map(choice => {
    const choiceResponses = responses?.filter(r => r.response === choice) || [];
    const responseCount = choiceResponses.length;
    return {
      choice,
      count: responseCount,
      percentage: responses?.length
        ? ((responseCount / responses.length) * 100).toFixed(1)
        : '0.0',
      respondents: choiceResponses.map(r => r.user_id),
      timeline: choiceResponses.map(r => r.timestamp).sort(),
    };
  });

  return {
    ...baseInfo,
    sub_type,
    choices,
    responseBreakdown,
    trends: analyzePollTrends(responseBreakdown),
  };
};

// New helper functions for enhanced analysis
const analyzeResponseText = responses => {
  const words = responses
    .join(' ')
    .toLowerCase()
    .split(/\W+/)
    .filter(word => word.length > 3);

  const wordFrequency = words.reduce((acc, word) => {
    acc[word] = (acc[word] || 0) + 1;
    return acc;
  }, {});

  return Object.entries(wordFrequency)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 5)
    .map(([word, count]) => ({word, count}));
};

const analyzePollTrends = breakdown => {
  if (!breakdown?.length) return null;

  const trends = {
    mostPopular: breakdown.sort((a, b) => b.count - a.count)[0],
    leastPopular: breakdown.sort((a, b) => a.count - b.count)[0],
    participationOverTime: breakdown.map(item => ({
      choice: item.choice,
      timeline: item.timeline,
    })),
  };

  return trends;
};

// Enhanced form content processing
const processFormContent = content => {
  const {form_items, form_responses} = content;
  if (!form_items) return null;

  // Process response data
  const formResponseSummary = form_responses?.reduce((acc, response) => {
    response.answers?.forEach(answer => {
      const question = form_items.find(item => item.id === answer.item_id);
      if (!question) return;

      if (!acc[question.question]) {
        acc[question.question] = {
          type: question.type,
          required: question.required,
          responses: [],
          total: 0,
          analytics: {
            uniqueResponses: new Set(),
            averageLength: 0,
            commonAnswers: {},
            completionRate: 0,
          },
        };
      }

      const entry = acc[question.question];
      entry.responses.push({
        value: answer.value,
        timestamp: response.timestamp,
        user_id: response.user_id,
      });
      entry.total++;

      // Update analytics
      entry.analytics.uniqueResponses.add(answer.value);
      if (typeof answer.value === 'string') {
        entry.analytics.averageLength += answer.value.length;
        entry.analytics.commonAnswers[answer.value] =
          (entry.analytics.commonAnswers[answer.value] || 0) + 1;
      }
    });
    return acc;
  }, {});

  // Calculate completion metrics
  const requiredQuestions = form_items.filter(item => item.required).length;
  const completionMetrics =
    form_responses?.map(response => ({
      user_id: response.user_id,
      completionRate: (response.answers?.length / requiredQuestions) * 100,
      timestamp: response.timestamp,
    })) || [];

  return {
    questions: form_items.map(item => ({
      question: item.question,
      type: item.type,
      required: item.required,
      choices: item.choices,
      validation: item.validation,
    })),
    responseSummary: formResponseSummary,
    totalResponses: form_responses?.length || 0,
    analytics: {
      completionMetrics,
      averageCompletionRate:
        completionMetrics.reduce((acc, curr) => acc + curr.completionRate, 0) /
          completionMetrics.length || 0,
      responseTimeline: completionMetrics
        .sort((a, b) => a.timestamp - b.timestamp)
        .map(m => ({
          timestamp: awsTimestampToDate(m.timestamp),
          completionRate: m.completionRate,
        })),
    },
  };
};

// Enhanced user formatting
const formatUser = (userId, profiles) => {
  if (userId === 'assistant') {
    return {
      fullName: 'PublicMind Assistant',
      position: '',
      organization_ids: [],
      bio: '',
      formatted: 'PublicMind Assistant',
    };
  }

  const user = profiles?.[userId];
  if (!user)
    return {
      fullName: 'Unknown',
      position: 'Unknown',
      organization_ids: [],
      bio: 'Unknown',
      formatted: userId,
    };

  const {first_name, last_name, position, organization_ids, bio} = user;
  const formattedName =
    first_name && last_name ? `${first_name} ${last_name}` : userId;

  return {
    fullName: formattedName,
    position,
    organization_ids,
    bio: bio?.substring(0, 100),
    formatted: position ? `${formattedName} (${position})` : formattedName,
  };
};

// Main content processing function with additional metrics
const processContentByType = (content, thread_content, profiles) => {
  if (!content?.type || !content?.content) {
    console.error('Invalid content structure:', content);
    return null;
  }

  const {
    type,
    content: rawContent,
    version,
    metrics,
    priority,
    parent,
    sub_type,
    owner_id,
    created,
    updated,
    media,
    reactions,
    children,
  } = content;

  // Extract rich text content and metadata
  const {text, metadata} =
    version === 'block'
      ? (() => {
          try {
            return extractTextFromBlocks(JSON.parse(rawContent));
          } catch (err) {
            console.error('Failed to parse block content:', err);
            return {text: rawContent, metadata: {}};
          }
        })()
      : {text: rawContent, metadata: {}};

  // Process reactions
  const processedReactions =
    reactions?.reduce((acc, reaction) => {
      if (!acc[reaction.reaction]) {
        acc[reaction.reaction] = {
          count: 0,
          users: [],
        };
      }
      acc[reaction.reaction].count++;
      acc[reaction.reaction].users.push(formatUser(reaction.user_id, profiles));
      return acc;
    }, {}) || {};

  const baseData = {
    type,
    priority: !!priority,
    text,
    textMetadata: metadata,
    media: processMediaFiles(media),
    owner_id,
    created: created ? awsTimestampToDate(created) : null,
    updated: updated ? awsTimestampToDate(updated) : null,
    parent_thread: parent ? thread_content[parent] : null,
    owner: formatUser(owner_id, profiles),
    mentions: content.mentions?.map(id => formatUser(id, profiles)) || [],
    reactions: processedReactions,
    engagement: {
      reactionCount: reactions?.length || 0,
      mentionCount: content.mentions?.length || 0,
      childCount: children?.length || 0,
      hasMedia: media?.length > 0,
    },
    ...processTimeBasedContent(content),
  };

  // Process by content type
  switch (type) {
    case 'milestone':
    case 'decision_point':
      return {
        ...baseData,
        metrics: metrics?.filter(Boolean).map(metric => ({
          goal: metric.goal,
          progress: metric.progress,
          unit: metric.unit,
          description: metric.description,
          percentComplete: metric.goal
            ? (
                (parseFloat(metric.progress) / parseFloat(metric.goal)) *
                100
              ).toFixed(1)
            : 0,
        })),
      };

    case 'poll':
      return {
        ...baseData,
        ...processPollContent(content),
      };

    case 'question':
      return {
        ...baseData,
        totalResponses: content.responses?.length || 0,
        responses: content.responses?.map(r => ({
          response: r.response,
          user: formatUser(r.user_id, profiles),
          timestamp: r.timestamp ? awsTimestampToDate(r.timestamp) : null,
        })),
        analytics: {
          responseRate: content.mentions?.length
            ? ((content.responses?.length || 0) / content.mentions.length) * 100
            : 0,
          averageResponseLength:
            content.responses?.reduce((acc, r) => acc + r.response.length, 0) /
            (content.responses?.length || 1),
          commonWords: analyzeResponseText(
            content.responses?.map(r => r.response) || [],
          ),
        },
      };

    // Continuation of the component...

    case 'form':
      const formData = processFormContent(content);
      return formData ? {...baseData, ...formData} : baseData;

    case 'task':
      const taskAnalytics = content.tasks?.reduce(
        (acc, task) => {
          acc.totalTasks++;
          if (task.status === 'completed') acc.completedTasks++;
          if (task.due_date && task.due_date * 1000 < Date.now())
            acc.overdueTasks++;
          if (task.assignee_ids?.length) acc.assignedTasks++;
          return acc;
        },
        {
          totalTasks: 0,
          completedTasks: 0,
          overdueTasks: 0,
          assignedTasks: 0,
        },
      ) || {
        totalTasks: 0,
        completedTasks: 0,
        overdueTasks: 0,
        assignedTasks: 0,
      };

      return {
        ...baseData,
        tasks: content.tasks?.filter(Boolean).map(task => ({
          description: task.description,
          status: task.status,
          assignees:
            task.assignee_ids?.map(id => formatUser(id, profiles)) || [],
          due_date: task.due_date ? awsTimestampToDate(task.due_date) : null,
          isOverdue: task.due_date && task.due_date * 1000 < Date.now(),
          timeRemaining: task.due_date ? timeSince(task.due_date) : null,
        })),
        taskMetrics: {
          ...taskAnalytics,
          completionRate:
            (taskAnalytics.completedTasks / taskAnalytics.totalTasks) * 100 ||
            0,
          assignmentRate:
            (taskAnalytics.assignedTasks / taskAnalytics.totalTasks) * 100 || 0,
          overdueRate:
            (taskAnalytics.overdueTasks / taskAnalytics.totalTasks) * 100 || 0,
        },
      };

    default:
      return baseData;
  }
};

// Main thread data generation function with enhanced analytics
const generateAIDataforCurrentThread = async (
  thread_to_complete,
  threads,
  thread_content,
  profiles,
) => {
  try {
    if (!thread_to_complete || !threads || !thread_content) {
      throw new Error('Missing required data');
    }

    // Track content and relationships
    const rootContent = new Map();
    const replyContent = new Map();
    const replyRelations = new Map();
    const contentTypes = new Map();
    const userActivity = new Map();
    const timelineEvents = [];

    // First pass: categorize and analyze content
    Object.entries(thread_content)
      .filter(([_, content]) => content?.thread_id === thread_to_complete.id)
      .forEach(([id, content]) => {
        // Track content types
        contentTypes.set(
          content.type,
          (contentTypes.get(content.type) || 0) + 1,
        );

        // Track user activity
        if (!userActivity.has(content.owner_id)) {
          userActivity.set(content.owner_id, {
            posts: 0,
            reactions: 0,
            mentions: 0,
            lastActive: 0,
          });
        }
        const userStats = userActivity.get(content.owner_id);
        userStats.posts++;
        userStats.reactions += content.reactions?.length || 0;
        userStats.mentions += content.mentions?.length || 0;
        userStats.lastActive = Math.max(
          userStats.lastActive,
          content.created || 0,
        );

        // Add to timeline
        timelineEvents.push({
          type: 'content_created',
          contentType: content.type,
          timestamp: content.created,
          user: content.owner_id,
          contentId: id,
        });

        // Organize content hierarchy
        if (content.parent) {
          replyContent.set(id, content);
          const parentReplies = replyRelations.get(content.parent) || [];
          parentReplies.push(id);
          replyRelations.set(content.parent, parentReplies);
        } else {
          rootContent.set(id, content);
        }
      });

    // Process content hierarchically
    const processedContents = Array.from(rootContent.values())
      .sort((a, b) => (a.created || 0) - (b.created || 0))
      .map(content => {
        try {
          const processedContent = processContentByType(
            content,
            thread_content,
            profiles,
          );
          if (!processedContent) return null;

          // Process replies if they exist
          const contentReplies = replyRelations.get(content.id);
          if (contentReplies?.length) {
            processedContent.replies = contentReplies
              .map(replyId => {
                const reply = replyContent.get(replyId);
                return reply
                  ? processContentByType(reply, thread_content, profiles)
                  : null;
              })
              .filter(Boolean)
              .sort((a, b) => (a.created || 0) - (b.created || 0));
          }

          return processedContent;
        } catch (err) {
          console.error('Error processing content:', content.id, err);
          return null;
        }
      })
      .filter(Boolean);

    // Calculate enhanced thread metrics
    const threadMetrics = {
      content: {
        totalRootContent: rootContent.size,
        totalReplies: replyContent.size,
        totalContentByType: Object.fromEntries(contentTypes),
        averageResponsesPerPost: replyContent.size / rootContent.size || 0,
      },
      engagement: {
        uniqueContributors: userActivity.size,
        totalReactions: Array.from(userActivity.values()).reduce(
          (sum, stats) => sum + stats.reactions,
          0,
        ),
        totalMentions: Array.from(userActivity.values()).reduce(
          (sum, stats) => sum + stats.mentions,
          0,
        ),
        mostActiveUsers: Array.from(userActivity.entries())
          .sort((a, b) => b[1].posts - a[1].posts)
          .slice(0, 5)
          .map(([userId, stats]) => ({
            user: formatUser(userId, profiles),
            posts: stats.posts,
            reactions: stats.reactions,
            mentions: stats.mentions,
            lastActive: awsTimestampToDate(stats.lastActive),
          })),
      },
      timeline: {
        created: awsTimestampToDate(thread_to_complete.created),
        updated: awsTimestampToDate(thread_to_complete.updated),
        timeSince: timeSince(thread_to_complete.created),
        events: timelineEvents
          .sort((a, b) => a.timestamp - b.timestamp)
          .map(event => ({
            ...event,
            timestamp: awsTimestampToDate(event.timestamp),
            user: formatUser(event.user, profiles),
          })),
      },
    };
    // Build enhanced thread data
    const threadData = {
      title: thread_to_complete.title,
      status: thread_to_complete.status,
      type: thread_to_complete.type,
      securityLevel: thread_to_complete.security_level,
      owner: formatUser(thread_to_complete.owner_id, profiles),
      tags: thread_to_complete.tags,
      content: processedContents,
      metrics: threadMetrics,
    };

    // Generate refined prompt data
    const promptData = generatePromptData(threadData);
    return {threadData, promptData};
  } catch (err) {
    console.error('Error generating thread data:', err);
    return null;
  }
};
const formatTaskStatus = status => {
  switch (status?.toLowerCase()) {
    case 'complete':
      return '✅';
    case 'rejected':
      return '❌';
    case 'in-progress':
      return '🔄';
    case 'todo':
    default:
      return '⭕';
  }
};

const calculateTaskMetrics = tasks => {
  if (!tasks?.length) return null;

  const totalTasks = tasks.length;
  const completedTasks = tasks.filter(t => t.status === 'complete').length;
  const rejectedTasks = tasks.filter(t => t.status === 'rejected').length;
  const inProgressTasks = tasks.filter(t => t.status === 'in-progress').length;
  const todoTasks =
    totalTasks - completedTasks - rejectedTasks - inProgressTasks;

  // Only count non-rejected tasks for completion rate
  const activeTaskCount = totalTasks - rejectedTasks;

  return {
    totalTasks,
    completedTasks,
    rejectedTasks,
    inProgressTasks,
    todoTasks,
    completionRate: activeTaskCount
      ? (completedTasks / activeTaskCount) * 100
      : 0,
    assignmentRate:
      (tasks.filter(t => t.assignees?.length > 0).length / totalTasks) * 100,
    overdueRate:
      (tasks.filter(t => t.due_date && new Date(t.due_date) < new Date())
        .length /
        totalTasks) *
      100,
  };
};

const formatTaskSection = content => {
  if (!content.tasks?.length) return '';

  const metrics = calculateTaskMetrics(content.tasks);

  const taskList = content.tasks
    .map(task => {
      const status = formatTaskStatus(task.status);
      const assignees =
        task.assignees?.map(a => a.formatted).join(', ') || 'Unassigned';
      const dueDate = task.due_date
        ? `(Due: ${task.timeRemaining}${task.isOverdue ? ' ⚠️' : ''})`
        : '(No due date)';

      return `- ${status} ${task.description} | Assigned: ${assignees} ${dueDate}`;
    })
    .join('\n');

  return `
Tasks (${metrics.completionRate.toFixed(1)}% Complete):
${taskList}

Task Analytics:
- Total Tasks: ${metrics.totalTasks}
- Completed: ${metrics.completedTasks} (${metrics.completionRate.toFixed(1)}%)
- In Progress: ${metrics.inProgressTasks}
- Todo: ${metrics.todoTasks}
- Rejected: ${metrics.rejectedTasks}
- Tasks Assigned: ${metrics.assignmentRate.toFixed(1)}%
- Tasks Overdue: ${metrics.overdueRate.toFixed(1)}%`;
};
// Separate function to generate optimized prompt data
const generatePromptData = threadData => {
  return `
${threadData.title}

Project Overview
Status: ${threadData.status}
Type: ${threadData.type}
Security Level: ${threadData.securityLevel}
Owner: ${
    threadData?.owner?.formatted ? threadData.owner.formatted : threadData.owner
  }
Time Since Created: ${threadData.metrics.timeline.timeSince}
Tags: ${threadData.tags?.join(', ') || 'None'}

Project Metrics
Content Statistics
- Total Root Posts: ${threadData.metrics.content.totalRootContent}
- Total Replies: ${threadData.metrics.content.totalReplies}
- Avg Responses/Post: ${threadData.metrics.content.averageResponsesPerPost.toFixed(
    1,
  )}

Engagement Metrics
- Unique Contributors: ${threadData.metrics.engagement.uniqueContributors}
- Total Reactions: ${threadData.metrics.engagement.totalReactions}
- Total Mentions: ${threadData.metrics.engagement.totalMentions}

Content Breakdown
${Object.entries(threadData.metrics.content.totalContentByType)
  .map(([type, count]) => `- ${type}: ${count}`)
  .join('\n')}

Top Contributors
${threadData.metrics.engagement.mostActiveUsers
  .map(
    user =>
      `- ${user.user.formatted}: ${user.posts} posts, ${user.reactions} reactions`,
  )
  .join('\n')}

Project Timeline
${threadData.content
  .map(content => {
    let entry = `\n${content.created.toLocaleString()} - ${
      content.type === 'comment' ? 'POST' : content.type.toUpperCase()
    }
By: ${content.owner.formatted}
${content.text}`;

    // Add type-specific details
    switch (content.type) {
      case 'milestone':
      case 'decision_point':
        if (content.metrics?.length) {
          entry +=
            '\nMetrics:\n' +
            content.metrics
              .map(
                m =>
                  `- ${m.description}: ${m.progress}/${m.goal} ${m.unit} (${m.percentComplete}%)`,
              )
              .join('\n');
        }
        break;

      case 'task':
        if (content.tasks?.length) {
          entry += formatTaskSection(content);
        }
        break;

      case 'poll':
        if (content.responseBreakdown) {
          entry +=
            '\nResults:\n' +
            content.responseBreakdown
              .map(r => `- ${r.choice}: ${r.count} votes (${r.percentage}%)`)
              .join('\n');
        }
        break;

      case 'question':
        if (content.analytics) {
          entry += `\nResponse Analysis:
- Response Rate: ${content.analytics.responseRate.toFixed(1)}%
- Avg Response Length: ${Math.round(
            content.analytics.averageResponseLength,
          )} chars`;
        }
        break;
    }

    // Add engagement metrics
    if (content.engagement) {
      entry += `\n\nEngagement:
- Reactions: ${content.engagement.reactionCount}
- Mentions: ${content.engagement.mentionCount}
- Replies: ${content.engagement.childCount}`;
    }

    // Add replies
    if (content.replies?.length) {
      entry +=
        '\n\nReplies:\n' +
        content.replies
          .map(reply => `- ${reply.owner.formatted}: ${reply.text}`)
          .join('\n');
    }

    return entry;
  })
  .join('\n')}

Key Insights
1. Participation: ${
    threadData.metrics.engagement.uniqueContributors
  } team members actively contributing
2. Engagement Level: ${calculateEngagementLevel(threadData.metrics)}
3. Progress Status: ${calculateProgressStatus(threadData)}
4. Collaboration Pattern: ${analyzeCollaborationPattern(threadData)}
5. Decision Points: ${countDecisionPoints(threadData.content)}

Key Terms and Mentions
${identifyKeyThemes(threadData.content)}
`;
};

// Helper functions for prompt generation
const calculateEngagementLevel = metrics => {
  const postsPerUser =
    metrics.content.totalRootContent / metrics.engagement.uniqueContributors;
  const reactionsPerPost =
    metrics.engagement.totalReactions / metrics.content.totalRootContent;

  if (postsPerUser >= 3 && reactionsPerPost >= 2) return 'High';
  if (postsPerUser >= 1.5 || reactionsPerPost >= 1) return 'Medium';
  return 'Low';
};

const calculateProgressStatus = threadData => {
  const hasRecentActivity =
    Date.now() - new Date(threadData.metrics.timeline.updated) <
    7 * 24 * 60 * 60 * 1000;
  const completedTasks = threadData.content
    .filter(c => c.type === 'task')
    .reduce((acc, curr) => acc + (curr.taskMetrics?.completedTasks || 0), 0);
  const totalTasks = threadData.content
    .filter(c => c.type === 'task')
    .reduce((acc, curr) => acc + (curr.taskMetrics?.totalTasks || 0), 0);

  if (!hasRecentActivity) return 'Inactive';
  if (totalTasks === 0) return 'Active (No Tasks)';
  if (completedTasks / totalTasks > 0.8) return 'Near Completion';
  if (completedTasks / totalTasks > 0.3) return 'In Progress';
  return 'Early Stages';
};

const analyzeCollaborationPattern = threadData => {
  const replyRate =
    threadData.metrics.content.totalReplies /
    threadData.metrics.content.totalRootContent;
  const mentionsPerPost =
    threadData.metrics.engagement.totalMentions /
    threadData.metrics.content.totalRootContent;

  if (replyRate > 2 && mentionsPerPost > 1) return 'Highly Collaborative';
  if (replyRate > 1 || mentionsPerPost > 0.5) return 'Moderately Collaborative';
  return 'Independent Work';
};

const countDecisionPoints = content => {
  const decisions = content.filter(c => c.type === 'decision_point').length;
  return `${decisions} formal decision${decisions !== 1 ? 's' : ''} made`;
};

const identifyKeyThemes = content => {
  // Analyze content text to identify common themes
  const allText = content
    .map(c => c.text)
    .join(' ')
    .toLowerCase();
  const words = allText.split(/\W+/).filter(w => w.length > 3);
  const wordFreq = words.reduce((acc, word) => {
    acc[word] = (acc[word] || 0) + 1;
    return acc;
  }, {});

  // Filter out common stop words
  const stopWords = new Set([
    'this',
    'that',
    'have',
    'with',
    'from',
    'they',
    'will',
    'would',
    'should',
    'could',
    'been',
  ]);
  const significantWords = Object.entries(wordFreq)
    .filter(([word]) => !stopWords.has(word))
    .sort((a, b) => b[1] - a[1])
    .slice(0, 5);

  // Group content by type for theme analysis
  const contentByType = content.reduce((acc, item) => {
    if (!acc[item.type]) acc[item.type] = [];
    acc[item.type].push(item);
    return acc;
  }, {});

  // Analyze themes based on content types and frequency
  const themes = [];

  // Add milestone-based themes
  if (contentByType.milestone?.length) {
    themes.push('Project Milestones & Progress');
  }

  // Add task-based themes
  if (contentByType.task?.length) {
    const taskStatus = contentByType.task.reduce((acc, task) => {
      task.tasks?.forEach(t => {
        acc[t.status] = (acc[t.status] || 0) + 1;
      });
      return acc;
    }, {});

    if (taskStatus.completed > 0) {
      themes.push('Task Completion & Deliverables');
    }
  }

  // Add decision-based themes
  if (contentByType.decision_point?.length) {
    themes.push('Strategic Decisions & Planning');
  }

  // Add common word-based themes
  significantWords.forEach(([word, freq]) => {
    if (freq > 3) {
      // Only include if word appears multiple times
      themes.push(
        `${word.charAt(0).toUpperCase() + word.slice(1)} (${freq} mentions)`,
      );
    }
  });

  return themes.map((theme, i) => `${i + 1}. ${theme}`).join('\n');
};

// Helper to process markdown content for AI consumption
const formatMarkdown = text => {
  // Remove excessive whitespace while preserving markdown formatting
  return text
    .replace(/\n{3,}/g, '\n\n')
    .replace(/\s+/g, ' ')
    .trim();
};

// Helper to analyze sentiment and tone
const analyzeSentimentAndTone = content => {
  const sentimentIndicators = {
    positive: ['success', 'achieve', 'complete', 'improve', 'progress'],
    negative: ['issue', 'problem', 'fail', 'delay', 'concern'],
    urgent: ['urgent', 'asap', 'critical', 'immediate', 'priority'],
  };

  const toneCount = Object.keys(sentimentIndicators).reduce((acc, tone) => {
    acc[tone] = 0;
    return acc;
  }, {});

  content.forEach(item => {
    const text = item.text.toLowerCase();
    Object.entries(sentimentIndicators).forEach(([tone, words]) => {
      if (words.some(word => text.includes(word))) {
        toneCount[tone]++;
      }
    });
  });

  const dominantTone = Object.entries(toneCount).sort(
    (a, b) => b[1] - a[1],
  )[0][0];

  return {
    overall: dominantTone,
    breakdown: toneCount,
  };
};

// Generate a thread summary
const generateThreadSummary = threadData => {
  const sentiment = analyzeSentimentAndTone(threadData.content);
  const taskProgress = threadData.content
    .filter(c => c.type === 'task')
    .reduce(
      (acc, curr) => {
        return {
          total: acc.total + (curr.taskMetrics?.totalTasks || 0),
          completed: acc.completed + (curr.taskMetrics?.completedTasks || 0),
        };
      },
      {total: 0, completed: 0},
    );

  return formatMarkdown(`
This ${threadData.type} thread shows ${sentiment.overall} sentiment with 
${taskProgress.completed}/${taskProgress.total} tasks completed. 
The thread has engaged ${
    threadData.metrics.engagement.uniqueContributors
  } participants 
over ${threadData.metrics.timeline.duration}, with an average of 
${threadData.metrics.content.averageResponsesPerPost.toFixed(
  1,
)} responses per post.

Key activities include:
${threadData.content
  .filter(c => ['milestone', 'decision_point'].includes(c.type))
  .slice(0, 3)
  .map(c => `- ${c.text.split('\n')[0]}`)
  .join('\n')}

Most active participants:
${threadData.metrics.engagement.mostActiveUsers
  .slice(0, 3)
  .map(u => `- ${u.user.formatted}: ${u.posts} posts`)
  .join('\n')}
  `);
};

// Add AI contextual hints
const addAIContext = (promptData, threadData) => {
  return `
${promptData}

## AI Context & Suggestions
- Thread Classification: ${classifyThread(threadData)}
- Communication Style: ${analyzeCommStyle(threadData)}
- Priority Level: ${determinePriority(threadData)}
- Next Steps: ${suggestNextSteps(threadData)}

NOTE TO AI: This thread requires ${getAIApproach(
    threadData,
  )} approach in responses.
`;
};

// Helper functions for AI context
const classifyThread = threadData => {
  const types = {
    planning: ['milestone', 'task'],
    discussion: ['question', 'poll'],
    documentation: ['comment'],
    decision: ['decision_point'],
  };

  const contentTypeCounts = threadData.content.reduce((acc, item) => {
    acc[item.type] = (acc[item.type] || 0) + 1;
    return acc;
  }, {});

  let maxType = '';
  let maxScore = 0;

  Object.entries(types).forEach(([classification, contentTypes]) => {
    const score = contentTypes.reduce(
      (sum, type) => sum + (contentTypeCounts[type] || 0),
      0,
    );
    if (score > maxScore) {
      maxScore = score;
      maxType = classification;
    }
  });

  return maxType.charAt(0).toUpperCase() + maxType.slice(1);
};

const analyzeCommStyle = threadData => {
  const avgContentLength =
    threadData.content.reduce((sum, item) => sum + item.text.length, 0) /
    threadData.content.length;

  const hasFormality = threadData.content.some(
    item =>
      item.text.toLowerCase().includes('please') ||
      item.text.toLowerCase().includes('thank you'),
  );

  if (avgContentLength > 500) return 'Detailed & Thorough';
  if (hasFormality) return 'Professional & Formal';
  if (avgContentLength < 100) return 'Brief & Direct';
  return 'Balanced & Practical';
};

const determinePriority = threadData => {
  const urgentKeywords = ['urgent', 'asap', 'immediate', 'critical'];
  const hasUrgentContent = threadData.content.some(item =>
    urgentKeywords.some(keyword => item.text.toLowerCase().includes(keyword)),
  );

  const recentActivity =
    Date.now() - new Date(threadData.metrics.timeline.updated) <
    24 * 60 * 60 * 1000;
  const highEngagement = threadData.metrics.engagement.uniqueContributors > 3;

  if (hasUrgentContent && recentActivity) return 'High';
  if (highEngagement || recentActivity) return 'Medium';
  return 'Low';
};

const suggestNextSteps = threadData => {
  const suggestions = [];

  // Check for incomplete tasks
  const incompleteTasks = threadData.content
    .filter(c => c.type === 'task')
    .some(t => t.taskMetrics.completionRate < 100);
  if (incompleteTasks) {
    suggestions.push('Complete outstanding tasks');
  }

  // Check for unanswered questions
  const unansweredQuestions = threadData.content
    .filter(c => c.type === 'question')
    .some(q => q.totalResponses === 0);
  if (unansweredQuestions) {
    suggestions.push('Address unanswered questions');
  }

  // Check for recent decisions requiring follow-up
  const recentDecisions = threadData.content
    .filter(c => c.type === 'decision_point')
    .some(d => Date.now() - new Date(d.created) < 7 * 24 * 60 * 60 * 1000);
  if (recentDecisions) {
    suggestions.push('Follow up on recent decisions');
  }

  return suggestions.join(', ') || 'Continue current progress';
};

const getAIApproach = threadData => {
  const style = analyzeCommStyle(threadData);
  const priority = determinePriority(threadData);

  if (style === 'Professional & Formal' && priority === 'High') {
    return 'a formal and action-oriented';
  }
  if (style === 'Brief & Direct') {
    return 'a concise and practical';
  }
  return 'a balanced and supportive';
};

export {
  addAIContext,
  analyzeSentimentAndTone,
  generatePromptData,
  generateThreadSummary,
};

export default generateAIDataforCurrentThread;
