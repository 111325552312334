import {useState} from 'react';
import {BsActivity} from 'react-icons/bs';
import {RxCheck, RxFile} from 'react-icons/rx';

export function DraftStatusButton({onClick}) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      className="thread-status-icon-group"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}>
      <div className="draft-status-content">
        <div
          className={`draft-status-state ${
            isHovered ? 'fade-out' : 'fade-in'
          }`}>
          <div className="draft-status-inner">
            <RxFile className="thread-status-icon draft" />
            <span>Draft</span>
          </div>
        </div>
        <div
          className={`draft-status-state active-state ${
            isHovered ? 'fade-in' : 'fade-out'
          }`}>
          <div className="draft-status-inner">
            <BsActivity className="thread-status-icon active-thread" />
            <span>Set Live</span>
          </div>
        </div>
      </div>
    </button>
  );
}
export function CompletedStatusButton({onClick}) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      className="thread-status-icon-group"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}>
      <div className="draft-status-content">
        <div
          className={`draft-status-state ${
            isHovered ? 'fade-out' : 'fade-in'
          }`}>
          <div className="draft-status-inner">
            <RxCheck className="thread-status-icon completed" />
            <span>Completed</span>
          </div>
        </div>
        <div
          className={`draft-status-state active-state ${
            isHovered ? 'fade-in' : 'fade-out'
          }`}>
          <div className="draft-status-inner">
            <BsActivity className="thread-status-icon active-thread" />
            <span>Set Live</span>
          </div>
        </div>
      </div>
    </button>
  );
}
export function LiveStatusButton({onClick}) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      className="thread-status-icon-group"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}>
      <div className="draft-status-content">
        <div
          className={`draft-status-state ${
            isHovered ? 'fade-out' : 'fade-in'
          }`}>
          <div className="draft-status-inner">
            <BsActivity className="thread-status-icon active-thread" />
            <span>Live</span>
          </div>
        </div>
        <div
          className={`draft-status-state active-state ${
            isHovered ? 'fade-in' : 'fade-out'
          }`}>
          <div className="draft-status-inner">
            <RxCheck className="thread-status-icon completed" />
            <span>Set Complete</span>
          </div>
        </div>
      </div>
    </button>
  );
}
