const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const isSameDay = (timestamp1, timestamp2) => {
  const date1 = new Date(timestamp1 * 1000);
  const date2 = new Date(timestamp2 * 1000);

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};
const formatTimeDuration = (time, options = {}) => {
  // Default options
  const defaultOptions = {
    compact: false,
    maxUnits: 2,
    alwaysShowSeconds: false,
    roundSmallValues: true,
    addPrefix: true,
    precision: 0,
  };
  options = {...defaultOptions, ...options};

  // Input validation and conversion
  if (!time && time !== 0) return 'Invalid duration';

  // Handle AWS timestamps (convert if needed)
  let ms = typeof time === 'number' ? time : 0;
  if (ms < 2147483647 && ms > -2147483647) {
    ms *= 1000;
  }

  // Calculate absolute value and direction
  const absMs = Math.abs(ms);
  const isPast = ms < 0;

  // Define time units with validation thresholds
  const timeUnits = [
    {
      unit: 'year',
      ms: 31536000000,
      threshold: 31536000000, // Only show years if >= 1 year
      abbreviation: 'y',
    },
    {
      unit: 'month',
      ms: 2592000000,
      threshold: 2592000000, // Only show months if >= 1 month
      abbreviation: 'mo',
    },
    {
      unit: 'week',
      ms: 604800000,
      threshold: 604800000, // Only show weeks if >= 1 week
      abbreviation: 'w',
    },
    {
      unit: 'day',
      ms: 86400000,
      threshold: 86400000, // Only show days if >= 1 day
      abbreviation: 'd',
    },
    {
      unit: 'hour',
      ms: 3600000,
      threshold: 3600000, // Only show hours if >= 1 hour
      abbreviation: 'h',
    },
    {
      unit: 'minute',
      ms: 60000,
      threshold: 60000, // Only show minutes if >= 1 minute
      abbreviation: 'm',
    },
    {
      unit: 'second',
      ms: 1000,
      threshold: 0, // Always show seconds if no larger units
      abbreviation: 's',
    },
  ];

  // Format the duration
  const formatParts = () => {
    let remaining = absMs;
    let parts = [];

    // Handle very small durations
    if (remaining < 1000 && options.roundSmallValues) {
      if (options.compact) return ['0s'];
      return ['less than a second'];
    }

    // Process each time unit
    for (const {unit, ms: unitMs, threshold, abbreviation} of timeUnits) {
      // Skip units that don't meet threshold unless we need to show seconds
      if (
        remaining < threshold &&
        !(options.alwaysShowSeconds && unit === 'second')
      ) {
        continue;
      }

      if (remaining >= unitMs) {
        const value =
          options.precision > 0 && unit === 'second'
            ? Number((remaining / unitMs).toFixed(options.precision))
            : Math.floor(remaining / unitMs);

        remaining = remaining % unitMs;

        if (options.compact) {
          parts.push(`${value}${abbreviation}`);
        } else {
          parts.push(`${value} ${unit}${value !== 1 ? 's' : ''}`);
        }

        if (parts.length === options.maxUnits) break;
      }
    }

    // Handle case where no parts were generated
    if (parts.length === 0) {
      if (options.compact) return ['0s'];
      return ['less than a second'];
    }

    return parts;
  };

  // Get formatted parts
  const parts = formatParts();

  // Join parts with appropriate formatting
  let duration = '';
  if (options.compact) {
    duration = parts.join(' ');
  } else {
    if (parts.length === 1) {
      duration = parts[0];
    } else {
      const lastPart = parts.pop();
      duration = `${parts.join(', ')} and ${lastPart}`;
    }
  }

  // Add time direction prefix if needed
  if (options.addPrefix && !options.compact) {
    duration = isPast ? `${duration} ago` : `in ${duration}`;
  }

  return duration;
};

// Export additional utility functions for common use cases
const timeUtils = {
  formatCompact: ms => formatTimeDuration(ms, {compact: true}),

  formatFull: ms =>
    formatTimeDuration(ms, {
      compact: false,
      maxUnits: 3,
      alwaysShowSeconds: true,
    }),

  formatPrecise: ms =>
    formatTimeDuration(ms, {
      compact: false,
      precision: 1,
      alwaysShowSeconds: true,
    }),

  formatDeadline: ms =>
    formatTimeDuration(ms, {
      maxUnits: 2,
      roundSmallValues: true,
      addPrefix: true,
    }),

  formatTimestamp: timestamp =>
    formatTimeDuration(timestamp * 1000, {
      maxUnits: 2,
      addPrefix: true,
    }),
};

const convertToDate = obj => {
  if (!obj) {
    return null;
  }
  const type = typeof obj;
  switch (type) {
    case 'object':
      return obj instanceof Date ? obj : obj.toDate();
    case 'string':
    case 'number':
      return new Date(obj);
    default:
      return obj;
  }
};

const dateToString = (date = new Date()) => {
  return date.toISOString().slice(0, 10);
};

const timeToString = (date = new Date()) => {
  return date.toISOString().slice(11, 16);
};

// TAKES IN A STRING
const formatDate = (
  date,
  options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  },
) => {
  const [year, month, day] = date.split('-').map(num => parseInt(num, 10));
  // Note: JavaScript months are 0-indexed, so subtract 1 from the month value.
  const formattedDate = new Date(year, month - 1, day).toLocaleDateString(
    'en-US',
    options,
  );
  return formattedDate;
};

// TAKES IN A DATE OBJ
export const dateFormatter = (
  date = new Date(),
  options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  },
) => {
  const formattedDate = new Date(date).toLocaleDateString('en-US', options);
  return formattedDate;
};

const formatTime = time => {
  // Split the time string into hours and minutes
  const [hours, minutes] = time.split(':').map(Number);

  // Convert hours to 12-hour format
  const twelveHourFormat = hours % 12 || 12;

  // Determine if it's AM or PM
  const period = hours >= 12 ? 'PM' : 'AM';

  // Construct the formatted time string
  const formattedTime = `${twelveHourFormat}:${minutes
    .toString()
    .padStart(2, '0')}${period}`;

  return formattedTime;
};

// Sets the year month day relative to the current day
const goBackInTimeFromDate = (data, date = new Date()) => {
  const {years, months, days} = data;
  const newDate = new Date(
    date.getFullYear() - (years || 0),
    date.getMonth() - (months || 0),
    date.getDate() - (days || 0),
  );
  return newDate;
};

const formatMMDDYYYY = (date = new Date()) => {
  try {
    const months = date.getMonth() + 1;
    const days = date.getDate();
    const years = date.getFullYear();
    return `${months}/${days}/${years}`;
  } catch (err) {
    return null;
  }
};

const formatWWW_MMM_DD_YYYY_TTTT = (date = new Date()) => {
  let stringify = date.toString();
  stringify = stringify.slice(0, 21);
  return stringify;
};

const formatWWW_DD_MMM_TTT = (date = new Date()) => {
  const day = date.getDate(); // Gets the day of the month
  const monthIndex = date.getMonth(); // Gets the month index (0-11)
  const hour = date.getHours(); // Gets the hour (0-23)
  const minute = date.getMinutes(); // Gets the minutes (0-59)

  const formattedHour = hour % 12 || 12; // Convert to 12-hour format
  const formattedMinute = String(minute).padStart(2, '0'); // Add leading zeros
  const ampm = hour >= 12 ? 'PM' : 'AM';

  return `${day} ${months[monthIndex]} ${formattedHour}:${formattedMinute} ${ampm}`;
};

const formatChainDate = inputDate => {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const targetDate = new Date(inputDate);

  // Resetting the time to compare only the date
  today.setHours(0, 0, 0, 0);
  yesterday.setHours(0, 0, 0, 0);
  targetDate.setHours(0, 0, 0, 0);

  if (targetDate.getTime() === today.getTime()) {
    return 'Today';
  }

  if (targetDate.getTime() === yesterday.getTime()) {
    return 'Yesterday';
  }

  const day = targetDate.getDate().toString().padStart(2, '0');
  const month = (targetDate.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because JavaScript months are 0-based
  const year = targetDate.getFullYear();

  return `${month}/${day}/${year}`;
};

const getCurrentYearAndWeek = (date = new Date()) => {
  const year = date.getFullYear();
  const dayOfYear = Math.floor((date - new Date(year, 0, 0)) / 86400000);
  const week = Math.ceil((dayOfYear + 1) / 7); // Adding 1 to account for January 1st

  return `${year}-${String(week).padStart(2, '0')}`;
};

const dateToTimestamp = (date = new Date()) => {
  return Math.floor(date.getTime() / 1000);
};

const awsTimestampToDate = awsTimestamp => {
  return new Date(awsTimestamp * 1000);
};

const isWithinLast24Hours = timestamp => {
  const oneDay = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  const now = new Date();
  const timestampDate = new Date(timestamp * 1000);

  return now - timestampDate < oneDay;
};

const isWithinLast30Days = timestamp => {
  const thirtyDays = 24 * 60 * 60 * 1000 * 30; // 30 days in milliseconds
  const now = new Date();
  const timestampDate = new Date(timestamp * 1000);
  return now - timestampDate < thirtyDays;
};

const timeSince = (timestamp, options = {}) => {
  const {
    shortFormat = false,
    futureDates = false,
    maxRelativeTime = 7 * 24 * 60 * 60, // 1 week in seconds
  } = options;

  const timestampDate = new Date(timestamp * 1000);
  const now = new Date();
  const timeDiffInSeconds = Math.floor((now - timestampDate) / 1000);
  const isInFuture = timeDiffInSeconds < 0;
  const absTimeDiffInSeconds = Math.abs(timeDiffInSeconds);

  // Function to format date
  const formatDate = (date, includeTime = false) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const weekStart = new Date(today);
    weekStart.setDate(today.getDate() - today.getDay());

    if (date.toDateString() === today.toDateString()) {
      return includeTime
        ? `Today at ${date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })}`
        : 'Today';
    } else if (date.toDateString() === yesterday.toDateString()) {
      return includeTime
        ? `Yesterday at ${date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })}`
        : 'Yesterday';
    } else if (date >= weekStart) {
      // If the date is within the current week, return the day name
      return date.toLocaleDateString('en-US', {weekday: 'long'});
    } else if (date.getFullYear() === today.getFullYear()) {
      return date.toLocaleDateString('en-US', {month: 'short', day: 'numeric'});
    } else {
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    }
  };

  // Handle very recent times
  if (absTimeDiffInSeconds < 5) {
    return shortFormat ? 'now' : 'Just now';
  }

  // Handle future dates if enabled
  if (isInFuture && futureDates) {
    if (absTimeDiffInSeconds < 60) return `in ${absTimeDiffInSeconds}s`;
    if (absTimeDiffInSeconds < 3600)
      return `in ${Math.floor(absTimeDiffInSeconds / 60)}m`;
    return formatDate(timestampDate, true);
  }

  // Past dates
  if (absTimeDiffInSeconds < 60)
    return shortFormat
      ? `${absTimeDiffInSeconds}s`
      : `${absTimeDiffInSeconds} seconds ago`;
  if (absTimeDiffInSeconds < 3600) {
    const minutes = Math.floor(absTimeDiffInSeconds / 60);
    return shortFormat
      ? `${minutes}m`
      : `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  }
  if (absTimeDiffInSeconds < 86400) {
    const hours = Math.floor(absTimeDiffInSeconds / 3600);
    return shortFormat
      ? `${hours}h`
      : `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  }
  if (absTimeDiffInSeconds < maxRelativeTime) {
    return formatDate(timestampDate);
  }

  // For older messages, return the formatted date
  return formatDate(timestampDate);
};

const readableDateandTime = timestamp => {
  if (typeof timestamp === 'string') {
    timestamp = Math.floor(new Date(timestamp).getTime() / 1000);
  }

  const timestampDate = new Date(timestamp * 1000); // Convert Unix timestamp to JavaScript date
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    // second: 'numeric',
    hour12: true,
  };
  return timestampDate.toLocaleDateString('en-US', options);
};

const formatEventDate = values => {
  if (!values) {
    return null;
  }
  const {start_date, start_time, end_date, end_time} = values;
  let pretty = '';

  if (start_date) {
    pretty += formatDate(start_date);
  }

  if (start_time) {
    pretty += `: ${formatTime(start_time)}`;
  }

  if (end_date) {
    pretty += ` - ${formatDate(end_date)}`;
  }

  if (end_time) {
    pretty += `: ${formatTime(end_time)}`;
  }

  return pretty;
};

const timestampToTimeOrDate = timestamp => {
  const date = new Date(timestamp);
  const today = new Date();

  const isSameDay =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();

  if (isSameDay) {
    // If the timestamp is the same day as today, return the time in HH:MM format
    return date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
  } else {
    // If the timestamp is not the same day as today, return the date in MM/DD/YYYY format
    return date.toLocaleDateString();
  }
};

const daysBetweenTodayAndTimestamp = timestamp => {
  if (!timestamp) return null;

  const timestampInMilliseconds = timestamp * 1000;

  const date = new Date(timestampInMilliseconds);

  const currentDate = new Date();

  const differenceInTime = date - currentDate;

  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays;
};

export {
  awsTimestampToDate,
  convertToDate,
  dateToString,
  dateToTimestamp,
  daysBetweenTodayAndTimestamp,
  formatChainDate,
  formatDate,
  formatEventDate,
  formatMMDDYYYY,
  formatTime,
  formatTimeDuration,
  formatWWW_DD_MMM_TTT,
  formatWWW_MMM_DD_YYYY_TTTT,
  getCurrentYearAndWeek,
  goBackInTimeFromDate,
  isSameDay,
  isWithinLast24Hours,
  isWithinLast30Days,
  readableDateandTime,
  timeSince,
  timeToString,
  timestampToTimeOrDate,
};
// New utility functions for AWS timestamps
const getAWSTimestamp = (date = new Date()) =>
  Math.floor(date.getTime() / 1000);

const getTimeFromNow = (timestamp, options = {}) => {
  if (!timestamp) return null;
  const now = getAWSTimestamp();
  const diff = timestamp - now;
  return {
    timestamp,
    now,
    diff,
    isPast: diff < 0,
    formatted: formatTimeDuration(diff * 1000, options),
  };
};

const isExpired = (timestamp, now = getAWSTimestamp()) => {
  if (!timestamp) return false;
  return timestamp < now;
};

const processTimeBasedContent = content => {
  const {dates, expiration, reminder, scheduled} = content;
  const now = getAWSTimestamp();

  const processDate = (timestamp, options = {}) => {
    if (!timestamp) return null;

    const timeInfo = getTimeFromNow(timestamp, options);
    return {
      timestamp,
      date: awsTimestampToDate(timestamp),
      isPast: timeInfo.isPast,
      timeFromNow: timeInfo.formatted,
      // Additional useful properties
      isToday: isSameDay(timestamp, now),
      readableDate: readableDateandTime(timestamp),
      shortDate: formatMMDDYYYY(awsTimestampToDate(timestamp)),
    };
  };

  const processDateArray = dates?.filter(Boolean).map(date => ({
    name: date.name,
    ...processDate(date.date, {
      addPrefix: true,
      maxUnits: 2,
      roundSmallValues: true,
    }),
  }));

  // Group dates by status for easier access
  const groupedDates = processDateArray?.reduce((acc, date) => {
    acc[date.name] = date;
    return acc;
  }, {});

  return {
    // Original date array but processed
    dates: processDateArray,

    // Grouped dates for easier access
    datesByType: groupedDates,

    // Time-based flags
    hasStartDate: groupedDates?.start?.timestamp,
    hasDueDate: groupedDates?.due?.timestamp,
    isOverdue: groupedDates?.due?.isPast,
    isStarted: groupedDates?.start?.isPast,
    isCompleted: groupedDates?.completed?.timestamp,

    // Additional date processing
    expiration: processDate(expiration, {
      addPrefix: true,
      maxUnits: 2,
      roundSmallValues: true,
    }),

    reminder: processDate(reminder, {
      addPrefix: true,
      maxUnits: 1,
      roundSmallValues: true,
    }),

    scheduled: processDate(scheduled, {
      addPrefix: true,
      maxUnits: 2,
      roundSmallValues: true,
    }),

    // Timestamps for comparisons
    timestamps: {
      start: groupedDates?.start?.timestamp,
      due: groupedDates?.due?.timestamp,
      completed: groupedDates?.completed?.timestamp,
      expiration: expiration,
      reminder: reminder,
      scheduled: scheduled,
    },

    // Status helpers
    status: {
      isActive:
        groupedDates?.start?.isPast && !groupedDates?.completed?.timestamp,
      isPending: groupedDates?.start?.timestamp && !groupedDates?.start?.isPast,
      isExpired: expiration ? isExpired(expiration, now) : false,
      hasReminder: reminder ? !isExpired(reminder, now) : false,
      isScheduled: scheduled ? !isExpired(scheduled, now) : false,
    },
  };
};

// Additional utility function for date range handling
const getDateRangeInfo = (startTimestamp, endTimestamp) => {
  if (!startTimestamp || !endTimestamp) return null;

  const now = getAWSTimestamp();
  const duration = endTimestamp - startTimestamp;
  const progress =
    now > startTimestamp ? Math.min((now - startTimestamp) / duration, 1) : 0;

  return {
    start: processTimeBasedContent({
      dates: [{name: 'start', date: startTimestamp}],
    }).dates[0],
    end: processTimeBasedContent({dates: [{name: 'end', date: endTimestamp}]})
      .dates[0],
    duration: formatTimeDuration(duration * 1000, {
      maxUnits: 2,
      addPrefix: false,
    }),
    progress: progress * 100,
    status:
      progress === 1 ? 'completed' : progress > 0 ? 'in-progress' : 'pending',
    remaining: formatTimeDuration(
      (endTimestamp - Math.max(now, startTimestamp)) * 1000,
      {
        maxUnits: 2,
        addPrefix: true,
      },
    ),
  };
};

export {
  getAWSTimestamp,
  getDateRangeInfo,
  getTimeFromNow,
  isExpired,
  processTimeBasedContent,
};
