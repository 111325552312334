import {useState} from 'react';
import {FiInfo} from 'react-icons/fi';
import {RxCross2} from 'react-icons/rx';
import {useAuth} from '../../../hooks/use-auth';
import {useThreads} from '../../../hooks/use-threads';
import './SaveToThreadSelector.css';

const ThreadSelector = ({selectedThreads, onThreadSelect}) => {
  const {
    state: {id},
  } = useAuth();
  const {
    state: {threads},
  } = useThreads();
  const [searchQuery, setSearchQuery] = useState('');

  const completedThreads = Object.values(threads).filter(
    thread =>
      thread?.status === 'complete' &&
      (!searchQuery ||
        thread?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        thread?.content?.toLowerCase()?.includes(searchQuery?.toLowerCase())) &&
      thread?.permissions.find(x => x.user_id === id),
  );
  return (
    <div className="ai-assistant-section">
      <div className="ai-assistant-section-header">
        <h4>Knowledge Base</h4>
      </div>
      <div className="ai-assistant-info-text">
        <FiInfo size={14} />
        <span>Only completed threads can be used as references</span>
      </div>
      <div className="ai-assistant-search">
        <input
          type="text"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          placeholder="Search completed threads..."
          className="ai-assistant-input"
        />
      </div>

      {selectedThreads.length > 0 && (
        <div className="ai-assistant-selected-threads">
          <h5>Selected Threads</h5>
          {selectedThreads.map(thread => (
            <div key={thread.id} className="ai-assistant-selected-thread">
              <div className="ai-assistant-selected-thread-content">
                {thread.custom_logo && (
                  <img
                    src={thread.custom_logo?.url}
                    alt="Logo"
                    style={{
                      height: '16px',
                      maxWidth: '32px',
                      width: 'auto',
                      marginRight: '4px',
                    }}
                  />
                )}
                <span>{thread.title}</span>
              </div>
              <button
                onClick={() => onThreadSelect(thread)}
                className="ai-assistant-button-remove">
                Remove
              </button>
            </div>
          ))}
        </div>
      )}

      <div className="ai-assistant-thread-list">
        {completedThreads.map(thread => (
          <div
            key={thread.id}
            className={`thread-item ${
              selectedThreads.some(t => t.id === thread.id) ? 'active' : ''
            }`}
            onClick={() => onThreadSelect(thread)}>
            <span className="flex-row align-center">
              {thread.custom_logo && (
                <img
                  src={thread.custom_logo?.url}
                  alt="Logo"
                  style={{
                    height: '16px',
                    maxWidth: '32px',
                    width: 'auto',
                    marginRight: '2px',
                  }}
                />
              )}
              <p className="thread-item__title">
                {thread.logo} {thread.title}
              </p>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
export const SaveToThreadSelector = ({onSelect, onClose}) => {
  const {
    state: {id},
  } = useAuth();
  const {
    state: {threads},
  } = useThreads();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedThread, setSelectedThread] = useState(null);

  const filteredThreads = Object.values(threads).filter(
    thread =>
      thread?.permissions?.find(x => x.user_id === id) &&
      (!searchQuery ||
        thread?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())),
  );

  return (
    <div className="ai-thread-selector-modal">
      <div className="ai-thread-selector-content">
        <div className="ai-thread-selector-header">
          <h3>Save to Thread</h3>
          <RxCross2 className="clickable" onClick={onClose} />
        </div>

        <div className="ai-thread-selector-search">
          <input
            type="text"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            placeholder="Search threads..."
            className="ai-assistant-input"
          />
        </div>

        <div className="ai-thread-selector-list">
          {filteredThreads.map(thread => (
            <div
              key={thread.id}
              className={`ai-thread-selector-item ${
                selectedThread?.id === thread.id ? 'selected' : ''
              }`}
              onClick={() => setSelectedThread(thread)}>
              <div className="ai-thread-selector-item-content">
                {thread.custom_logo && (
                  <img
                    src={thread.custom_logo?.url}
                    alt=""
                    className="ai-thread-selector-logo"
                  />
                )}
                <span>{thread.title}</span>
              </div>
              <span className="ai-thread-selector-status">{thread.status}</span>
            </div>
          ))}
        </div>

        <div className="ai-thread-selector-footer">
          <button
            className="ai-assistant-button ai-assistant-button-secondary"
            onClick={onClose}>
            Cancel
          </button>
          <button
            className="ai-assistant-button ai-assistant-button-primary"
            disabled={!selectedThread}
            onClick={() => onSelect(selectedThread)}>
            Save to Thread
          </button>
        </div>
      </div>
    </div>
  );
};
export default ThreadSelector;
