import constants from '../../components/constants';

const generatePlatformInvite = props => {
  const {link = null, profile, note} = props || {};
  const {email, first_name, last_name} = profile || {};
  const pretty_name =
    first_name || last_name
      ? `${first_name || ''} ${last_name || ''}`.trim()
      : email;

  return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap" rel="stylesheet">
        <style>
            body { font-family: 'Inter', sans-serif; color: #333; line-height: 1.6; margin: 0; padding: 0; background-color: #f7f7f7; }
            .container { max-width: 600px; margin: 0 auto; padding: 20px; background-color: #ffffff; }
            .logo { text-align: center; margin-bottom: 20px; }
            .content { background-color: #f4f4f4; padding: 32px; border-radius: 12px; }
            .button { 
                display: inline-block; 
                background-color: #4743c5; 
                color: white; 
                padding: 16px 32px; 
                text-decoration: none; 
                border-radius: 8px; 
                font-weight: 600;
                font-size: 16px;
                transition: all 0.3s ease;
            }
            .button:hover {
                background-color: #3d39b0;
                transform: translateY(-2px);
            }
            .secondary-button { 
                display: inline-block; 
                background-color: #ffffff; 
                color: #4743c5; 
                padding: 14px 28px; 
                text-decoration: none; 
                border-radius: 8px; 
                font-weight: 600; 
                border: 2px solid #4743c5;
                font-size: 15px;
            }
            .footer { text-align: center; margin-top: 24px; color: #666; font-size: 14px; }
            .footer a { margin: 0 8px; color: #666; text-decoration: none; }
            .note { 
                white-space: pre-line; 
                border-left: 4px solid #4743c5; 
                padding: 16px; 
                border-radius: 8px; 
                margin: 24px 0;
                background: #ffffff;
            }
            .highlight-box {
                background: linear-gradient(135deg, #4743c5, #6e8efb);
                color: white;
                padding: 24px;
                border-radius: 12px;
                margin: 24px 0;
            }
            .feature-grid { 
                display: grid; 
                grid-template-columns: 1fr; 
                gap: 16px; 
                margin: 24px 0; 
            }
            .feature-item { 
                background: #ffffff; 
                padding: 20px; 
                border-radius: 8px;
                border: 1px solid #e1e4e8;
                transition: transform 0.3s ease;
            }
            .feature-item:hover {
                transform: translateY(-2px);
            }
            .feature-icon {
                font-size: 24px;
                margin-bottom: 12px;
            }
            .personal-note {
                background: #fff9e6;
                border: 1px solid #ffd700;
                padding: 16px;
                border-radius: 8px;
                margin-bottom: 24px;
            }
            .badge {
                display: inline-block;
                background: rgba(71, 67, 197, 0.1);
                color: #4743c5;
                padding: 4px 12px;
                border-radius: 16px;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 16px;
            }
        </style>
    </head>
    <body>
        <div class="container">
            <div class="logo">
                           <img src="${
                             constants.publicmind_logo
                           }" alt="PublicMind Logo" height="48" width="48" />
                <h2 style="font-weight: 200; margin-top: 12px;">PUBLICMIND</h2>
            </div>
            <div class="content">
                <div class="badge">SAFE AND SECURE AGENT WORKSPACE</div>
                
                <h2 style="margin: 0 0 16px 0;">Turn Your Daily Work Into Powerful Knowledge</h2>
                
                ${
                  note
                    ? `
                    <div class="personal-note">
                        <strong>A note from ${pretty_name}:</strong>
                        <div style="margin-top: 8px;">${note}</div>
                    </div>
                `
                    : ''
                }

                <div class="highlight-box">
                    <h3 style="margin: 0 0 12px 0;">${
                      pretty_name || 'Someone'
                    } Thinks You'll Love PublicMind</h3>
                    <p style="margin: 0;">Simply log your work as you go, and the PublicMind Agent does the rest - automatically saving knowledge, generating perfect documentation, and creating polished presentations and reports with a single click. It's like having a dedicated assistant who never forgets a detail.</p>
                </div>

                <div class="feature-grid">
                    <div class="feature-item">
                        <div class="feature-icon">🟩</div>
                        <h4 style="margin: 0 0 8px 0">Your Intelligent Assistant</h4>
                        <p style="margin: 0;">Turn your projects into professional emails, presentations, and reports with one click. The PublicMind Agent handle the heavy lifting while you focus on what matters.</p>
                    </div>
                    
                    <div class="feature-item">
                        <div class="feature-icon">🔄</div>
                        <h4 style="margin: 0 0 8px 0">Works With Your Tools</h4>
                        <p style="margin: 0;">No IT headaches or system changes needed. Work seamlessly with Microsoft, Google, and other platforms - just like working with colleagues down the hall.</p>
                    </div>
                    
                    <div class="feature-item">
                        <div class="feature-icon">📚</div>
                        <h4 style="margin: 0 0 8px 0">Automatic Knowledge Sharing</h4>
                        <p style="margin: 0;">As your team logs their work, PublicMind automatically preserves the context behind every decision. When someone retires or moves on, their expertise stays with you.</p>
                    </div>

                    <div class="feature-item">
                        <div class="feature-icon">🚀</div>
                        <h4 style="margin: 0 0 8px 0">Start Projects 3x Faster</h4>
                        <p style="margin: 0;">Every project makes your whole team smarter. Access proven approaches, avoid past pitfalls, and build on each success - all powered by secure AI that learns from your organization's experience.</p>
                    </div>
                </div>

                <div style="background: #fff; padding: 20px; border-radius: 8px; margin: 24px 0; text-align: center;">
                    <h4 style="margin: 0 0 12px 0; color: #4743c5;">How It Works:</h4>
                    <p style="margin: 0;">1. Log your work naturally as you go</p>
                    <p style="margin: 0;">2. AI agents organize and connect knowledge automatically</p>
                    <p style="margin: 0;">3. Generate perfect documentation with one click</p>
                </div>

                <p style="font-weight: 600; font-size: 18px; text-align: center; margin: 32px 0 16px 0;">Ready to make your work easier and more impactful?</p>
                
                          <div style="text-align: center; margin-bottom: 32px;">
                    <a href="${link}" class="button">Join Your Project Space →</a>
                </div>

                <div style="text-align: center;">
                    <p style="margin-bottom: 16px;">Want to see PublicMind in action?</p>
                    <a href="https://calendly.com/jack-publicmind" class="secondary-button">Schedule a 15-min Demo</a>
                </div>

                <p style="text-align: center; margin-top: 32px; color: #666;">Have questions? Our team is here to help at <a href="mailto:help@publicmind.ai" style="color: #4743c5; text-decoration: none;">help@publicmind.ai</a></p>
            </div>
            
            <div class="footer">
                <p style="font-weight: 600; color: #333;">PublicMind - Where Public Sector Knowledge Lives and Grows</p>
                <div style="margin-top: 16px;">
                    <a href="${constants.privacy_policy}">Privacy Policy</a>
                    <a href="${constants.terms_of_service}">Terms of Service</a>
                </div>
            </div>
            </div>
        </div>
    </body>
    </html>
  `;
};

export default generatePlatformInvite;
