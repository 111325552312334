import {useCreateBlockNote} from '@blocknote/react';
import {useFormik} from 'formik';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {v4} from 'uuid';
import * as Yup from 'yup';

import {track} from '../../../api/analytics';
import {dateToTimestamp} from '../../../api/dates';
import {sendPriorityThreadContent} from '../../../api/email';
import ActionPopup from '../../../components/action-feedback-popup/action-feedback-popup';
import {setupBlocks} from '../../../components/blocks/divider';
import {schema} from '../../../components/inputs/block-input';
import {
  CreateSpecialCommentModal,
  ScheduleContentModal,
} from '../../../components/modal/thread-modals';
import {useAuth} from '../../../hooks/use-auth';
import {useThreads} from '../../../hooks/use-threads';
import useUtilities from '../../../hooks/use-utilities';
import {ContentInputField} from './content-input-field';
import {
  CommentActionButtons,
  CommentActionButtonsHome,
} from './create-content-action-buttons';
import './CreateComment.css';

// HIGHEST LEVEL COMPONENT FOR COMMENT - INCLUDES INPUT AND BUTTONS
export const CreateComment = ({id, onSuccess}) => {
  const createComment = useRef(null);
  const inputRef = useRef(null);
  const {copyString} = useUtilities();

  const {
    state: {id: user_id, profile},
  } = useAuth();
  const {
    state: {threads, thread_subs, thread_content},
    createThreadContent,
    updateThreadSubscription,
    createThreadSubscription,
  } = useThreads();

  let user_role = threads?.[id]?.permissions.find(
    p => p.user_id === user_id,
  )?.role;
  const canMakeOfficial = user_role === 'owner' || user_role === 'admin';

  const [isExpanded, setIsExpanded] = useState(false);
  const [showFileUploader, setShowFileUploader] = useState(false);
  const [files, setFiles] = useState([]);
  const [schedule, setSchedule] = useState(false);
  const [specialComment, setSpecialComment] = useState(null);
  const [popup, setPopup] = useState({on: false, message: ''});
  const [isOfficial, setIsOfficial] = useState(false);
  const editor = useCreateBlockNote(
    setupBlocks(
      {
        schema: schema,
        trailingBlock: false,
      },
      threads,
      thread_content,
    ),
  );
  const handleEditorCleanup = () => {
    try {
      const blocks = editor.document;

      // Then remove them all at once
      if (blocks.length > 0) {
        editor.removeBlocks(blocks);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      owner_id: user_id,
      thread_id: id,
      type: 'comment',
      status: 'active',
      priority: false,
      content: '',
      content_html: '',
      version: 'block',
      media: [],
      mentions: [],
      responses: [],
      parent: null,
      scheduled: null,
      reminder: false,
      expiration: null,
      dates: [],
      metrics: [],
      tasks: [],
      sub_type: null,
    },

    validationSchema: Yup.object()
      .shape({
        content: Yup.string(),
        media: Yup.array(),
      })
      .test(
        'content-or-media-required',
        'Either content or at least one media item is required',
        function (values) {
          return (
            (values.content && values.content.trim().length > 0) ||
            (values.media && values.media.length > 0)
          );
        },
      ),
    onSubmit: async (values, helpers) => {
      try {
        const {type, scheduled, reminder, mentions} = values;

        const content = {
          id: v4(),
          ...values,
          thread_id: id,
          // Override owner details if official post
          owner_id: isOfficial ? 'assistant' : user_id,
        };

        if (files?.length) {
          content.media = files;
        }

        if (reminder) {
          content.reminder = scheduled - 3600;
          content.created = scheduled;
          content.updated = scheduled;
        } else {
          const now = dateToTimestamp();
          content.reminder = null;
          content.created = now;
          content.updated = now;
        }

        const {success, error} = await createThreadContent(content);

        if (success) {
          // IF SCHEDULED SEND TO SCHEDULER
          // if (status === 'scheduled' && !!scheduled) {
          //   const response = await scheduleContent(content);
          // }
          setIsOfficial(false);
          track('comment_created', {
            thread_id: id,
            content_type: values.type,
            has_media: files.length > 0,
            mentions_count: mentions.length,
            reminder: reminder,
          });
          helpers.resetForm();
          handleEditorCleanup();
          setFiles([]);
          setIsExpanded(false);
          setShowFileUploader(false);
          if (onSuccess) {
            await onSuccess();
          }

          if (mentions?.length) {
            const {success, error} = await sendPriorityThreadContent({
              emails: mentions,
              content,
              profile,
              thread: threads?.[id],
            });
          }

          // UPDATE THE SUB WITH LAST SEEN
          const sub = thread_subs?.[id] ?? null;
          if (sub) {
            const update = {
              id: sub.id,
              thread_id: id,
              last_read: dateToTimestamp(),
            };
            updateThreadSubscription(update);
          } else {
            createThreadSubscription({
              id: v4(),
              user_id,
              thread_id: id,
              status: null,
              last_read: dateToTimestamp(),
            });
          }
        }
        if (error) {
          setPopup({on: true, message: error});
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  const handleKeyDown = useCallback(
    event => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        event.preventDefault();
        formik.handleSubmit();
      }
    },
    [formik],
  );

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        createComment.current &&
        !createComment.current.contains(event.target)
      ) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (id) {
      formik.setFieldValue('thread_id', id);
    }
  }, [id]);

  return (
    <>
      <div ref={createComment} className="thread-reply-container">
        <ContentInputField
          thread_id={id}
          formik={formik}
          editor={editor}
          inputRef={inputRef}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          showFileUploader={showFileUploader}
          files={files}
          setFiles={setFiles}
          onKeyDown={handleKeyDown} // Add this prop
          isOfficial={isOfficial}
        />
        <CommentActionButtons
          formik={formik}
          editor={editor}
          files={files}
          handleSendMessage={formik.handleSubmit}
          inputMessage={formik.values.content}
          setInputMessage={input => {
            formik.setFieldValue('content', input.trim());
          }}
          copyString={copyString}
          setShowFileUploader={setShowFileUploader}
          setSchedule={setSchedule}
          setSpecialComment={setSpecialComment}
          isOfficial={isOfficial}
          setIsOfficial={setIsOfficial}
          canMakeOfficial={canMakeOfficial}
        />
      </div>
      <ScheduleContentModal
        active={schedule}
        setActive={setSchedule}
        formik={formik}
      />
      <CreateSpecialCommentModal
        active={specialComment}
        setActive={setSpecialComment}
        id={formik?.values?.thread_id}
        formik={formik}
      />
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};
export const CreateCommentHome = ({id, onSuccess}) => {
  const createComment = useRef(null);
  const inputRef = useRef(null);
  const {copyString} = useUtilities();

  const {
    state: {id: user_id, profile},
  } = useAuth();
  const {
    state: {threads, thread_subs, thread_content},
    createThreadContent,
    updateThreadSubscription,
    createThreadSubscription,
  } = useThreads();

  let user_role = threads?.[id]?.permissions.find(
    p => p.user_id === user_id,
  )?.role;
  const canMakeOfficial = user_role === 'owner' || user_role === 'admin';

  const [isExpanded, setIsExpanded] = useState(false);
  const [showFileUploader, setShowFileUploader] = useState(false);
  const [files, setFiles] = useState([]);

  const [popup, setPopup] = useState({on: false, message: ''});
  const [isOfficial, setIsOfficial] = useState(false);
  const editor = useCreateBlockNote(
    setupBlocks(
      {
        schema: schema,
        trailingBlock: false,
      },
      threads,
      thread_content,
    ),
  );
  const handleEditorCleanup = () => {
    try {
      const blocks = editor.document;

      // Then remove them all at once
      if (blocks.length > 0) {
        editor.removeBlocks(blocks);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      owner_id: user_id,
      thread_id: id,
      type: 'comment',
      status: 'active',
      priority: false,
      content: '',
      content_html: '',
      version: 'block',
      media: [],
      mentions: [],
      responses: [],
      parent: null,
      scheduled: null,
      reminder: false,
      expiration: null,
      dates: [],
      metrics: [],
      tasks: [],
      sub_type: null,
    },

    validationSchema: Yup.object()
      .shape({
        content: Yup.string(),
        media: Yup.array(),
      })
      .test(
        'content-or-media-required',
        'Either content or at least one media item is required',
        function (values) {
          return (
            (values.content && values.content.trim().length > 0) ||
            (values.media && values.media.length > 0)
          );
        },
      ),
    onSubmit: async (values, helpers) => {
      try {
        const {type, scheduled, reminder, mentions} = values;

        const content = {
          id: v4(),
          ...values,
          thread_id: id,
          // Override owner details if official post
          owner_id: isOfficial ? 'assistant' : user_id,
        };

        if (files?.length) {
          content.media = files;
        }

        if (reminder) {
          content.reminder = scheduled - 3600;
          content.created = scheduled;
          content.updated = scheduled;
        } else {
          const now = dateToTimestamp();
          content.reminder = null;
          content.created = now;
          content.updated = now;
        }

        const {success, error} = await createThreadContent(content);

        if (success) {
          // IF SCHEDULED SEND TO SCHEDULER
          // if (status === 'scheduled' && !!scheduled) {
          //   const response = await scheduleContent(content);
          // }
          setIsOfficial(false);
          track('comment_created', {
            thread_id: id,
            content_type: values.type,
            has_media: files.length > 0,
            mentions_count: mentions.length,
            reminder: reminder,
          });
          helpers.resetForm();
          handleEditorCleanup();
          setFiles([]);
          setIsExpanded(false);
          setShowFileUploader(false);
          if (onSuccess) {
            await onSuccess();
          }

          if (mentions?.length) {
            const {success, error} = await sendPriorityThreadContent({
              emails: mentions,
              content,
              profile,
              thread: threads?.[id],
            });
          }

          // UPDATE THE SUB WITH LAST SEEN
          const sub = thread_subs?.[id] ?? null;
          if (sub) {
            const update = {
              id: sub.id,
              thread_id: id,
              last_read: dateToTimestamp(),
            };
            updateThreadSubscription(update);
          } else {
            createThreadSubscription({
              id: v4(),
              user_id,
              thread_id: id,
              status: null,
              last_read: dateToTimestamp(),
            });
          }
        }
        if (error) {
          setPopup({on: true, message: error});
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  const handleKeyDown = useCallback(
    event => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        event.preventDefault();
        formik.handleSubmit();
      }
    },
    [formik],
  );

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        createComment.current &&
        !createComment.current.contains(event.target)
      ) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (id) {
      formik.setFieldValue('thread_id', id);
    }
  }, [id]);

  return (
    <>
      <div ref={createComment} className="thread-reply-container home">
        <ContentInputField
          thread_id={id}
          formik={formik}
          editor={editor}
          inputRef={inputRef}
          isExpanded={true}
          setIsExpanded={setIsExpanded}
          showFileUploader={showFileUploader}
          files={files}
          setFiles={setFiles}
          onKeyDown={handleKeyDown} // Add this prop
          isOfficial={isOfficial}
        />
        <CommentActionButtonsHome
          formik={formik}
          editor={editor}
          files={files}
          handleSendMessage={formik.handleSubmit}
          inputMessage={formik.values.content}
          setInputMessage={input => {
            formik.setFieldValue('content', input.trim());
          }}
          copyString={copyString}
          setShowFileUploader={setShowFileUploader}
          isOfficial={isOfficial}
          setIsOfficial={setIsOfficial}
          canMakeOfficial={canMakeOfficial}
        />
      </div>

      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};
