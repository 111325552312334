import {useCreateBlockNote} from '@blocknote/react';
import {useEffect, useState} from 'react';
import {v4} from 'uuid';
import {handleFilesForClaude, StreamAIMessage} from '../../../../api/ai';
import {dateToTimestamp} from '../../../../api/dates';
import prompts from '../../../../components/ai-prompts';
import {setupBlocks} from '../../../../components/blocks/divider';
import {schema} from '../../../../components/inputs/block-input';
import {useAuth} from '../../../../hooks/use-auth';
import {useThreads} from '../../../../hooks/use-threads';
import {countTokens} from '../../../../utils/utils';
import generateAIDataforCurrentThread, {
  extractTextFromBlocks,
} from '../../data/thread-data';
export const getDocumentSectionCount = editor => {
  let sectionCount = 1; // Start with 1 as there's always at least one section
  editor.forEachBlock(block => {
    if (block.type === 'divider') {
      sectionCount++;
    }
  });
  return sectionCount;
};

export const createSectionConfig = baseConfig => ({
  selectedThreads: baseConfig.selectedThreads || [],
  files: Array.isArray(baseConfig.files) ? baseConfig.files : [],
  chatHistory: Array.isArray(baseConfig.chatHistory)
    ? baseConfig.chatHistory
    : [],
  generated: baseConfig.generated || false,
  wizard_content: '',
  tokens: baseConfig.tokens || 0,
});

const initChatHistory = async ({
  config,
  threads,
  profiles,
  thread_content,
  threadAIData,
  profile,
}) => {
  try {
    const {files, selectedThreads, chatHistory = []} = config || {};
    if (
      files?.length === 0 &&
      selectedThreads?.length === 0 &&
      !threadAIData?.promptData
    ) {
      chatHistory[0] = {
        role: 'user',
        content: [
          {
            type: 'text',
            text: `Current User: Name ${profile?.first_name} ${profile?.last_name}. Job Title ${profile?.position}. Profile Bio ${profile?.bio}. No other context, files, project threads, and existing thread information provided by user`,
          },
        ],
      };

      return chatHistory;
    }

    const buildProjectContext = async () => {
      if (selectedThreads?.length === 0) return '';

      const contexts = await Promise.all(
        selectedThreads.map(async thread => {
          // For non-completed threads, generate AI data
          const threadAIData =
            thread.status !== 'complete'
              ? await generateAIDataforCurrentThread(
                  thread,
                  threads,
                  thread_content,
                  profiles,
                )
              : null;

          // For completed threads, use last post
          const lastPost =
            thread.status === 'complete'
              ? Object.values(thread_content)
                  .filter(content => content?.thread_id === thread.id)
                  .sort((a, b) => b.created - a.created)[0]
              : null;

          return `
Reference Project Thread: ${thread.title}
Status: ${thread.status.toUpperCase()}
${
  thread.status === 'complete'
    ? `Final Report:\n${
        extractTextFromBlocks(JSON.parse(lastPost?.content))?.text ||
        'No report available'
      }`
    : `Current Progress:\n${
        threadAIData?.promptData || 'No progress data available'
      }`
}`;
        }),
      );

      return contexts.join('\n\n');
    };
    //WORKING
    const fileContent = await handleFilesForClaude(files);
    //WORKING
    const projectContext = await buildProjectContext(
      selectedThreads,
      thread_content,
    );

    chatHistory[0] = {
      role: 'user',
      content: [
        ...(profile?.first_name ||
        profile?.last_name ||
        profile?.position ||
        profile?.bio
          ? [
              {
                type: 'text',
                text: `Current User: ${
                  profile?.first_name ? `Name ${profile.first_name}` : ''
                }${profile?.last_name ? ` ${profile.last_name}` : ''}${
                  profile?.position ? `. Job Title ${profile.position}` : ''
                }${profile?.bio ? `. Profile Bio ${profile.bio}` : ''}`,
              },
            ]
          : []),
        ...(fileContent?.length
          ? [
              {
                type: 'text',
                text: `Files:\n${fileContent
                  .map(f => `File Name: ${f.name}\nFile Content: ${f.text}`)
                  .join('\n\n')}`,
              },
            ]
          : []),
        ...(projectContext
          ? [
              {
                type: 'text',
                text: projectContext,
              },
            ]
          : []),
        ...(threadAIData?.promptData
          ? [
              {
                type: 'text',
                text: `Main Thread Data, this is the key information you are generating a report on:\n${threadAIData.promptData}`,
              },
            ]
          : []),
      ].filter(item => item.text.trim() !== ''),
    };
    return chatHistory;
  } catch (error) {
    console.error('Error in chat generation:', error);
    throw error;
  }
};

export const useAIAssistantState = ({thread_data}) => {
  const {
    state: {profiles, profile},
  } = useAuth();
  const {
    state: {threads, thread_content},
  } = useThreads();
  // Core states
  const [loading, setLoading] = useState(false);
  const [streaming, setStreaming] = useState(false);
  const [saving, setSaving] = useState(false);
  const [streamingContent, setStreamingContent] = useState('');
  const [threadAIData, setThreadAIData] = useState(null);

  // Editor setup
  const editor = useCreateBlockNote(
    setupBlocks({
      schema: schema,
      trailingBlock: false,
    }),
  );

  // Wizard states
  const [wizardStep, setWizardStep] = useState(1);

  // Section states
  const [activeSection, setActiveSection] = useState(0);

  //EDIT BASED ON NEW CONFIG
  const [sectionConfigs, setSectionConfigs] = useState({
    0: createSectionConfig({
      selectedThreads: [],
      files: [],
      chatHistory: [
        {
          role: 'user',
          content: [
            {
              type: 'text',
              text: `No context, files, project threads, and existing thread information provided by user`,
            },
          ],
        },
      ],
      generated: false,
      wizard_content: '',
      tokens: 0,
    }),
  });

  const {chatHistory, selectedThreads, files} =
    sectionConfigs[activeSection] || {};

  // Load thread AI data
  useEffect(() => {
    const load = async () => {
      const data = await generateAIDataforCurrentThread(
        thread_data.thread,
        threads,
        thread_content,
        profiles,
      );
      setThreadAIData(data);
    };
    if (thread_data?.thread) {
      load();
    }
  }, [thread_data, threads, thread_content, profiles]);

  // Init Chat History
  useEffect(() => {
    const initChat = async () => {
      const chatHistory = await initChatHistory({
        config: sectionConfigs[activeSection],
        threads,
        thread_content,
        profiles,
        threadAIData,
        profile,
      });
      updateSectionConfig('chatHistory', chatHistory);
      updateContextCounts(chatHistory, activeSection);
    };

    if (sectionConfigs[activeSection]) {
      initChat();
    }
  }, [threadAIData, selectedThreads, files]);

  const updateContextCounts = (history, activeSec) => {
    if (history && activeSec === activeSection) {
      let sum = 0;

      history.forEach(message => {
        const {role, content} = message;
        content?.forEach(data => {
          const {type, text} = data;
          if (type === 'text') {
            const count = countTokens(text);
            sum += count;
          }
        });
      });
      updateSectionConfig('tokens', sum);
    }
  };
  const updateSectionConfig = (key, value) => {
    setSectionConfigs(prev => ({
      ...prev,
      [activeSection]: {
        ...prev[activeSection],
        [key]: value,
      },
    }));
  };

  const addSectionConfig = insertAfterIndex => {
    setSectionConfigs(prev => {
      // Get the configuration to duplicate (either the previous section or the last section)
      const configToDuplicate =
        prev[insertAfterIndex] || prev[Object.keys(prev).length - 1];

      // Create new config based on the previous one
      const newConfig = createSectionConfig({
        selectedThreads: [...configToDuplicate.selectedThreads],
        files: [...configToDuplicate.files],
        chatHistory: [configToDuplicate.chatHistory[0]], // Only keep the initial context message
        generated: configToDuplicate.generated,
        wizard_content: configToDuplicate.wizard_content,
      });

      // Shift all section indices after the insertion point
      const updatedConfigs = {};
      Object.entries(prev).forEach(([index, config]) => {
        const numIndex = Number(index);
        if (numIndex <= insertAfterIndex) {
          updatedConfigs[numIndex] = config;
        } else {
          updatedConfigs[numIndex + 1] = config;
        }
      });

      // Insert new config
      updatedConfigs[insertAfterIndex + 1] = newConfig;

      return updatedConfigs;
    });
  };

  const removeSectionConfig = removeIndex => {
    setSectionConfigs(prev => {
      // Can't remove if it's the only section
      if (Object.keys(prev).length <= 1) return prev;

      const updatedConfigs = {};
      Object.entries(prev).forEach(([index, config]) => {
        const numIndex = Number(index);
        if (numIndex < removeIndex) {
          updatedConfigs[numIndex] = config;
        } else if (numIndex > removeIndex) {
          updatedConfigs[numIndex - 1] = config;
        }
      });

      return updatedConfigs;
    });

    // Update activeSection if needed
    setActiveSection(current => {
      if (current === removeIndex) {
        return Math.max(0, current - 1);
      }
      if (current > removeIndex) {
        return current - 1;
      }
      return current;
    });
  };
  // Function to sync section configs with document structure
  const syncSectionConfigs = currentConfigs => {
    const documentSectionCount = getDocumentSectionCount(editor);
    const configSectionCount = Object.keys(currentConfigs).length;

    if (documentSectionCount > configSectionCount) {
      // Add missing configs
      for (let i = configSectionCount; i < documentSectionCount; i++) {
        addSectionConfig(i - 1);
      }
    } else if (documentSectionCount < configSectionCount) {
      // Remove extra configs
      for (let i = configSectionCount - 1; i >= documentSectionCount; i--) {
        removeSectionConfig(i);
      }
    }
  };

  const resetSystem = () => {
    // Reset core states
    setLoading(false);
    setStreaming(false);
    setSaving(false);
    setStreamingContent('');
    setThreadAIData(null);

    // Reset wizard state
    setWizardStep(1);

    // Reset active section
    setActiveSection(0);

    // Reset section configs to initial state
    setSectionConfigs({
      0: createSectionConfig({
        selectedThreads: [],
        files: [],
        chatHistory: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: `No context, files, project threads, and existing thread information provided by user`,
              },
            ],
          },
        ],
        generated: false,
        wizard_content: '',
        tokens: 0,
      }),
    });
    let allBlocks = editor.document.flatMap(x => x.id);
    console.log(allBlocks);
    // Reset the editor state
    editor.removeBlocks(allBlocks);
  };

  useEffect(() => {
    if (!editor || streaming) return;

    const syncSectionsWithCurrentConfig = () => {
      syncSectionConfigs(sectionConfigs);
    };

    const unsubscribe = editor.onChange(syncSectionsWithCurrentConfig);
    return () => unsubscribe();
  }, [editor, streaming, sectionConfigs]);

  return {
    editor,
    loading,
    resetSystem,
    setLoading,
    saving,
    streaming,
    setStreaming,
    setSaving,
    streamingContent,
    setStreamingContent,
    wizardStep,
    setWizardStep,
    sectionConfigs,
    setSectionConfigs,
    threadAIData,
    activeSection,
    setActiveSection,
    updateSectionConfig,
    syncSectionConfigs,
  };
};

export const useContentGeneration = ({
  setLoading,
  setStreaming,
  setStreamingContent,
}) => {
  const generateInitialDocument = async ({
    chatHistory,
    wizard_content,
    currentTemplate,
    profile,
  }) => {
    setLoading(true);
    setStreamingContent('');

    try {
      let documentTemplate = prompts[currentTemplate?.value];
      const messages = [
        ...chatHistory,
        {
          role: 'user',
          content: [
            {
              type: 'text',
              text: `Document Configuration and User Information:
                    Template: ${documentTemplate || 'Standard'}
                    Current User: ${profile?.first_name} ${
                profile?.last_name
              } (${profile?.position}) \n\n 
                    Current User Background:\n${
                      profile?.bio || 'None given.'
                    } \n Extra Project Information from User: ${
                wizard_content || 'No extra information'
              }`,
            },
          ].filter(Boolean),
        },
      ];
      await StreamAIMessage({
        messages,
        system: prompts.base_system,
        onUpdate: message => {
          setStreamingContent(prev => prev + message);
          setLoading(false);
          setStreaming(true);
        },
        onComplete: response => {
          setStreaming(false);
        },
      });
    } catch (error) {
      console.error('Error generating content:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const generateChatResponse = async ({
    updateSectionConfig,
    currentConfig,
    chatHistory,
    editor,
    activeSection,
  }) => {
    setLoading(true);
    setStreamingContent('');

    try {
      let currentSectionContent = [];
      let sectionIndex = 0;

      editor?.document.forEach(block => {
        if (sectionIndex === activeSection) {
          currentSectionContent.push(block);
        }
        if (block.type === 'divider') {
          sectionIndex++;
        }
      });

      const currentSectionText = extractTextFromBlocks(
        currentSectionContent,
      )?.text;
      const fullDocumentText = extractTextFromBlocks(editor?.document)?.text;

      const messages = [
        ...chatHistory,
        {
          role: 'user',
          content: [
            {
              type: 'text',
              text: `Current Section:\n${
                currentSectionText || 'No current section.'
              }\n\nEntire Document:\n${
                fullDocumentText || 'No current document.'
              }`,
            },
          ],
        },
      ];
      console.log('chat messages', messages);
      await StreamAIMessage({
        messages,
        system: currentConfig?.generated
          ? prompts.base_system_document_creator_chat
          : prompts.base_system_document_creator_no_doc_chat,
        onUpdate: message => {
          setStreamingContent(prev => prev + message);
          setLoading(false);
          setStreaming(true);
        },
        onComplete: response => {
          let newChatHistory = [
            ...chatHistory,
            {
              role: 'assistant',
              content: [
                {
                  type: 'text',
                  text: response,
                },
              ],
            },
          ];
          updateSectionConfig('chatHistory', newChatHistory);
          updateSectionConfig('generated', true);

          setStreaming(false);
        },
      });
    } catch (error) {
      console.error('Error in chat generation:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  return {generateInitialDocument, generateChatResponse};
};

export const useSaveContent = ({editor, setSaving}) => {
  const saveContent = async ({
    user_id,
    thread_data,
    createThread,
    createThreadContent,
    updateThread,
    onSuccess,
  }) => {
    if (!editor) return;
    const {thread, toComplete} = thread_data || {};
    setSaving(true);
    try {
      const whereToSave = toComplete ? thread?.id : v4();
      const now = dateToTimestamp();
      const content = JSON.stringify(editor.document);
      const content_html = await editor.blocksToHTMLLossy();

      // Extract mentions
      const mentions = [];
      editor.forEachBlock(block => {
        const blockContent = block.content;
        if (!Array.isArray(blockContent)) return;

        blockContent.forEach(item => {
          const {type, props} = item;
          if (type === 'mention' && !mentions.includes(props.email)) {
            mentions.push(props.email);
          }
        });
      });

      if (!toComplete) {
        let title = '';

        editor.forEachBlock(block => {
          if (!title && block.type === 'heading') {
            title = block.content?.[0].text;
            return;
          }
        });
        if (!title) {
          title = await editor.blocksToMarkdownLossy(editor.document);
          title = title.split('\n')[0];
        }

        const thread = {
          id: whereToSave,
          owner_id: user_id,
          title,
          status: 'draft',
          type: 'thread',
          security_level: 'private',
          permissions: [{user_id, role: 'owner', created: now, updated: now}],
          members: [user_id],
          created: now,
          updated: now,
        };

        const {success} = await createThread(thread);

        if (success) {
          await createThreadContent({
            id: v4(),
            owner_id: 'assistant',
            thread_id: whereToSave,
            type: 'comment',
            status: 'active',
            priority: false,
            content,
            content_html,
            version: 'block',
            created: now,
            updated: now,
          });
          onSuccess?.(whereToSave);
        }
      } else {
        const {success} = await updateThread({
          id: whereToSave,
          status: 'complete',
        });

        if (success) {
          await createThreadContent({
            id: v4(),
            owner_id: 'assistant',
            thread_id: whereToSave,
            type: 'comment',
            status: 'active',
            priority: false,
            content,
            content_html,
            version: 'block',
            created: now,
            updated: now,
          });
        }
        onSuccess?.(null);
      }
    } catch (error) {
      console.error('Error saving thread:', error);
      throw error;
    } finally {
      setSaving(false);
    }
  };
  const saveContentToExisting = async ({
    thread_id,
    createThreadContent,
    onSuccess,
  }) => {
    if (!editor) return;

    setSaving(true);
    try {
      const now = dateToTimestamp();
      const content = JSON.stringify(editor.document);
      const content_html = await editor.blocksToHTMLLossy();

      // Extract mentions
      const mentions = [];
      editor.forEachBlock(block => {
        const blockContent = block.content;
        if (!Array.isArray(blockContent)) return;

        blockContent.forEach(item => {
          const {type, props} = item;
          if (type === 'mention' && !mentions.includes(props.email)) {
            mentions.push(props.email);
          }
        });
      });

      await createThreadContent({
        id: v4(),
        owner_id: 'assistant',
        thread_id: thread_id,
        type: 'comment',
        status: 'active',
        priority: false,
        content,
        content_html,
        version: 'block',
        created: now,
        updated: now,
      });

      onSuccess?.();
    } catch (error) {
      console.error('Error saving thread:', error);
      throw error;
    } finally {
      setSaving(false);
    }
  };
  return {saveContent, saveContentToExisting};
};
