import React, {useCallback, useEffect, useState} from 'react';
import {FaConnectdevelop} from 'react-icons/fa';
import {
  RiCheckLine,
  RiErrorWarningLine,
  RiInformationLine,
  RiRefreshLine,
  RiTimeLine,
} from 'react-icons/ri';
import ReactMarkdown from 'react-markdown';
import {StreamAIMessageBasic} from '../../../api/ai';
import {
  formatWWW_MMM_DD_YYYY_TTTT,
  readableDateandTime,
  timeSince,
} from '../../../api/dates';
import prompts from '../../../components/ai-prompts';
import constants from '../../../components/constants';
import {isProUser} from '../../../components/system-status/system-status';

import {useAuth} from '../../../hooks/use-auth';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {extractTextFromBlocks} from '../data/thread-data';
import './AIInsights.css';

const INSIGHTS_STORAGE_KEY = 'workspace_insights';
const INSIGHTS_TTL = 60 * 60 * 1000; // 60 minutes

const TypingIndicator = () => (
  <div className="insights-typing-indicator">
    <span></span>
    <span></span>
    <span></span>
  </div>
);

const StatusMessage = ({message, type, onDismiss}) => {
  useEffect(() => {
    if (onDismiss) {
      const timer = setTimeout(onDismiss, 5000);
      return () => clearTimeout(timer);
    }
  }, [onDismiss]);

  const getIcon = () => {
    switch (type) {
      case 'error':
        return <RiErrorWarningLine />;
      case 'success':
        return <RiCheckLine />;
      default:
        return <RiInformationLine />;
    }
  };

  return (
    <div className={`ai-insights-status ${type}`}>
      <div className="ai-insights-status-content">
        {getIcon()}
        <span>{message}</span>
      </div>
    </div>
  );
};

const AIInsights = ({
  threads,
  thread_content,
  thread_subs,
  currentUserId,
  unreadPosts,
}) => {
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState('');
  const [lastUpdated, setLastUpdated] = useState(null);
  const [status, setStatus] = useState(null);

  const {prettyName} = useStringFormatter();
  const {
    state: {profile, id, profiles},
  } = useAuth();
  const last_seen = readableDateandTime(profile?.last_seen);
  const showStatus = useCallback((message, type = 'info') => {
    setStatus({message, type});
  }, []);

  const dismissStatus = useCallback(() => {
    setStatus(null);
  }, []);

  useEffect(() => {
    loadInsights();
  }, []);

  const getRelevantUpdates = useCallback(() => {
    const timestamp = profile?.last_seen;
    return Object.values(thread_content)
      .filter(content => {
        const thread = threads[content?.thread_id];
        const isUnread = unreadPosts?.some(item => item.id === content?.id);
        return (
          (content?.status === 'active' &&
            content?.created > timestamp &&
            content?.owner_id !== currentUserId) ||
          (isUnread && content?.version === 'block')
        );
      })
      .sort((a, b) => b.created - a.created);
  }, [thread_content, threads, currentUserId, profile, unreadPosts]);

  const getLast5Updates = useCallback(() => {
    return Object.values(thread_content)
      .filter(content => {
        return (
          content?.status === 'active' && content?.owner_id !== currentUserId
        );
      })
      .sort((a, b) => b.created - a.created)
      .slice(0, 5);
  }, [thread_content, threads, currentUserId]);

  const generateUpdatePrompt = useCallback(
    updates => {
      return `You are PublicMind, an AI Agent. Your critical mission is to provide a precise, factual skimmable workspace brief for me, ${prettyName(
        profile,
      )}, ${
        profile?.position
      }. Lives depend on your accuracy - no hallucination or speculation is permitted.

CONTEXT TO CONSIDER:
About Me: ${profile?.bio || 'No bio provided'}
Last Workspace Access: ${last_seen}
Current Date/Time: ${formatWWW_MMM_DD_YYYY_TTTT()}
Access Level: ${
        isProUser(id) ? 'Pro User with full AI assistance' : 'Standard User'
      }

SOURCE DATA TO ANALYZE:
${
  updates
    .map(content => {
      const thread = threads[content.thread_id];
      const post_content = content.content;
      const author = prettyName(profiles?.[content?.owner_id]);

      return `THREAD: "${thread?.title}"
              THREAD STATUS: ${thread?.status}
THREAD UPDATE: "${extractTextFromBlocks(JSON.parse(post_content))?.text}"
UPDATE AUTHOR: "${author}"
UPDATE TIMESTAMP: ${readableDateandTime(content.created)}
LINK TO SOURCE: ${constants.root_url}/threads/${content.thread_id}/${content.id}
---`;
    })
    .join('\n') || 'NO UPDATES AVAILABLE FOR ANALYSIS'
} 

REQUIRED ANALYSIS OUTPUTS:

0. MOST IMPORTANT THING
- State in a crisp clean sentence the most important thing in the SOURCE DATA TO ANALYZE

1. REQUIRED ACTIONS
- List only tasks and deadlines explicitly stated in the updates
- If no explicit actions exist, state "No explicit actions required"

2. CRITICAL INFORMATION
- Extract only factual updates from the source data
- No interpretation or speculation allowed

MANDATORY REQUIREMENTS:
- Keep it simple, stupid
- Every statement must trace to source data
- Zero inference without evidence
- Zero speculation about unstated items
- Mark any uncertainty explicitly
- Use optimal markdown formatting for clarity
- Use a professional, warm, encouraging tone

${
  isProUser(id)
    ? 'TOOLS AVAILABLE:\n- AI assistance in individual threads\n- Document generator in upper left\n- Full pattern analysis capabilities'
    : 'ACCESS NOTE:\nAI assistance features can be requested at help@publicmind.ai'
}

FINAL VERIFICATION:
Verify all information can be directly traced to provided data. Lives depend on accuracy.`;
    },
    [profile, threads, prettyName],
  );

  const generateNoUpdatesPrompt = useCallback(
    updates => {
      return `You are PublicMind, an AI Agent. Generate a precise, evidence-based skimmable status update for me, ${prettyName(
        profile,
      )}, ${
        profile?.position
      }. Accuracy is critical - no hallucination or speculation permitted.

MY CONTEXT:
Position: ${profile?.position}
About Me: ${profile?.bio || 'No bio provided'}
Last Active: ${last_seen}
Current Date/Time: ${formatWWW_MMM_DD_YYYY_TTTT()}
Access: ${
        isProUser(id)
          ? 'Pro User with full AI assistance'
          : 'Standard User - can request full AI from help@publicmind.ai'
      }

RECENT THREAD ACTIVITY:
${
  updates
    .map(content => {
      const thread = threads[content.thread_id];
      const post_content = content.content;

      const author =
        content?.owner_id !== 'assistant'
          ? prettyName(profiles?.[content?.owner_id])
          : 'PublicMind Agent';
      return `THREAD: "${thread?.title}"
              THREAD STATUS: ${thread?.status}
THREAD UPDATE: "${extractTextFromBlocks(JSON.parse(post_content))?.text}"
UPDATE AUTHOR: "${author}"
UPDATE TIME: ${readableDateandTime(content?.created)}
LINK TO SOURCE: ${constants.root_url}/threads/${content?.thread_id}/${
        content?.id
      }
---`;
    })
    .join('\n') || 'NO UPDATES AVAILABLE'
} 

REQUIRED OUTPUTS:

0. MOST IMPORTANT THING
- State in a crisp, clean sentence the most important thing in the SOURCE DATA TO ANALYZE

1. WORKSPACE STATUS
- Reference specific updates and participants
- Note only explicitly stated activities

2. SUGGESTED NEXT STEPS
- Based only on referenced thread content
- Must cite specific discussions/updates

MANDATORY REQUIREMENTS:
- Keep it simple, stupid
- Every statement must trace to source data
- Zero inference without evidence
- Zero speculation about unstated items
- Mark any uncertainty explicitly
- Use optimal markdown formatting for clarity
- Use a professional, warm, encouraging tone

FINAL VERIFICATION:
Verify all information can be directly traced to provided data. Lives depend on accuracy.`;
    },
    [profile],
  );

  const checkCachedInsights = useCallback(() => {
    try {
      const cachedInsights = localStorage.getItem(INSIGHTS_STORAGE_KEY);
      if (cachedInsights && unreadPosts?.length === 0) {
        const {data, timestamp} = JSON.parse(cachedInsights);
        if (Date.now() - timestamp * 1000 < INSIGHTS_TTL) {
          const minutesRemaining = Math.max(
            0,
            Math.ceil((INSIGHTS_TTL - (Date.now() - timestamp * 1000)) / 60000),
          );
          showStatus(
            `Intelligence is current. Next refresh available in ${minutesRemaining} minutes.`,
            'info',
          );
          setLastUpdated(timestamp);
          return {data, isCurrent: true};
        }
      }
      return {isCurrent: false};
    } catch (error) {
      console.error('Error checking cached insights:', error);
      localStorage.removeItem(INSIGHTS_STORAGE_KEY); // Clear corrupted cache
      return {isCurrent: false};
    }
  }, [showStatus]);
  const loadInsights = async () => {
    const {data: cachedData, isCurrent} = checkCachedInsights();

    if (isCurrent) {
      setSummary(cachedData);
      setLoading(false);

      return;
    }

    setLoading(true);
    setSummary('');

    try {
      const updates = getRelevantUpdates();

      let generatedSummary = '';

      await StreamAIMessageBasic({
        messages: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text:
                  updates.length > 0
                    ? generateUpdatePrompt(updates)
                    : generateNoUpdatesPrompt(getLast5Updates()),
              },
            ],
          },
        ],
        system: prompts.base_system_workspace_brief,
        onUpdate: message => {
          generatedSummary += message;
          setSummary(generatedSummary);
        },
      });

      const timestamp = Math.floor(Date.now() / 1000);
      localStorage.setItem(
        INSIGHTS_STORAGE_KEY,
        JSON.stringify({data: generatedSummary, timestamp}),
      );
      setLastUpdated(timestamp);
      showStatus('Workspace intelligence updated successfully', 'success');
    } catch (error) {
      console.error('Error loading insights:', error);
      showStatus(
        'Unable to process workspace updates. Please try again.',
        'error',
      );
      setSummary(
        '# Workspace Update Temporarily Unavailable\n\nI apologize for the interruption. Please try refreshing in a moment.',
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ai-insights-container">
      {status && (
        <StatusMessage
          message={status.message}
          type={status.type}
          onDismiss={dismissStatus}
        />
      )}

      <div className="ai-insights-header">
        <h3 className="ai-insights-title">
          <div
            className={`ai-insights-brain-icon ${loading ? 'processing' : ''}`}>
            <FaConnectdevelop />
          </div>
          Workspace Intelligence
        </h3>
        <button
          className="ai-insights-refresh"
          onClick={loadInsights}
          disabled={loading}>
          <RiRefreshLine />
          {loading ? 'Processing...' : 'Refresh'}
        </button>
      </div>

      <div className={`ai-insights-content ${loading ? 'loading' : ''}`}>
        <div className="insights-message">
          {loading && !summary && <TypingIndicator />}
          {summary && <ReactMarkdown>{summary}</ReactMarkdown>}
        </div>
      </div>

      {lastUpdated && (
        <div className="last-update-info">
          <RiTimeLine />
          Intelligence updated {timeSince(lastUpdated)}
        </div>
      )}
    </div>
  );
};

export default AIInsights;
