import React, {useEffect, useState} from 'react';
import {BsActivity} from 'react-icons/bs';
import {FaRegSquarePlus} from 'react-icons/fa6';
import {
  RxCheck,
  RxChevronRight,
  RxClock,
  RxCross1,
  RxFile,
} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {isWithinLast30Days, timeSince} from '../../../api/dates';
import {isProUser} from '../../../components/system-status/system-status';
import {useAuth} from '../../../hooks/use-auth';
import useCollaborator from '../../../hooks/use-collaborator';
import {useThreads} from '../../../hooks/use-threads';
import {CreateCommentHome} from '../inputs/create-comment-inputs';
import {AssistantModal} from '../modals/assistant-modal';
import AIInsights from './AIInsightsHome';
import './WorkLogger.css';

const ThreadCard = ({thread, latestContent, onSelect, onCreate}) => {
  const navigate = useNavigate();
  const {
    state: {id: userId},
  } = useAuth();

  const getStatusDetails = () => {
    switch (thread?.status) {
      case 'draft':
        return {
          icon: <RxFile />,
          text: 'Draft',
        };
      case 'active':
        return {
          icon: <BsActivity />,
          text: 'Active',
        };
      case 'completed':
        return {
          icon: <RxCheck />,
          text: 'Completed',
        };
      default:
        return {
          icon: null,
          text: '',
        };
    }
  };

  const {icon, text} = getStatusDetails();

  const handleQuickUpdate = e => {
    e.stopPropagation();
    onSelect(thread);
  };

  const handleCreate = e => {
    e.stopPropagation();
    onCreate(thread);
  };

  const handleViewThread = e => {
    e.stopPropagation();
    navigate(`/threads/${thread?.id}`);
  };

  return (
    <div className="thread-select-card">
      <div className="thread-select-header">
        {thread?.logo && (
          <div className="thread-select-icon">{thread.logo}</div>
        )}
        <h3 className="thread-select-title">{thread.title}</h3>
      </div>
      <div className="thread-select-header">
        <div className={`thread-select-status ${thread?.status}`}>
          {icon}
          <span>{text}</span>
        </div>
        {latestContent && (
          <div className="thread-select-activity">
            <RxClock />
            <span>Updated {timeSince(latestContent.created)}</span>
          </div>
        )}
      </div>
      <div className="thread-select-meta">
        <div className="thread-select-actions">
          {isProUser(userId) && (
            <button
              className="thread-select-action"
              onClick={handleCreate}
              aria-label="Quick update">
              <RxFile />
              <span>Create</span>
            </button>
          )}
          <button
            className="thread-select-action"
            onClick={handleQuickUpdate}
            aria-label="Quick update">
            <FaRegSquarePlus />
            <span>Update</span>
          </button>
          <button
            className="thread-select-action"
            onClick={handleViewThread}
            aria-label="View thread details">
            <RxChevronRight />
            <span>View</span>
          </button>
        </div>
      </div>
    </div>
  );
};

const WorkLogger = ({unreadPosts}) => {
  const navigate = useNavigate();
  const {threadActions} = useCollaborator();
  const [selectedThread, setSelectedThread] = useState(null);
  const [activeThreads, setActiveThreads] = useState([]);
  const [composerVisible, setComposerVisible] = useState(false);
  const [assistantActive, setAssistantActive] = useState(false);

  const {
    state: {threads, thread_ids, thread_content, thread_subs},
  } = useThreads();

  const {
    state: {id: userId},
  } = useAuth();

  const latestContentMap = React.useMemo(() => {
    return Object.values(thread_content).reduce((acc, content) => {
      if (
        !acc[content?.thread_id] ||
        content.created > acc[content?.thread_id].created
      ) {
        acc[content?.thread_id] = content;
      }
      return acc;
    }, {});
  }, [thread_content]);

  useEffect(() => {
    const filteredThreads = thread_ids
      .map(id => threads[id])
      .filter(thread => {
        if (!thread) return false;
        const {status, type} = thread;
        const {writeable} = threadActions(thread);
        return writeable && status !== 'complete' && type !== 'direct';
      })
      .sort((a, b) => b.updated - a.updated);

    setActiveThreads(filteredThreads);
  }, [threads, thread_ids]);

  const handleThreadSelect = thread => {
    setSelectedThread(thread);
    setComposerVisible(true);
  };

  const handleCreateDocument = thread => {
    setSelectedThread(thread);
    setAssistantActive(true);
  };

  const handleUpdateSuccess = () => {
    setComposerVisible(false);
    setTimeout(() => setSelectedThread(null), 300);
  };

  const recentThreads = activeThreads.slice(0, 3);
  const monthlyThreads = activeThreads.filter(
    thread =>
      isWithinLast30Days(thread.updated) && !recentThreads.includes(thread),
  );

  return (
    <div className="work-logger">
      <div className="work-logger-content">
        <AIInsights
          threads={threads}
          thread_content={thread_content}
          thread_subs={thread_subs}
          currentUserId={userId}
          unreadPosts={unreadPosts}
        />

        <div className="work-logger-header">
          <h2>Recent</h2>
          <p>3 Most Recent Threads</p>
        </div>
        <div className="thread-quick-select">
          {recentThreads.map(thread => (
            <ThreadCard
              key={thread.id}
              thread={thread}
              latestContent={latestContentMap[thread.id]}
              onSelect={handleThreadSelect}
              onCreate={handleCreateDocument}
            />
          ))}
        </div>

        <div className="work-logger-header padding-top8">
          <h2>Other Threads</h2>
          <p>Last 30 Days</p>
        </div>
        <div className="thread-quick-select">
          {monthlyThreads.map(thread => (
            <ThreadCard
              key={thread.id}
              thread={thread}
              latestContent={latestContentMap[thread.id]}
              onSelect={handleThreadSelect}
              onCreate={handleCreateDocument}
            />
          ))}
        </div>
      </div>

      <div className={`composer-overlay ${composerVisible ? 'visible' : ''}`}>
        <div className="composer-container">
          <div className="composer-header">
            <div className="composer-thread-info">
              <p className="text-secondary">Posting To: </p>
              {selectedThread?.status === 'draft' ? (
                <RxFile />
              ) : selectedThread?.status === 'active' ? (
                <BsActivity />
              ) : (
                <RxCheck />
              )}
              <span className="composer-thread-icon">
                {selectedThread?.logo}
              </span>
              <span className="composer-thread-title">
                {selectedThread?.title}
              </span>
              <button
                onClick={() => navigate(`/threads/${selectedThread?.id}`)}
                className="composer-back">
                <RxChevronRight />
              </button>
            </div>
            <button
              className="composer-back"
              onClick={() => setComposerVisible(false)}>
              <RxCross1 />
            </button>
          </div>

          {selectedThread && (
            <CreateCommentHome
              id={selectedThread.id}
              onSuccess={handleUpdateSuccess}
            />
          )}
        </div>
      </div>

      {selectedThread && (
        <AssistantModal
          active={assistantActive}
          setActive={setAssistantActive}
          thread_data={{thread: selectedThread, toComplete: false}}
        />
      )}
    </div>
  );
};

export default WorkLogger;
