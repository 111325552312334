import {useCreateBlockNote} from '@blocknote/react';
import {useFormik} from 'formik';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {RxCross2} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {v4} from 'uuid';
import * as Yup from 'yup';
import {track} from '../../../api/analytics';
import {dateToTimestamp, timeSince} from '../../../api/dates';
import {sendPriorityThreadContent} from '../../../api/email';
import ActionPopup from '../../../components/action-feedback-popup/action-feedback-popup';
import {BadgeDotRelative} from '../../../components/badge/badge';
import {setupBlocks} from '../../../components/blocks/divider';
import {schema} from '../../../components/inputs/block-input';
import {Textfield} from '../../../components/inputs/textfields';
import {ProfileImage} from '../../../components/inputs/uploader';
import {useAuth} from '../../../hooks/use-auth';
import useCrypto from '../../../hooks/use-crypto';
import useLoader from '../../../hooks/use-loader';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import ThreadMessageGroup from '../components/thread-message-group';
import {DMInputField} from '../inputs/dm-input-field';
import './direct-messages.css';

export const DirectMessages = ({active, setActive, directID = null}) => {
  const [current, setCurrent] = useState(directID);

  useEffect(() => {
    setCurrent(directID);
  }, [directID]);

  if (!active) {
    return null;
  }
  return (
    <>
      <DMList current={current} setCurrent={setCurrent} setActive={setActive} />
      <DMDetail id={current} setCurrent={setCurrent} />
    </>
  );
};

export const DMDetail = ({id, setCurrent}) => {
  const {prettyName} = useStringFormatter();
  const {encryptString} = useCrypto();
  const navigate = useNavigate();

  const {
    state: {id: user_id, profile, profiles, organizations},
  } = useAuth();
  const {
    state: {
      threads,
      thread_content,
      thread_subs,
      thread_subs_loaded,
      content_ids,
    },
    createThreadContent,
    updateThreadSubscription,
    createThreadSubscription,
  } = useThreads();

  const [popup, setPopup] = useState({on: false, message: ''});
  const [otherActive, setActive] = useState(false);

  const thread = threads?.[id] ?? {};
  const sub = thread_subs?.[id] ?? null;
  const {members, last_content, last_sender, updated} = thread;
  const other = members?.find(member => member !== user_id);
  const other_profile = profiles?.[other] ?? {email: other};
  const {
    profile_image,
    id: other_profile_id,
    organization_ids,
    position,
  } = other_profile;
  const current_org = organization_ids?.length
    ? organizations?.[organization_ids[0]]
    : null;

  const title = (
    <span className="flex-column">
      <span className="flex-row">
        <h3 className="user-name">{prettyName(other_profile)}</h3>
        <span className="user-activity-status">
          <span
            className={`activity-dot ${otherActive ? 'green' : 'grey'}`}></span>
          <span className="activity-text">
            {otherActive ? 'Active' : 'Inactive'}
          </span>
        </span>
      </span>
      <div className="flex-row align-center">
        {position && (
          <p className="content-org">
            {position}
            {current_org?.name ? `, ${current_org?.name}` : null}
          </p>
        )}
      </div>
    </span>
  );

  const editor = useCreateBlockNote(
    setupBlocks(
      {
        schema: schema,
        trailingBlock: false,
      },
      threads,
      thread_content,
    ),
  );

  const handleEditorCleanup = () => {
    try {
      const blocks = editor.document;

      // Then remove them all at once
      if (blocks.length > 0) {
        editor.removeBlocks(blocks);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let active = false;
    const message_ids = content_ids?.[id] ?? [];
    Object.keys(message_ids).forEach(m_id => {
      const content = thread_content?.[m_id] ?? {};
      const {owner_id, updated} = content;
      if (owner_id !== user_id && updated > dateToTimestamp() - 3600) {
        active = true;
      }
    });

    setActive(active);
  }, [content_ids?.[id]]);

  const formik = useFormik({
    initialValues: {
      owner_id: user_id,
      thread_id: id,
      type: 'comment',
      status: 'active',
      priority: false,
      content: '',
      content_html: '',
      version: 'block',
      media: [],
      mentions: [],
      responses: [],
      parent: null,
      scheduled: null,
      reminder: false,
      expiration: null,
      dates: [],
      metrics: [],
      tasks: [],
      sub_type: null,
    },

    validationSchema: Yup.object()
      .shape({
        content: Yup.string(),
        media: Yup.array(),
      })
      .test(
        'content-or-media-required',
        'Either content or at least one media item is required',
        function (values) {
          return (
            (values.content && values.content.trim().length > 0) ||
            (values.media && values.media.length > 0)
          );
        },
      ),
    onSubmit: async (values, helpers) => {
      try {
        const {type, scheduled, reminder, mentions} = values;

        const content = {
          id: v4(),
          ...values,
          thread_id: id,
        };

        // if (files?.length) {
        //   content.media = files;
        // }

        if (reminder) {
          content.reminder = scheduled - 3600;
          content.created = scheduled;
          content.updated = scheduled;
        } else {
          const now = dateToTimestamp();
          content.reminder = null;
          content.created = now;
          content.updated = now;
        }

        const {success, error} = await createThreadContent(content);

        if (success) {
          // IF SCHEDULED SEND TO SCHEDULER
          // if (status === 'scheduled' && !!scheduled) {
          //   const response = await scheduleContent(content);
          // }
          track('comment_created', {
            thread_id: id,
            content_type: values.type,
            // has_media: files.length > 0,
            mentions_count: mentions.length,
            reminder: reminder,
          });
          helpers.resetForm();
          handleEditorCleanup();
          // setFiles([]);

          if (mentions?.length) {
            const {success, error} = await sendPriorityThreadContent({
              emails: mentions,
              content,
              profile,
              thread: threads?.[id],
            });
          }

          // UPDATE THE SUB WITH LAST SEEN
          const sub = thread_subs?.[id] ?? null;
          if (sub) {
            const update = {
              id: sub.id,
              thread_id: id,
              last_read: dateToTimestamp(),
            };
            updateThreadSubscription(update);
          } else {
            createThreadSubscription({
              id: v4(),
              user_id,
              thread_id: id,
              status: null,
              last_read: dateToTimestamp(),
            });
          }
        }
        if (error) {
          setPopup({on: true, message: error});
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!id || !thread_subs_loaded) {
      return;
    }

    const new_content = !sub?.last_read || sub?.last_read < updated;

    // IF NOT ACTIVE OR NO NEW CONTENT TO HAVE SEEN IGNORE
    if (!new_content) {
      return;
    }

    if (sub) {
      const update = {
        id: sub.id,
        thread_id: id,
        last_read: dateToTimestamp(),
      };
      updateThreadSubscription(update);
    } else {
      const created = {
        id: v4(),
        user_id,
        thread_id: id,
        status: null,
        last_read: dateToTimestamp(),
      };
      createThreadSubscription(created);
    }
  }, [thread_subs_loaded, id]);

  return (
    <div className={`direct-msg-chat ${!!id ? 'direct-msg-show' : ''}`}>
      <div className="direct-msg-chat-header">
        <div className="flex-row align-center justify-between">
          <div className="flex-row align-center">
            <ProfileImage
              data={profile_image}
              style={{height: '32px', width: '32px'}}
              onClick={() => {
                const parsed = encryptString(other_profile_id);
                navigate(`/feed/profiles/detail/${parsed}`);
              }}
            />
            <h3 className="padding-left8">{title}</h3>
          </div>

          <button
            className="direct-msg-close-button"
            onClick={() => setCurrent(null)}>
            <RxCross2 />
          </button>
        </div>
      </div>
      <ChatMessages id={id} />
      <div className="direct-msg-input-container">
        <div className="direct-msg-input-wrapper">
          <DMInputField
            formik={formik}
            editor={editor}
            thread_id={id}
            files={[]}
            setFiles={() => {}}
            onKeyDown={() => {}}
          />
        </div>
      </div>
      <div className="direct-msg-button-group">
        <div className="direct-msg-button-left">
          <button
            className="direct-msg-button direct-msg-button-primary"
            onClick={formik.handleSubmit}
            disabled={
              formik.isSubmitting || !editor?.document?.[0].content?.length
            }>
            Send
          </button>
        </div>
        <div className="direct-msg-button-right">
          <button
            className="direct-msg-button direct-msg-button-secondary"
            onClick={handleEditorCleanup}>
            Clear
          </button>
        </div>
      </div>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </div>
  );
};

const DMItem = ({id, setCurrent, active}) => {
  const {loadProfiles} = useLoader();
  const {prettyName} = useStringFormatter();
  const {encryptString} = useCrypto();
  const navigate = useNavigate();
  const {
    state: {id: user_id, profiles},
  } = useAuth();
  const {
    state: {threads, thread_subs, content_ids, thread_content},
  } = useThreads();

  const [otherActive, setActive] = useState(false);

  const sub = thread_subs?.[id] ?? {};
  const thread = threads[id] ?? {};
  const {members, last_content, last_sender, updated} = thread;
  const other = members?.find(member => member !== user_id);
  const other_profile = profiles?.[other] ?? {email: other};
  const pretty = prettyName(other_profile);
  const {profile_image, id: other_profile_id} = other_profile;

  const unread =
    user_id !== thread.last_sender &&
    (!sub?.last_read || sub?.last_read < thread.updated);

  const pretty_date = timeSince(updated);

  useEffect(() => {
    if (other) {
      loadProfiles([other]);
    }
  }, [other]);

  useEffect(() => {
    let active = false;
    const message_ids = content_ids?.[id] ?? [];
    Object.keys(message_ids).forEach(m_id => {
      const content = thread_content?.[m_id] ?? {};
      const {owner_id, updated} = content;
      if (owner_id !== user_id && updated > dateToTimestamp() - 3600) {
        active = true;
      }
    });

    setActive(active);
  }, [content_ids?.[id]]);

  return (
    <div
      className={`direct-msg-item ${active ? 'active' : ''}`}
      onClick={() => setCurrent(id)}>
      <div className="flex-row justify-between align-center">
        <div className="flex-row align-center">
          <ProfileImage
            data={profile_image}
            style={{height: '24px', width: '24px'}}
            onClick={() => {
              const parsed = encryptString(other_profile_id);
              navigate(`/feed/profiles/detail/${parsed}`);
            }}
          />
          <p className="name">{pretty}</p>
        </div>
        {!unread && (
          <span className="user-activity-status">
            <span
              className={`activity-dot ${otherActive ? 'green' : 'grey'}`}
            />
          </span>
        )}
        <BadgeDotRelative active={unread} />
      </div>
      <p className="time">{pretty_date}</p>
    </div>
  );
};

const PAGE_SIZE = 10;

export const ChatMessages = React.memo(({id}) => {
  const {
    state: {content_ids, thread_content},
  } = useThreads();

  const containerRef = useRef(null);
  const [page, setPage] = useState(1);
  const message_ids = useMemo(() => {
    return content_ids?.[id] ?? [];
  }, [content_ids, id]);

  const sorted = useMemo(() => {
    return Object.keys(message_ids).sort((a, b) => {
      const a_content = thread_content?.[a] ?? {};
      const b_content = thread_content?.[b] ?? {};
      return a_content?.created - b_content.created; // Sort oldest first for bottom-up chat
    });
  }, [message_ids, thread_content]);

  const handleScroll = () => {
    if (!containerRef.current) return;

    const {scrollTop, scrollHeight, clientHeight} = containerRef.current;
    // For chat interface, we check if scrolled near top to load older messages
    const scrollPercentage = scrollTop / scrollHeight;

    if (scrollPercentage < 0.2 && sorted.length > page * PAGE_SIZE) {
      setPage(prev => prev + 1);
    }
  };

  useEffect(() => {
    const currentContainer = containerRef.current;
    if (currentContainer) {
      currentContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [page, sorted.length]);

  // Reset pagination when conversation changes
  useEffect(() => {
    setPage(1);
  }, [id]);

  // For chat, we show most recent PAGE_SIZE messages first, then load older ones
  const paginatedMessages = useMemo(() => {
    const startIndex = Math.max(0, sorted.length - page * PAGE_SIZE);
    return sorted.slice(startIndex);
  }, [sorted, page]);

  return (
    <div className="direct-msg-chat-container" ref={containerRef}>
      <div className="messages-wrapper">
        {paginatedMessages?.map(item => (
          <ThreadMessageGroup
            key={item}
            contentId={item}
            messageIds={message_ids[item]?.sort((a, b) => {
              const a_content = thread_content?.[a] ?? {};
              const b_content = thread_content?.[b] ?? {};
              return a_content?.created - b_content.created;
            })}
            onReply={() => {}}
            onQuote={() => {}}
            focusedContent={null}
            sub={() => {}}
            dm={true}
          />
        ))}
      </div>
    </div>
  );
});

export const DMList = ({current, setCurrent, setActive}) => {
  const {
    state: {thread_ids, threads},
  } = useThreads();

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const containerRef = useRef(null);

  const sortedDMs = useMemo(() => {
    return thread_ids
      .filter(threadId => {
        const thread = threads[threadId];
        const {type, title} = thread;

        if (search) {
          const searchTerm = search.toLowerCase();
          const contains = title?.toLowerCase().includes(searchTerm);
          if (!contains) return false;
        }

        return type === 'direct';
      })
      .sort((a, b) => threads[b].updated - threads[a].updated);
  }, [thread_ids, threads, search]);

  const handleScroll = () => {
    if (!containerRef.current) return;

    const {scrollTop, scrollHeight, clientHeight} = containerRef.current;
    const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;

    if (scrollPercentage > 0.8 && sortedDMs.length > page * PAGE_SIZE) {
      setPage(prev => prev + 1);
    }
  };

  useEffect(() => {
    const currentContainer = containerRef.current;
    if (currentContainer) {
      currentContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [page, sortedDMs.length]);

  // Reset pagination when search changes
  useEffect(() => {
    setPage(1);
  }, [search]);

  const paginatedDMs = useMemo(() => {
    return sortedDMs.slice(0, page * PAGE_SIZE);
  }, [sortedDMs, page]);

  return (
    <div className="direct-msg-list-modal direct-msg-show">
      <div className="flex-row align-center justify-between">
        <h3>Messages</h3>
        <button
          className="direct-msg-close-button"
          onClick={() => setActive(false)}>
          <RxCross2 />
        </button>
      </div>
      <div className="direct-msg-search-container">
        <Textfield
          className="direct-msg-search-input"
          value={search}
          type="text"
          placeholder="Search..."
          onChange={e => setSearch(e.target.value)}
        />
      </div>
      <div className="direct-msg-list" ref={containerRef}>
        {paginatedDMs.map(t_id => (
          <DMItem
            key={t_id}
            id={t_id}
            setCurrent={setCurrent}
            active={current === t_id}
          />
        ))}
      </div>
    </div>
  );
};
