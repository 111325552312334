import debounce from 'lodash/debounce';

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {MdPersonAddAlt} from 'react-icons/md';
import {
  RxChatBubble,
  RxChevronLeft,
  RxChevronRight,
  RxGlobe,
  RxMagnifyingGlass,
  RxMoon,
  RxSun,
} from 'react-icons/rx';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {track} from '../../api/analytics';
import {useAuth} from '../../hooks/use-auth';
import useLoader from '../../hooks/use-loader';
import useLogout from '../../hooks/use-logout';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useThreads} from '../../hooks/use-threads';
import {ThreadButtons} from '../../pages/threads/inputs/thread-action-buttons';
import {AssistantModal} from '../../pages/threads/modals/assistant-modal';
import {DirectMessages} from '../../pages/threads/overview-tabs/direct-messages';
import ThreadSearch from '../../pages/threads/overview-tabs/thread-search';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import {BadgeDot} from '../badge/badge';
import {DropDown} from '../inputs/drop-down';
import {ProfileImage} from '../inputs/uploader';
import {InviteModal} from '../modal/invite-modal';
import {ModalPortalAbsolute} from '../modal/modal-portal';
import SystemStatus from '../system-status/system-status';
import {InvertedTooltip} from '../tooltip/tooltip';
import './styles.css';

const TopBarSearch = ({startSearch = false, setStartSearch}) => {
  const {loadProfiles, initThreads} = useLoader();
  const {prettyName, prettyPosition} = useStringFormatter();
  const {
    state: {thread_content, threads, thread_ids, threads_loaded},
  } = useThreads();
  const {
    state: {id, profiles},
  } = useAuth();

  const [loading, setLoading] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const searchInputRef = useRef(null);

  const debouncedSetSearch = useMemo(
    () =>
      debounce(value => {
        setDebouncedSearch(value);
        if (value) {
          track('thread_search', {search: value, user_id: id});
        }
      }, 300),
    [id],
  );

  const handleSearchChange = useCallback(
    e => {
      const value = e.target.value;
      setSearch(value);
      if (value.length > 2) {
        track('thread_search', {search_term: value});
      }
      debouncedSetSearch(value);
    },
    [debouncedSetSearch],
  );

  useEffect(() => {
    return () => {
      debouncedSetSearch.cancel();
    };
  }, [debouncedSetSearch]);

  useEffect(() => {
    if (id) {
      initThreads();
    }
  }, [id]);

  useEffect(() => {
    if (threads_loaded) {
      const profile_ids = [
        ...new Set(
          Object.values(threads)
            .filter(thread => thread?.status === 'active')
            .flatMap(thread =>
              thread?.permissions.map(permission => permission.user_id),
            ),
        ),
      ];

      loadProfiles(profile_ids);
    }
  }, [threads_loaded]);

  const searchResults = useMemo(() => {
    if (!debouncedSearch)
      return {
        contentIds: [],
        threadIds: [],
        fileLinkMentionIds: [],
        peopleIds: [],
      };

    setLoading(true);

    const searchLower = debouncedSearch.toLowerCase();

    const contentIds = Object.keys(thread_content)
      .filter(id => {
        const content = thread_content?.[id] ?? {};
        const thread = threads?.[content?.thread_id] ?? {};
        if (thread?.status !== 'active') return false;
        return content?.content?.toLowerCase().includes(searchLower);
      })
      .sort((a, b) => {
        const createdA = thread_content[a]?.created ?? 0;
        const createdB = thread_content[b]?.created ?? 0;
        return createdB - createdA; // Sort in descending order (newest first)
      });

    const threadIds = Object.keys(threads)
      .filter(id => {
        const thread = threads?.[id] ?? {};
        if (thread?.status !== 'active' || thread?.type === 'direct')
          return false;
        return (
          thread?.title?.toLowerCase().includes(searchLower) ||
          thread?.security_level?.toLowerCase().includes(searchLower) ||
          thread?.content?.toLowerCase().includes(searchLower)
        );
      })
      .sort((a, b) => {
        const updatedA = threads[a]?.updated ?? 0;
        const updatedB = threads[b]?.updated ?? 0;
        return updatedB - updatedA; // Sort in descending order (newest first)
      });

    const fileLinkMentionIds = Object.keys(thread_content)
      .filter(id => {
        const item = thread_content[id];
        const thread = threads?.[item?.thread_id] ?? {};
        if (thread?.status !== 'active') return false;
        if (item?.media && item.media.length > 0) {
          return item.media.some(file =>
            file?.key?.split('/')?.[1]?.toLowerCase().includes(searchLower),
          );
        }
        return false;
      })
      .sort((a, b) => {
        const updatedA = thread_content[a]?.updated ?? 0;
        const updatedB = thread_content[b]?.updated ?? 0;
        return updatedB - updatedA; // Sort in descending order (newest first)
      });

    const peopleIds = Object.keys(profiles).filter(id => {
      const profile = profiles[id];
      return (
        prettyName(profile)?.toLowerCase().includes(searchLower) ||
        prettyPosition(profile)?.toLowerCase().includes(searchLower)
      );
    });

    setLoading(false);

    return {contentIds, threadIds, fileLinkMentionIds, peopleIds};
  }, [debouncedSearch, thread_content, threads, profiles]);

  const handleSearchClick = () => {
    setIsSearchOpen(true);
  };

  const handleCloseSearch = () => {
    setStartSearch(false);
    setIsSearchOpen(false);
    setSearch('');
    setDebouncedSearch('');
  };
  const handleKeyDown = useCallback(e => {
    if (e.key === 'Escape') {
      handleCloseSearch();
    }
  }, []);

  useEffect(() => {
    if (isSearchOpen) {
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isSearchOpen, handleKeyDown]);

  useEffect(() => {
    if (startSearch) handleSearchClick();
  }, [startSearch]);

  return (
    <div className="top-bar-search">
      <div className="search-input-container" onClick={handleSearchClick}>
        <RxMagnifyingGlass className="search-icon" />
        <input
          type="text"
          className="search-input"
          placeholder="Search for threads, posts, files, people..."
          value={search}
          onChange={handleSearchChange}
        />
      </div>
      {isSearchOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            paddingTop: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}>
          <ModalPortalAbsolute>
            <ThreadSearch
              onClose={handleCloseSearch}
              searchResults={searchResults}
              search={search}
              handleSearchChange={handleSearchChange}
              searchInputRef={searchInputRef}
              loading={loading}
            />
          </ModalPortalAbsolute>
        </div>
      )}
    </div>
  );
};

const HorizontalSidebar = ({disabled, setIndex, setCurrent, setContent}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    state: {thread_ids, threads},
  } = useThreads();
  const navigate = useNavigate();
  const logout = useLogout();

  const {
    state: {id: user_id, profile, current_organization},
  } = useAuth();

  const [add, setAdd] = useState(false);
  // Updated dark mode initialization to check localStorage first
  const [darkMode, setDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme === 'dark';
    }
    return (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    );
  });
  const [dms, setDMs] = useState(false);
  const [initialDM, setInitial] = useState(null);
  const [popup, setPopup] = useState({on: false, message: ''});
  const [startSearch, setStartSearch] = useState(false);
  const [startAssistant, setStartAssistant] = useState(false);

  const userAgent = navigator.userAgent.toLowerCase();
  const isMac = userAgent.includes('mac');
  const commandKey = isMac ? 'CMD' : 'CTRL';

  useEffect(() => {
    setDMs(searchParams.get('dms_active'));
    setInitial(searchParams.get('dm_id') ?? null);
  }, [searchParams]);

  // Update theme in DOM and localStorage when darkMode changes
  useEffect(() => {
    document.documentElement.setAttribute(
      'data-theme',
      darkMode ? 'dark' : 'light',
    );
    localStorage.setItem('theme', darkMode ? 'dark' : 'light');

    // Optional: Update meta theme-color for mobile browsers
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', darkMode ? '#000000' : '#ffffff');
    }
  }, [darkMode]);

  // Modified system preference listener to respect user preference
  useEffect(() => {
    const setThemeBasedOnSystemPreference = e => {
      // Only update if user hasn't set a preference
      if (!localStorage.getItem('theme')) {
        setDarkMode(e.matches);
      }
    };

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    // Initial check
    if (!localStorage.getItem('theme')) {
      setDarkMode(mediaQuery.matches);
    }

    // Add listener with compatibility check
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', setThemeBasedOnSystemPreference);
    } else {
      // Fallback for older browsers
      mediaQuery.addListener(setThemeBasedOnSystemPreference);
    }

    return () => {
      // Cleanup with compatibility check
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener(
          'change',
          setThemeBasedOnSystemPreference,
        );
      } else {
        mediaQuery.removeListener(setThemeBasedOnSystemPreference);
      }
    };
  }, []);

  const handleAction = action => () => {
    switch (action) {
      case 'search':
        setStartSearch(true);
        break;
      case 'feed':
        navigate('/feed');
        break;
      case 'invite':
        setAdd(!add);
        break;
      case 'darkMode':
        setDarkMode(!darkMode);
        break;
      case 'home':
        navigate('/threads');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleKeyDown = event => {
      const {metaKey, ctrlKey, altKey, shiftKey, key, target} = event;
      const isInputElement = ['INPUT', 'TEXTAREA', 'SELECT'].includes(
        target.tagName,
      );

      if ((metaKey || ctrlKey) && !altKey && !shiftKey && !isInputElement) {
        const actionMap = {
          s: 'search',
          d: 'darkMode',
          h: 'home',
        };

        if (actionMap[key]) {
          event.preventDefault();
          handleAction(actionMap[key])();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [darkMode, add]);

  const buttons = [
    {
      onClick: () => {
        setDMs(!dms);
      },
      icon: <RxChatBubble />,
      message: 'Messages',
      iconTitle: 'DMs',
      notifications: 0,
    },
    {
      onClick: handleAction('feed'),
      icon: <RxGlobe />,
      message: 'Search database',
      iconTitle: 'Search',
      notifications: 0,
    },
    {
      onClick: handleAction('invite'),
      icon: <MdPersonAddAlt />,
      message: 'Invite people',
      // commandMessage: `${commandKey} + d`,
      iconTitle: 'Invite',
      notifications: 0,
    },
    {
      onClick: handleAction('darkMode'),
      icon: darkMode ? <RxMoon /> : <RxSun />,
      message: 'Darkmode',
      commandMessage: `${commandKey} + d`,
      iconTitle: 'Theme',
      notifications: 0,
    },
  ];

  if (disabled) return null;

  return (
    <>
      <div className="horizontal-sidebar">
        <div className="sidebar-left">
          {/* <div className="sidebar-logo">
            {darkMode ? (
              <span
                className="flex-column align-center "
                onClick={handleAction('home')}>
                <PublicMindLogoLightNoText />
              </span>
            ) : (
              <span
                className="flex-column align-center "
                onClick={handleAction('home')}>
                <PublicMindLogoDarkNoText />
              </span>
            )}
          </div> */}
          <ThreadButtons darkMode={darkMode} setDMActive={setDMs} />

          <SystemStatus
            onLaunchAssistant={authed => {
              if (authed) setStartAssistant(true);
            }}
          />

          {/* <KnowledgeUploadButton
            onLaunch={() => {
              // We'll implement this handler in the next step
              setShowKnowledgeUploader(true);
            }}
          /> */}
        </div>

        <span className="flex-row ">
          <RxChevronLeft
            className="sidebar-action-button"
            onClick={() => {
              navigate(-1);
            }}
          />
          <RxChevronRight
            className="sidebar-action-button"
            onClick={() => {
              navigate(+1);
            }}
          />
        </span>
        <div className="sidebar-search">
          <TopBarSearch
            startSearch={startSearch}
            setStartSearch={setStartSearch}
          />
        </div>
        <div className="sidebar-right">
          <div className="sidebar-actions">
            <div className="sidebar-profile">
              <DropDown
                items={[
                  {
                    text: 'Profile',
                    onClick: () => navigate('/settings/profile'),
                  },
                  {
                    text: 'Notifications',
                    onClick: () => navigate('/settings/general'),
                  },

                  {text: 'Log Out', onClick: logout},
                  ...(process.env.NODE_ENV === 'development'
                    ? [
                        {
                          text: 'MASTER',
                          onClick: () => navigate('/settings/master'),
                        },
                        {
                          text: 'ADMIN',
                          onClick: () => navigate('/settings/admin'),
                        },
                      ]
                    : []),
                ]}>
                <InvertedTooltip text="View Profile Settings">
                  <ProfileImage
                    data={profile?.profile_image}
                    onClick={() => {}}
                    style={{height: '24px', width: '24px'}}
                  />
                </InvertedTooltip>
              </DropDown>
            </div>
            {buttons.map((button, i) => (
              <InvertedTooltip
                key={i}
                text={button.message}
                subtext={button.commandMessage}>
                <div className="sidebar-action-button" onClick={button.onClick}>
                  {button.icon}
                  <BadgeDot active={button.notifications} />
                </div>
              </InvertedTooltip>
            ))}
          </div>
          <p className="text-secondary text-10">
            {process.env.REACT_APP_VERSION}
          </p>
        </div>
      </div>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
      <InviteModal active={add} setActive={setAdd} />
      <DirectMessages active={dms} setActive={setDMs} directID={initialDM} />
      <AssistantModal active={startAssistant} setActive={setStartAssistant} />
      {/* <KnowledgeUploader
        active={showKnowledgeUploader}
        setActive={setShowKnowledgeUploader}
      /> */}
    </>
  );
};

export default HorizontalSidebar;
